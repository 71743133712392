import { gql, useMutation } from '@apollo/client';
import {
	DndContext,
	DragOverlay,
	PointerSensor,
	closestCenter,
	useSensor,
	useSensors,
	TouchSensor,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useToast } from 'components/ui/toast';
import {
	Emblem,
	Mutation,
	MutationUpdateOrganizationTeamMembersArgs,
	TeamMembersUpdate,
} from 'middleware-types';
import { useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { GET_TEAM_MEMBERS, useOrgTeam } from '../hooks';
import { SortableTeamMemberListItem } from './team-member-list-item';
import TeamMemberListItem from './team-member-list-item';

interface OrderTeamMembersModalProps {
	orgId: string;
	onClose: () => void;
}

export interface EmblemExtended extends Emblem {
	order: number;
}

export const OrderTeamMembersModal = ({ orgId, onClose }: OrderTeamMembersModalProps) => {
	const { teamMembers } = useOrgTeam(orgId);
	const [teamMembersArray, setTeamMembersArray] = useState(
		teamMembers.map((tm, idx): EmblemExtended => {
			return {
				...tm,
				order: idx + 1,
			};
		})
	);
	const [activeTeamMember, setActiveTeamMember] = useState<null | EmblemExtended>(null);

	const { updateTeamMembers, loading } = useUpdateTeamMembers(orgId);

	const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

	const onClick = () => {
		const update: TeamMembersUpdate = {
			teamMemberUpdates: teamMembersArray.map((tm, idx) => {
				return {
					userId: tm.id,
					displayPosition: idx + 1,
				};
			}),
		};

		updateTeamMembers(update).then((res) => {
			if (!res) return;
			onClose();
		});
	};

	const handleDragStart = (event) => {
		const { active } = event;

		const activeMemberToSet = teamMembersArray.find((tm) => tm.order == active.id);
		if (activeMemberToSet) {
			setActiveTeamMember(activeMemberToSet);
		}
	};

	const handleDragEnd = (event) => {
		const { active, over } = event;

		if (active.id !== over.id) {
			setTeamMembersArray((tm) => {
				const oldIndex = tm.map((tm) => tm.order).indexOf(active.id);
				const newIndex = tm.map((tm) => tm.order).indexOf(over.id);

				return arrayMove(tm, oldIndex, newIndex);
			});
		}
	};

	return (
		<Stack>
			<Stack
				px={2}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Typography variant="h3" noWrap>
					Reorder Team Members
				</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack overflow="auto" width="500px">
				<DndContext
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
					sensors={sensors}
					collisionDetection={closestCenter}>
					<SortableContext
						items={teamMembersArray}
						strategy={verticalListSortingStrategy}>
						{teamMembersArray.map((tm, idx) => {
							return (
								<SortableTeamMemberListItem
									key={tm.order}
									orderToSet={idx + 1}
									teamMember={tm}
								/>
							);
						})}
					</SortableContext>
					<DragOverlay>
						{activeTeamMember ? (
							<TeamMemberListItem teamMember={activeTeamMember} />
						) : null}
					</DragOverlay>
				</DndContext>
			</Stack>
			<Divider />
			<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
				<Button size="large" variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<LoadingButton
					size="large"
					variant="contained"
					color="primary"
					onClick={onClick}
					loading={loading}>
					Save
				</LoadingButton>
			</Stack>
		</Stack>
	);
};

const BULK_UPDATE_TEAM_MEMBERS = gql`
	mutation updateOrganizationTeamMembers($organizationId: ID!, $update: TeamMembersUpdate!) {
		updateOrganizationTeamMembers(organizationId: $organizationId, update: $update)
	}
`;

export const useUpdateTeamMembers = (organizationId: string) => {
	const toast = useToast();

	const [_UpdateTeamMembers, { loading }] = useMutation<
		Pick<Mutation, 'updateOrganizationTeamMembers'>,
		MutationUpdateOrganizationTeamMembersArgs
	>(BULK_UPDATE_TEAM_MEMBERS);

	const updateTeamMembers = async (update: TeamMembersUpdate) => {
		return await _UpdateTeamMembers({
			variables: { organizationId, update },
			refetchQueries: [GET_TEAM_MEMBERS],
			awaitRefetchQueries: true,
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Team member order updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateTeamMembers, loading };
};
