import { gql, useQuery } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import { Query, QueryBillingOrganizationDraftInvoicingGetArgs } from 'middleware-types';

export const GET_DRAFT_INVOICING = gql`
	query GetDraftInvoicing($organizationId: String!) {
		billingOrganizationDraftInvoicingGet(organizationId: $organizationId) {
			draftInvoicingEnabled
		}
	}
`;

export const useDraftInvoicingEnabled = () => {
	const organizationId = useOrgId();
	const { data, loading } = useQuery<
		Pick<Query, 'billingOrganizationDraftInvoicingGet'>,
		QueryBillingOrganizationDraftInvoicingGetArgs
	>(GET_DRAFT_INVOICING, { variables: { organizationId } });

	const enabled = data?.billingOrganizationDraftInvoicingGet.draftInvoicingEnabled ?? false;
	return { enabled, loading };
};
