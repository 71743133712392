import { gql, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { FileFieldUploadValues } from 'components/ui/emblem/user-emblem-uploader';
import { FileField } from 'components/ui/fields';
import { FileType } from 'components/ui/fields/file';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { Mutation, UserChatFile } from 'middleware-types';
import { useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';

interface EvoxFileUploaderModalProps extends ModalContentProps {
	userId: string;
	setRequestFiles: React.Dispatch<React.SetStateAction<UserChatFile[]>>;
}

export const EvoxFileUploaderModal = ({
	userId,
	setRequestFiles,
	onClose,
}: EvoxFileUploaderModalProps) => {
	const { uploadFile, error } = useEvoxFileMutation();
	const toast = useToast();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [isUploading, setIsUploading] = useState(false);

	return (
		<Formik<{ evoxFile: FileFieldUploadValues }>
			initialValues={{
				evoxFile: {
					uploadToken: '',
				},
			}}
			onSubmit={(values: { evoxFile: FileFieldUploadValues }) => {
				uploadFile({
					variables: {
						userId,
						request: {
							fileUploadToken: values.evoxFile.uploadToken ?? undefined,
						},
					},
				})
					.then((res) => {
						if (responseHasErrors(res.errors, { toast })) {
							return false;
						}
						if (!res.data?.chatFileRequest) {
							toast.push(
								'There was an issue uploading this file. Please try again later.',
								{
									variant: 'error',
								}
							);
							return false;
						}
						setRequestFiles((prev) => {
							// For some reason it knows chatFileRequest isn't undefined
							// until it's put in an array with `...prev,`.
							if (!res.data) return prev;
							return [...prev, res.data?.chatFileRequest];
						});
						onClose();
					})
					.catch(() => handleNoResponse({ toast }));
			}}>
			{({ submitForm, isValid, dirty, isSubmitting }) => (
				<>
					<ModalHeader title="Attach File" onClose={onClose} />
					<ModalContent>
						<Alert error={error} />
						<h3 className="mt-0">Drag & drop a file below or click to attach</h3>
						<FileField
							name="evoxFile"
							type={FileType.Evox}
							setIsUploading={setIsUploading}
						/>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							variant="contained"
							color="primary"
							onClick={submitForm}
							disabled={!isValid || !dirty || isSubmitting || isUploading}
							loading={isSubmitting}>
							Attach
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};

/**
 * Hook to update the user profile picture
 */
export const useEvoxFileMutation = () => {
	const [uploadFile, { loading, error }] = useMutation<Pick<Mutation, 'chatFileRequest'>>(
		gql`
			mutation chatFileRequest($userId: ID!, $request: ChatFileRequest!) {
				chatFileRequest(userId: $userId, request: $request) {
					chatFileId
					name
				}
			}
		`
	);

	return { uploadFile, loading, error };
};
