import { ApolloError, gql, useQuery } from '@apollo/client';
import { ArrowDropDownOutlined, DomainOutlined } from '@mui/icons-material';
import {
	Alert,
	Box,
	Button,
	FormControlLabel,
	ListItemAvatar,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Switch,
	Typography,
} from '@mui/material';
import { OrgEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { Emblem, Query, QueryAffiliatedOrganizationsArgs } from 'middleware-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'utils/session';
import { theme } from 'utils/theme';

/* Query for list of orgs the current user belongs to/ is affiliated with. */
export const AFFILIATED_ORGS = gql`
	query affiliatedOrganizations($userId: ID!) {
		affiliatedOrganizations(userId: $userId) {
			id
			displayName
			handle
			private
		}
	}
`;

export const useAffiliatedOrgsQuery = () => {
	const { user } = useSession();
	const { loading, data, error } = useQuery<
		Pick<Query, 'affiliatedOrganizations'>,
		QueryAffiliatedOrganizationsArgs
	>(AFFILIATED_ORGS, {
		fetchPolicy: 'cache-and-network',
		variables: { userId: user.userId },
		skip: !!user.siteUserId,
	});

	return {
		error,
		loading,
		organizations: data?.affiliatedOrganizations ?? [],
	};
};

/**
 * Returns the organization dropdown in the top navigation bar.
 * @returns
 */
export const OrgDropDown = () => {
	const { error, loading, organizations } = useAffiliatedOrgsQuery();
	if (loading || (organizations.length <= 0 && !error)) return <></>;
	return <_OrgDropdown organizations={organizations} error={error} />;
};

const _OrgDropdown = ({
	organizations,
	error,
}: {
	organizations: Emblem[];
	error: ApolloError | undefined;
}) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [showPrivate, setShowPrivate] = useState(organizations.every((org) => org.private));

	const handleClick = (path: string) => {
		setAnchorEl(null);
		navigate(path);
	};

	const hasPrivateOrgs = organizations.some((org) => org.private);

	const filteredOrganizations = organizations.filter((org) => {
		if (showPrivate) return true;
		return !org.private;
	});

	return (
		<>
			<Button
				onClick={(el) => setAnchorEl(el.currentTarget)}
				sx={{ height: 40 }}
				variant="outlined"
				startIcon={<DomainOutlined sx={{ color: 'neutral.500', height: 24, width: 24 }} />}
				endIcon={<ArrowDropDownOutlined />}>
				<Typography
					noWrap
					sx={{
						display: { xs: 'block', sm: 'none', md: 'block' },
					}}>
					My Organizations
				</Typography>
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				disableAutoFocus
				disableAutoFocusItem
				disableRestoreFocus
				onClose={() => setAnchorEl(null)}
				sx={{ '& .MuiMenu-list': { paddingTop: 0, maxHeight: 440 } }}>
				{hasPrivateOrgs && !error && (
					<Stack
						display="flex"
						alignItems="center"
						py={0.5}
						borderBottom={`1px solid ${theme.palette.divider}`}>
						<FormControlLabel
							label="Show Private"
							labelPlacement="start"
							control={
								<Switch
									checked={showPrivate}
									onChange={(_e, checked) => setShowPrivate(checked)}
								/>
							}
						/>
					</Stack>
				)}
				{error ? (
					<MenuItem>
						<Alert severity="error">Organizations failed to load</Alert>
					</MenuItem>
				) : filteredOrganizations.length === 0 ? (
					<Box px={2} py={1.5}>
						<Typography variant="body1">No public organizations to show</Typography>
					</Box>
				) : (
					filteredOrganizations.map((org, i) => (
						<MenuItem
							key={i}
							dense={false}
							onClick={() => handleClick(`/orgs/${org.handle}`)}
							sx={{ minWidth: '12.25rem' }}>
							<ListItemAvatar>
								<OrgEmblemAvatar id={org.id} size={32} noDropdown />
							</ListItemAvatar>
							<ListItemText>{org.displayName}</ListItemText>
						</MenuItem>
					))
				)}
			</Menu>
		</>
	);
};
