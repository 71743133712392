import { gql, useLazyQuery } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import {
	Query,
	QueryBillingOrganizationPortalUrlGetArgs,
	QueryBillingSiteUserPortalUrlGetArgs,
} from 'middleware-types';
import { useIsSiteUser } from 'utils/useIsSiteUser';

const GET_BILLING_PORTAL_URL_SITE = gql`
	query GetBillingPortalUrlSite($organizationId: String!, $returnUrl: String!) {
		billingSiteUserPortalUrlGet(organizationId: $organizationId, returnUrl: $returnUrl) {
			url
		}
	}
`;

const GET_BILLING_PORTAL_URL_SOCIAL = gql`
	query GetBillingPortalUrlSocial($organizationId: String!, $returnUrl: String!) {
		billingOrganizationPortalUrlGet(organizationId: $organizationId, returnUrl: $returnUrl) {
			url
		}
	}
`;

export const useBillingPortalUrl = () => {
	const organizationId = useOrgId();
	const isSiteUser = useIsSiteUser();
	const returnUrl = window.location.href;

	const [onSiteClick, { loading: siteUserLoading }] = useLazyQuery<
		Pick<Query, 'billingSiteUserPortalUrlGet'>,
		QueryBillingSiteUserPortalUrlGetArgs
	>(GET_BILLING_PORTAL_URL_SITE, {
		variables: { organizationId, returnUrl },
		onCompleted: (data) => window.open(data.billingSiteUserPortalUrlGet.url, '_blank'),
	});

	const [onSocialClick, { loading: socialUserLoading }] = useLazyQuery<
		Pick<Query, 'billingOrganizationPortalUrlGet'>,
		QueryBillingOrganizationPortalUrlGetArgs
	>(GET_BILLING_PORTAL_URL_SOCIAL, {
		variables: { organizationId, returnUrl },
		onCompleted: (data) => window.open(data.billingOrganizationPortalUrlGet.url, '_blank'),
	});

	const onClick = isSiteUser ? onSiteClick : onSocialClick;
	const loading = isSiteUser ? siteUserLoading : socialUserLoading;
	return { onClick, loading };
};
