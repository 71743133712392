import { AddCircle, ArrowBackIos, ModeEditOutlineOutlined } from '@mui/icons-material';
import { Button, Divider, IconButton, List, Stack, Typography } from '@mui/material';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { useState } from 'react';
import { useCommunicationsContext } from '../communications-provider';
import { getCustomLabels, getDefaultLabels } from '../helpers/labels';
import { CreateUpdateLabelModal } from '../modals/create-update-label-modal';
import { LabelListItem } from './label-list-item';
import { LabelsColumnSkeleton } from './labels-column-skeleton';

interface LabelsColumnProps {
	onClose?: () => void;
	onLabelSelected?: () => void;
}

export const LabelsColumn = ({ onClose, onLabelSelected }: LabelsColumnProps) => {
	const { labels, labelsLoading, readOnly, setNewConversationModalOpen } =
		useCommunicationsContext();

	const defaultLabels = getDefaultLabels(labels);
	const customLabels = getCustomLabels(labels);

	const [createModalOpen, setCreateModalOpen] = useState(false);

	if (labelsLoading) {
		return <LabelsColumnSkeleton />;
	}

	return (
		<>
			<Stack spacing={{ xs: 1.5, sm: 2.5 }} height="100%">
				<Typography variant="h2">Conversations</Typography>
				{!readOnly && (
					<Button
						variant="contained"
						color="primary"
						startIcon={<ModeEditOutlineOutlined />}
						onClick={() => setNewConversationModalOpen(true)}>
						New Conversation
					</Button>
				)}
				<Stack spacing={{ xs: 1.5, sm: 2.5 }} flex={1} overflow="auto">
					<List disablePadding>
						{defaultLabels.map((label) => (
							<LabelListItem
								key={label.id}
								label={label}
								onSelect={onLabelSelected}
							/>
						))}
					</List>
					<Divider />
					<Stack spacing={0.5}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							px={1.5}>
							<Typography variant="h3">Labels</Typography>
							{!readOnly && (
								<IconButton onClick={() => setCreateModalOpen(true)}>
									<AddCircle color="primary" />
								</IconButton>
							)}
						</Stack>
						<List disablePadding>
							{customLabels.map((label) => (
								<LabelListItem
									key={label.label.id}
									label={label.label}
									indent={label.path.length}
									onSelect={onLabelSelected}
								/>
							))}
						</List>
					</Stack>
				</Stack>
				{onClose && (
					<Stack spacing={1}>
						<Divider />
						<Button
							onClick={onClose}
							startIcon={<ArrowBackIos sx={{ color: 'neutral.500' }} />}
							sx={{ justifyContent: 'flex-start', fontSize: 16 }}>
							Collapse
						</Button>
					</Stack>
				)}
			</Stack>
			{/** create modal */}
			<ModalOrDrawer open={createModalOpen}>
				<CreateUpdateLabelModal onClose={() => setCreateModalOpen(false)} />
			</ModalOrDrawer>
		</>
	);
};
