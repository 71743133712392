import { LoadingButton } from '@mui/lab';
import { Button, ListItemText, MenuItem, Stack } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { ColorField } from 'components/ui/fields/colorField';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { Formik, FormikProps } from 'formik';
import { LabelCreateRequest } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { useCommunicationsContext } from '../communications-provider';
import { LabelWithColor, labelColorOptions } from '../helpers/label-colors';
import { getCustomLabels } from '../helpers/labels';
import { useCreateLabel, useUpdateLabel } from '../hooks/labelsHooks';
import { LabelIcon } from '../shared/label-icon';

interface CreateUpdateLabelModalProps extends ModalContentProps {
	label?: LabelWithColor;
}

export const CreateUpdateLabelModal = ({ label, onClose }: CreateUpdateLabelModalProps) => {
	const createLabel = useCreateLabel();
	const updateLabel = useUpdateLabel();

	const { labels } = useCommunicationsContext();
	const customLabels = getCustomLabels(labels);

	const initialValues: LabelCreateRequest = {
		name: label?.name ?? '',
		colorName: label?.colorName ?? '',
		parentLabelId: '',
	};

	const onSubmit = async (values: LabelCreateRequest) => {
		let success = false;
		if (label === undefined) {
			success = await createLabel(values);
		} else {
			const { parentLabelId: _parentLabelId, ...updateValues } = values;
			success = await updateLabel(label.id, updateValues);
		}
		if (success) onClose();
	};

	const validation = useValidation('LabelCreateRequest');

	return (
		<Formik<LabelCreateRequest>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validation.schema}
			enableReinitialize>
			{({ isSubmitting, isValid, dirty, submitForm }: FormikProps<LabelCreateRequest>) => (
				<>
					<ModalHeader
						title={label ? 'Edit Label' : 'Create New Label'}
						onClose={onClose}
					/>
					<ModalContent>
						<TextField name="name" label="Label Name" required />
						<ColorField
							label="Label Color"
							name="colorName"
							required
							options={labelColorOptions.map((option) => ({
								name: option.name,
								hex: option.color[500],
							}))}
							getValueFromOption={(option) => option.name}
						/>
						{label === undefined && (
							<TextField select label="Parent Label" name="parentLabelId">
								{customLabels.length > 0 ? (
									[
										<MenuItem key="" value="">
											<em>None</em>
										</MenuItem>,
										...customLabels.map((option) => (
											<MenuItem
												key={option.label.id}
												value={option.label.id}
												sx={{ pl: 2 + option.path.length * 3 }}>
												<Stack
													direction="row"
													spacing={1.5}
													alignItems="center">
													<LabelIcon label={option.label} />
													<ListItemText primary={option.label.name} />
												</Stack>
											</MenuItem>
										)),
									]
								) : (
									<MenuItem disabled>No options</MenuItem>
								)}
							</TextField>
						)}
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Close
						</Button>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							loading={isSubmitting}
							disabled={!isValid || !dirty || isSubmitting}
							onClick={submitForm}>
							Save
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};
