import { Logout, PersonAddAlt, RemoveCircleOutline } from '@mui/icons-material';
import {
	Box,
	Button,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	Stack,
	Typography,
} from '@mui/material';
import { EmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { useEmblem } from 'components/ui/emblem/hooks';
import { ConversationParticipant } from 'middleware-types';
import { useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { useCommunicationsContext } from '../communications-provider';
import { convertParticipantType } from '../helpers/utils';

interface ParticipantsPopupProps {
	participants: ConversationParticipant[];
	onClose: () => void;
	onAdd: () => void;
	onRemove: (p: ConversationParticipant) => void;
	canEdit: boolean;
}

export const ParticipantsPopup = ({
	participants,
	onClose,
	onAdd,
	onRemove,
	canEdit,
}: ParticipantsPopupProps) => {
	const isMobile = useIsMobile();
	return (
		<Box>
			<List disablePadding>
				{participants.map((p) => (
					<ParticipantListItem
						key={p.entityId}
						participant={p}
						canEdit={canEdit}
						onRemove={() => {
							onClose();
							onRemove(p);
						}}
					/>
				))}
			</List>
			{canEdit && (
				<>
					<Divider />
					<Box px={2} py={{ xs: 2, sm: 1 }}>
						<Button
							fullWidth
							startIcon={<PersonAddAlt />}
							variant={isMobile ? 'contained' : undefined}
							color={isMobile ? 'primary' : undefined}
							onClick={() => {
								onClose();
								onAdd();
							}}>
							Add Participants
						</Button>
					</Box>
				</>
			)}
		</Box>
	);
};

interface ParticipantListItemProps {
	participant: ConversationParticipant;
	onRemove: () => void;
	canEdit: boolean;
}

const ParticipantListItem = ({ participant, onRemove, canEdit }: ParticipantListItemProps) => {
	const isMobile = useIsMobile();
	const { entityId } = useCommunicationsContext();

	const { emblem } = useEmblem(
		participant.entityId,
		convertParticipantType(participant.entityType)
	);

	const [hovered, setHovered] = useState(false);

	return (
		<ListItem
			disableGutters
			disablePadding
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}>
			<ListItemButton disableGutters>
				<Stack direction="row" alignItems="center" width="100%" spacing={1.5} pl={2} pr={1}>
					<EmblemAvatar emblem={emblem} size={32} />
					<Typography variant="body2">{emblem?.displayName}</Typography>
					<Box flex={1} />
					{canEdit &&
						(hovered || isMobile ? (
							<IconButton onClick={onRemove}>
								{entityId === participant.entityId ? (
									<Logout />
								) : (
									<RemoveCircleOutline />
								)}
							</IconButton>
						) : (
							<Box width={40} height={40} />
						))}
				</Stack>
			</ListItemButton>
		</ListItem>
	);
};
