import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, MenuItem, Skeleton, TextField } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { SelectField } from 'components/ui/fields';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { AssociateOrInvitation, AssociateUpdate } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useSession } from 'utils/session';
import { useValidation } from 'utils/useValidation';
import {
	useAllOrgRolesQuery,
	useDeactivateAssociatenMutation,
	useReactivateAssociateMutation,
} from '../hooks';

interface AssociateProps extends ModalContentProps {
	orgId: string;
	associate: AssociateOrInvitation;
}

interface DeactivateAssociateModalProps extends ModalContentProps {
	orgId: string;
	associateId: string;
	displayName: string;
}

export const DeactivateAssociateModal = ({
	orgId,
	associateId,
	displayName,
	onClose,
}: DeactivateAssociateModalProps) => {
	const { deactivateAssociate } = useDeactivateAssociatenMutation();
	return (
		<ConfirmationModalContent
			variant="destructive"
			title="Deactivate Associate"
			primaryText="Deactivate Associate"
			secondaryText={`Are you sure you want to deactivate ${displayName}?`}
			confirmText="Deactivate"
			onSubmit={async () => {
				await deactivateAssociate(orgId, associateId);
				return true;
			}}
			onClose={onClose}
		/>
	);
};

/**
 * useReactivateAssociate - Custom hook for getting validation schema, handling submit, and loading.
 *
 * @returns {({
 * 	initialValues: AssociateProps;
 * 	roles: AllOrgRoles;
 * 	validationSchema: ValidationSchema | null;
 * 	submit: (values: MAssociateInvitationRequest) => Promise<void>;
 * 	loading: boolean;
 * 	error: ApolloError | undefined;
 * })}
 */
const useReactivateAssociate = ({ orgId, associate, onClose }: AssociateProps) => {
	const validation = useValidation('AssociateUpdate');
	const toast = useToast();
	const { reactivateAssociate, error } = useReactivateAssociateMutation(
		orgId,
		associate.associateId!
	);
	const rolesQuery = useAllOrgRolesQuery(orgId);

	const initialValues: AssociateUpdate = {
		emailAddress: associate.emailAddress,
		displayName: associate.displayName,
		organizationRoleId: associate.organizationRoleId,
		displayOnProfile: associate.displayOnProfile ?? false,
	};

	/**
	 * When the form submits
	 */
	const submit = async (values: AssociateUpdate) => {
		return await reactivateAssociate(values.organizationRoleId)
			.then(async (res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(`Successfully updated associate ${values.displayName}.`, {
					variant: 'success',
				});
				onClose();
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		initialValues,
		roles: rolesQuery.roles,
		submit,
		validationSchema: validation.schema,
		loading: validation.loading || rolesQuery.loading,
		error,
	};
};

/**
 * Associate Reactivation Form
 *
 * @param {UpdateAssociateProps} associate
 * @returns
 */
export const ReactivateAssociateForm = (props: AssociateProps) => {
	const { user } = useSession();
	const { associate, onClose } = props;
	const { initialValues, roles, loading, submit, validationSchema, error } =
		useReactivateAssociate(props);

	if (loading) return <SkeletonForm />;

	return (
		<Formik<AssociateUpdate>
			onSubmit={submit}
			validationSchema={validationSchema}
			initialValues={initialValues}
			enableReinitialize>
			{(formik) => (
				<>
					<ModalHeader
						title={`Reactivate Associate ${associate.displayName}`}
						onClose={onClose}
					/>
					<ModalContent>
						<Box>
							<Grid container spacing={1} columnSpacing={2}>
								<Grid xs={12} sm={6} md={6} item>
									<SelectField
										label="Role"
										required
										name="organizationRoleId"
										value={formik.values.organizationRoleId ?? ''}
										disabled={user.userId === props.associate.userId}
										fullWidth>
										{roles.map((src, i) => (
											<MenuItem key={i} value={src.id}>
												{src.name !== '' ? src.name : <em>None</em>}
											</MenuItem>
										))}
									</SelectField>
								</Grid>
								{error && (
									<Grid xs={12} md={12} item>
										<Alert error={error} />
									</Grid>
								)}
							</Grid>
						</Box>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
							loading={formik.isSubmitting}
							onClick={formik.submitForm}>
							Reactivate Associate
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};

const SkeletonForm = (): React.JSX.Element => (
	<ModalContent>
		<Grid container spacing={1} columnSpacing={2}>
			<Grid xs={12} md={6} item>
				<Skeleton animation="wave" width="100%">
					<TextField fullWidth />
				</Skeleton>
			</Grid>
			<Grid xs={12} sm={6} md={6} item>
				<Skeleton animation="wave" width="100%">
					<TextField fullWidth />
				</Skeleton>
			</Grid>
			<Grid xs={12} sm={6} md={6} item>
				<Skeleton animation="wave" width="100%">
					<TextField fullWidth />
				</Skeleton>
			</Grid>
		</Grid>
	</ModalContent>
);
