import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { Formik, FormikProps } from 'formik';
import { FileInstanceCopyRequest, FileInstanceInformation } from 'middleware-types';
import { useEffect, useRef } from 'react';
import { useFileInstanceCopy } from 'utils/fileUtils';
import { useValidation } from 'utils/useValidation';
import { getAccessLevelOptions } from '../components/access-level';
import { AccessLevelFields } from './access-level-fields';

interface RenameFileModalProps {
	file: FileInstanceInformation;
	onClose: () => void;
}

export const CopyFileModal = ({ file, onClose }: RenameFileModalProps) => {
	const { copyFileInstance } = useFileInstanceCopy(file.id);

	const onSubmit = async (values: FileInstanceCopyRequest) => {
		const success = await copyFileInstance(values);
		if (success) onClose();
	};

	const accessLevelOptions = getAccessLevelOptions(file.allowedAccessLevelChangesList);

	const validation = useValidation('FileInstanceCopyRequest');

	const initialValues = {
		fileName: file.fileName ?? undefined,
		accessLevel: file.accessLevel ?? undefined,
		folderId: file.folder?.id ?? undefined,
	};

	const inputRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		if (!inputRef.current) return;
		inputRef.current.focus();
		inputRef.current.setSelectionRange(0, file.fileName.lastIndexOf('.'));
	}, []);

	return (
		<Formik<FileInstanceCopyRequest>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validation.schema}>
			{({ isSubmitting, isValid, submitForm }: FormikProps<FileInstanceCopyRequest>) => (
				<Stack>
					<Stack
						px={2}
						py={1}
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={1}>
						<Typography variant="h3">Copy File</Typography>
						<IconButton onClick={onClose}>
							<Close />
						</IconButton>
					</Stack>
					<Divider />
					<Stack p={2.5}>
						<TextField
							ref={inputRef}
							required
							name="fileName"
							label="Name"
							helperText="The copy created will have this name."
						/>
					</Stack>
					<AccessLevelFields accessLevelOptions={accessLevelOptions} />
					<Divider />
					<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
						<Button size="large" variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							size="large"
							variant="contained"
							color="primary"
							loading={isSubmitting}
							disabled={!isValid}
							onClick={submitForm}>
							Save
						</LoadingButton>
					</Stack>
				</Stack>
			)}
		</Formik>
	);
};
