import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material';

interface StripeLinkCardProps {
	title: string;
	url: string | undefined;
}

export const StripeLinkCard = ({ title, url }: StripeLinkCardProps) => {
	return (
		<Card variant="outlined">
			<CardActionArea component="a" href={url} target="_blank">
				<Stack p={1.5} direction="row" alignItems="center" spacing={2}>
					<Box
						component="img"
						src={`https://www.google.com/s2/favicons?domain=${url}&sz=64`}
						height={36}
						width={36}
					/>
					<Box flex={1} overflow="hidden">
						<Typography variant="h4">{title}</Typography>
						<Typography variant="subtitle1" noWrap>
							{url?.replace(/^https?:\/\/(www\.)?/, '')}
						</Typography>
					</Box>
				</Stack>
			</CardActionArea>
		</Card>
	);
};
