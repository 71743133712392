import { OpenInNew, ReceiptLong } from '@mui/icons-material';
import {
	Box,
	Button,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { format } from 'date-fns';
import { InvoiceData } from 'middleware-types';
import { zoneDateOnly } from 'utils/utils';
import { InvoiceItemRow } from './invoice-item-row';

interface InvoiceDataDisplayProps {
	invoiceData: InvoiceData | undefined;
	loading: boolean;
}

export const InvoiceDataDisplay = ({ invoiceData, loading }: InvoiceDataDisplayProps) => {
	if (loading) {
		return (
			<Stack p={2.5} spacing={2.5}>
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Box>
						<Skeleton variant="text" width={80} />
						<Skeleton variant="text" width={100} />
					</Box>
					<Skeleton variant="rounded" width={150} height={40} />
				</Stack>
				<Skeleton variant="rounded" height={200} />
			</Stack>
		);
	}

	if (!invoiceData) {
		return (
			<Stack height="100%" alignItems="center" justifyContent="center" spacing={2} p={4}>
				<EmptyStateAvatar icon={<ReceiptLong />} />
				<Box textAlign="center">
					<Typography variant="h3">Invoice preview will appear here.</Typography>
					<Typography variant="body1">
						Enter date and optional usage data on the left to generate an invoice
						preview.
					</Typography>
				</Box>
			</Stack>
		);
	}

	return (
		<Stack p={2.5} spacing={2.5} overflow="hidden">
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<Box>
					<Typography variant="h4">
						{format(zoneDateOnly(invoiceData.invoiceDate), 'MMMM yyyy')}
					</Typography>
					<Typography variant="subtitle1">{invoiceData.organizationName}</Typography>
				</Box>
				{invoiceData.invoiceUrl && (
					<Button
						variant="contained"
						color="primary"
						startIcon={<OpenInNew />}
						href={invoiceData.invoiceUrl}
						target="_blank">
						Go to Stripe Draft Invoice
					</Button>
				)}
			</Stack>
			<TableContainer>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Description</TableCell>
							<TableCell align="right">Unit Price</TableCell>
							<TableCell align="right">Quantity</TableCell>
							<TableCell align="right">Amount</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{invoiceData.invoiceItems.map((item, index) => (
							<InvoiceItemRow key={index} item={item} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Typography variant="body1">
				*This invoice does not include sales tax. Sales tax will automatically be added in
				the official Stripe invoice.
			</Typography>
		</Stack>
	);
};
