import { gql, useQuery } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import {
	JournalSearchRequest,
	Query,
	QueryBillingJournalsForOrganizationGetArgs,
	QueryBillingJournalsGetArgs,
} from 'middleware-types';

const BILLING_JOURNALS_FIELDS = gql`
	fragment BillingJournalsFields on PagingResponseOfJournalEntry {
		totalCount
		items {
			id
			dateTimeUtc
			journalEntityId
			journalEntityType
			message
			reference
			severity
			journalEntityName
		}
	}
`;

// journals for current org
const GET_ORG_BILLING_JOURNALS = gql`
	${BILLING_JOURNALS_FIELDS}
	query GetOrgBillingJournals($organizationId: String!, $request: JournalSearchRequest!) {
		billingJournalsForOrganizationGet(organizationId: $organizationId, request: $request) {
			...BillingJournalsFields
		}
	}
`;

const useOrgBillingJournals = (request: JournalSearchRequest) => {
	const organizationId = useOrgId();
	const { data, previousData, loading } = useQuery<
		Pick<Query, 'billingJournalsForOrganizationGet'>,
		QueryBillingJournalsForOrganizationGetArgs
	>(GET_ORG_BILLING_JOURNALS, { variables: { organizationId, request } });

	const journals = data?.billingJournalsForOrganizationGet.items ?? [];
	const totalCount =
		data?.billingJournalsForOrganizationGet.totalCount ??
		previousData?.billingJournalsForOrganizationGet.totalCount ??
		0;

	return { journals, totalCount, loading };
};

// journals for all orgs
const GET_ALL_BILLING_JOURNALS = gql`
	${BILLING_JOURNALS_FIELDS}
	query GetAllBillingJournals($request: JournalSearchRequest!) {
		billingJournalsGet(request: $request) {
			...BillingJournalsFields
		}
	}
`;

const useAllBillingJournals = (request: JournalSearchRequest) => {
	const { data, previousData, loading } = useQuery<
		Pick<Query, 'billingJournalsGet'>,
		QueryBillingJournalsGetArgs
	>(GET_ALL_BILLING_JOURNALS, { variables: { request } });

	const journals = data?.billingJournalsGet.items ?? [];
	const totalCount =
		data?.billingJournalsGet.totalCount ?? previousData?.billingJournalsGet.totalCount ?? 0;

	return { journals, totalCount, loading };
};

// hook to combine the two possible queries
export const useBillingJournals = (request: JournalSearchRequest, allOrgs: boolean) => {
	return allOrgs ? useAllBillingJournals(request) : useOrgBillingJournals(request);
};
