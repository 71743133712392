import { ErrorOutline, InfoOutlined, Subject, WarningAmber } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';
import { JournalSeverity } from 'middleware-types';

const journalSeverityMap: Record<
	JournalSeverity,
	{ text: string; color: ChipProps['color']; icon: ChipProps['icon'] }
> = {
	Error: {
		text: 'Error',
		color: 'error',
		icon: <ErrorOutline />,
	},
	Warning: {
		text: 'Warning',
		color: 'warning',
		icon: <WarningAmber />,
	},
	Information: {
		text: 'Info',
		color: 'primary',
		icon: <InfoOutlined />,
	},
	Verbose: {
		text: 'Verbose',
		color: 'default',
		icon: <Subject />,
	},
};

interface JournalSeverityChipProps {
	severity: JournalSeverity;
}

export const JournalSeverityChip = ({ severity }: JournalSeverityChipProps) => {
	const { text, color, icon } = journalSeverityMap[severity];
	return <Chip label={text} color={color} icon={icon} size="small" />;
};
