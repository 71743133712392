import { ErrorOutline, InfoOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { Alert, AlertColor, Stack } from '@mui/material';
import { BillingAlert, BillingAlertSeverity } from 'middleware-types';
import { ReactElement } from 'react';

export const OrgBillingAlerts = ({ alerts }: { alerts: BillingAlert[] }) => {
	if (alerts.length === 0) return <></>;
	return (
		<Stack spacing={2.5}>
			{alerts.map((alert, index) => (
				<OrgBillingAlert key={index} alert={alert} />
			))}
		</Stack>
	);
};

const alertTypeMap: Record<BillingAlertSeverity, { severity: AlertColor; icon: ReactElement }> = {
	Information: {
		severity: 'info',
		icon: <InfoOutlined />,
	},
	Warning: {
		severity: 'warning',
		icon: <WarningAmberOutlined />,
	},
	Critical: {
		severity: 'error',
		icon: <ErrorOutline />,
	},
};

interface OrgBillingAlertProps {
	alert: BillingAlert;
}

const OrgBillingAlert = ({ alert }: OrgBillingAlertProps) => {
	const { severity, icon } = alertTypeMap[alert.severity];
	return (
		<Alert variant="outlined" severity={severity} icon={icon}>
			{alert.description}
		</Alert>
	);
};
