import { DeleteOutlined, EditOutlined, StarOutlined } from '@mui/icons-material';
import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Chip,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import { PhoneNumberText } from 'components/ui/fields';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { Representative, RepresentativeType } from 'middleware-types';
import { useState } from 'react';
import { useOrgRepresentativesDeleteMutation } from './hooks';
import { OrgRepresentativeForm } from './modal';

interface OrgRepCardProps {
	orgId: string;
	rep: Representative;
	canEdit: boolean;
	canDelete: boolean;
}

export const OrgRepCard = ({ orgId, rep, canEdit, canDelete }: OrgRepCardProps) => {
	const { orgRepresentativeDelete } = useOrgRepresentativesDeleteMutation();
	const [editOpen, setEditOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);

	return (
		<>
			<Card key={rep.id} variant="outlined" sx={{ display: 'flex', flexDirection: 'column' }}>
				<CardHeader
					sx={{ paddingBottom: 0.5 }}
					title={
						<Stack direction="row" justifyContent="space-between">
							<span>
								{rep.name.firstName} {rep.name.middleName} {rep.name.lastName}{' '}
								{rep.name.suffix}
							</span>
							{rep.representativeType.includes(RepresentativeType.Primary) && (
								<Tooltip title="Primary Representative">
									<StarOutlined fontSize="small" color="secondary" />
								</Tooltip>
							)}
						</Stack>
					}
					titleTypographyProps={{
						variant: 'h5',
					}}
					subheader={rep.title}
					subheaderTypographyProps={{
						variant: 'h6',
					}}
				/>
				<CardContent sx={{ flexGrow: 1 }}>
					<Stack
						sx={{ flexWrap: 'wrap' }}
						rowGap={1}
						direction="row"
						spacing={0.5}
						margin="0.25rem 0 0.5rem 0">
						{rep.representativeType.includes(RepresentativeType.Primary) && (
							<Chip label="Primary" size="small" color="primary" />
						)}
						{rep.representativeType.includes(RepresentativeType.Technical) && (
							<Chip label="Technical" size="small" color="success" />
						)}
						{rep.representativeType.includes(RepresentativeType.Operational) && (
							<Chip label="Operational" size="small" color="success" />
						)}
						{rep.representativeType.includes(RepresentativeType.Billing) && (
							<Chip label="Billing" size="small" color="success" />
						)}
					</Stack>
					<Typography sx={{ wordBreak: 'break-word' }}>{rep.emailAddress}</Typography>
					<Typography>
						Cell: <PhoneNumberText number={rep.cellPhoneNumber} />
					</Typography>
					{rep.workPhoneNumber && (
						<Typography>
							Work: <PhoneNumberText number={rep.workPhoneNumber} />
						</Typography>
					)}
				</CardContent>
				{canEdit && (
					<CardActions disableSpacing>
						<IconButton onClick={() => setEditOpen(true)}>
							<EditOutlined />
						</IconButton>
						{canDelete && (
							<IconButton onClick={() => setDeleteOpen(true)}>
								<DeleteOutlined />
							</IconButton>
						)}
					</CardActions>
				)}
			</Card>
			{/** edit modal */}
			<ModalOrDrawer open={editOpen} dialogProps={{ maxWidth: 'lg' }}>
				<OrgRepresentativeForm orgId={orgId} rep={rep} onClose={() => setEditOpen(false)} />
			</ModalOrDrawer>
			{/** delete modal */}
			<ModalOrDrawer open={deleteOpen}>
				<ConfirmationModalContent
					variant="destructive"
					primaryText={`Delete ${rep.name.firstName} ${rep.name.lastName}?`}
					secondaryText="Do you really want to delete this representative? This process cannot be undone."
					onSubmit={async () => await orgRepresentativeDelete(orgId, rep.id)}
					onClose={() => setDeleteOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};
