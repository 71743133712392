import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { Formik, FormikProps } from 'formik';
import { Conversation, UpdateConversationRequest } from 'middleware-types';
import { useValidation } from 'utils/useValidation';
import { useUpdateConversation } from '../hooks/conversations-hooks';

interface UpdateConversationModalProps extends ModalContentProps {
	conversation: Conversation;
}

export const UpdateConversationModal = ({
	conversation,
	onClose,
}: UpdateConversationModalProps) => {
	const updateConversation = useUpdateConversation();

	const initialValues: UpdateConversationRequest = {
		subject: conversation.subject,
	};

	const onSubmit = async (values: UpdateConversationRequest) => {
		const success = await updateConversation(values);
		if (success) onClose();
	};

	const validation = useValidation('UpdateConversationRequest');

	return (
		<Formik<UpdateConversationRequest>
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={validation.schema}
			enableReinitialize>
			{({
				isSubmitting,
				isValid,
				dirty,
				submitForm,
			}: FormikProps<UpdateConversationRequest>) => (
				<>
					<ModalHeader title="Edit Conversation Name" onClose={onClose} />
					<ModalContent>
						<TextField
							name="subject"
							label="Conversation Name"
							helperText="This will change the conversation name for all participants."
							required
						/>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Close
						</Button>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							loading={isSubmitting}
							disabled={!isValid || !dirty || isSubmitting}
							onClick={submitForm}>
							Save
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};
