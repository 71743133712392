import { Box, Card, Divider, Stack, Typography } from '@mui/material';
import { BillingJournalsFilter } from 'components/ui/billing-journals-table/billing-journals-filter';
import { BillingJournalsProvider } from 'components/ui/billing-journals-table/billing-journals-provider';
import { BillingJournalsSearch } from 'components/ui/billing-journals-table/billing-journals-search';
import { BillingJournalsTable } from 'components/ui/billing-journals-table/table/billing-journals-table';

export const BillingJournalsCard = () => {
	return (
		<Card>
			<Box px={2.5} py={2}>
				<Typography variant="h2">Journals</Typography>
			</Box>
			<Divider />
			<BillingJournalsProvider allOrgs={false}>
				<Stack p={2.5} pb={0.5} spacing={2.5}>
					<Stack direction="row" spacing={2}>
						<BillingJournalsSearch />
						<BillingJournalsFilter />
					</Stack>
					<BillingJournalsTable />
				</Stack>
			</BillingJournalsProvider>
		</Card>
	);
};
