import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { useEmptyTrash } from '../hooks/conversation-labels-hooks';

export const EmptyTrashModal = ({ onClose }: ModalContentProps) => {
	const { emptyTrash, loading } = useEmptyTrash();

	return (
		<ConfirmationModalContent
			variant="destructive"
			primaryText="Empty Trash?"
			secondaryText="All conversations will be permanently deleted from your inbox. This cannot be undone."
			confirmText="Empty"
			onSubmit={emptyTrash}
			onClose={onClose}
		/>
	);
};
