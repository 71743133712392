import {
	AccessTimeFilled,
	DeleteOutlined,
	EditOutlined,
	Star,
	StarOutline,
} from '@mui/icons-material';
import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	IconButton,
	Stack,
	Tooltip,
} from '@mui/material';
import { AddressText } from 'components/ui/fields';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { format } from 'date-fns';
import { LocationAddress } from 'middleware-types';
import { useState } from 'react';
import { zoneDateOnly } from 'utils/utils';
import { AddressType, LocationAddressForm, LocationAddressFormValues } from './locations-form';

interface LocationInformationItemProps {
	labels?: Record<string, string>;
	locationAddress: Omit<LocationAddress, 'parentId'>;
	disabled?: boolean;
	deleteLocation: (locationAddressId: string) => Promise<boolean>;
	addOrUpdate: (location: LocationAddressFormValues, locationId?: string) => Promise<boolean>;
}

/**
 * LocationInformationItem - Each location address card item is rendered here
 * It maintains it's own delete, edit and make primary modals.
 *
 * @param {{
 * 	userId: string;
 * 	locationAddress: LocationAddress,
 * 	disabled?: boolean;
 * }} { userId, locationAddress, disabled }
 * @return {*}
 */
export const LocationInformationItem = ({
	labels,
	locationAddress,
	disabled,
	deleteLocation,
	addOrUpdate,
}: LocationInformationItemProps) => {
	const [editOpen, setEditOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [makePrimaryOpen, setMakePrimaryOpen] = useState(false);

	const makePrimary = async () => {
		const { id = undefined, ...update } = {
			...locationAddress,
			addressType: AddressType.Primary,
		};
		return await addOrUpdate(update, id);
	};

	const labelText: Record<string, string> = {
		[AddressType.Primary]: AddressType.Primary,
		[AddressType.Alternate]: AddressType.Alternate,
		[AddressType.Temporary]: AddressType.Temporary,
		...labels,
	};
	return (
		<Card variant="outlined" sx={{ display: 'flex', flexDirection: 'column' }}>
			<CardHeader
				sx={{ paddingBottom: 0.5 }}
				title={
					<Stack direction="row" justifyContent="space-between">
						<span>{labelText[locationAddress.addressType]} Address</span>
						{locationAddress.addressType === AddressType.Primary && (
							<Tooltip title="Primary Location">
								<Star fontSize="small" color="primary" />
							</Tooltip>
						)}
					</Stack>
				}
				titleTypographyProps={{
					variant: 'h5',
				}}
				subheader={
					locationAddress.fromDate && locationAddress.toDate ? (
						<Stack direction="row" alignItems="center" spacing={1}>
							<AccessTimeFilled fontSize="small" color="warning" />
							<span>
								{format(zoneDateOnly(locationAddress.fromDate), 'MMM. d, yyyy')} -{' '}
								{format(zoneDateOnly(locationAddress.toDate), 'MMM. d, yyyy')}
							</span>
						</Stack>
					) : undefined
				}
				subheaderTypographyProps={{
					variant: 'h6',
					sx: {
						color: 'warning[700]',
					},
				}}
			/>
			<CardContent sx={{ flexGrow: 1 }}>
				<Stack>
					<AddressText {...locationAddress.address} />
				</Stack>
			</CardContent>
			{disabled !== false && (
				<CardActions disableSpacing>
					<IconButton onClick={() => setEditOpen(true)}>
						<EditOutlined />
					</IconButton>
					{locationAddress.addressType != AddressType.Primary && (
						<>
							<IconButton onClick={() => setDeleteOpen(true)}>
								<DeleteOutlined />
							</IconButton>
							{locationAddress.addressType !== AddressType.Temporary && (
								<IconButton onClick={() => setMakePrimaryOpen(true)}>
									<StarOutline />
								</IconButton>
							)}
						</>
					)}
				</CardActions>
			)}
			{/** delete modal */}
			<ModalOrDrawer open={deleteOpen}>
				<ConfirmationModalContent
					variant="destructive"
					primaryText="Delete Address?"
					secondaryText="Do you really want to delete the address? This process cannot be undone."
					onSubmit={async () => await deleteLocation(locationAddress.id)}
					onClose={() => setDeleteOpen(false)}
				/>
			</ModalOrDrawer>
			{/** make primary modal */}
			<ModalOrDrawer open={makePrimaryOpen}>
				<ConfirmationModalContent
					graphic={<Box component="img" src="/img/star-primary-large.svg" />}
					primaryText="Make Primary?"
					secondaryText="Do you really want to make this the primary address?"
					confirmText="Make Primary"
					onSubmit={makePrimary}
					onClose={() => setMakePrimaryOpen(false)}
				/>
			</ModalOrDrawer>
			{/** edit modal */}
			<ModalOrDrawer open={editOpen} dialogProps={{ maxWidth: 'lg' }}>
				<LocationAddressForm
					addOrUpdate={addOrUpdate}
					locationAddress={locationAddress}
					onClose={() => setEditOpen(false)}
				/>
			</ModalOrDrawer>
		</Card>
	);
};
