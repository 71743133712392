import { gql, useMutation, useQuery } from '@apollo/client';
import { Business, ErrorOutlined, Link } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	Container,
	Stack,
	Typography,
} from '@mui/material';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { Loading } from 'components/ui/loading';
import { PageContent, PageTitle } from 'components/ui/page';
import { useToast } from 'components/ui/toast';
import { Mutation, MutationLegacyInvitationAcceptArgs, Query } from 'middleware-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageError } from 'utils/errors';
import { useSession } from 'utils/session';
import { theme } from 'utils/theme';

const PROCESS_INVITATION = gql`
	query ProcessInvitation($invitationId: ID!) {
		legacyInvitationProcess(invitationId: $invitationId) {
			adjCoName
			adjCoLogo
			userName
			systemUrl
		}
	}
`;

const useProcessInvitation = (invitationId: string) => {
	const { data, error, loading } = useQuery<Pick<Query, 'legacyInvitationProcess'>>(
		PROCESS_INVITATION,
		{
			variables: {
				invitationId,
			},
			skip: !invitationId,
			onError: (e) => console.log(JSON.stringify(e)),
		}
	);

	return { company: data?.legacyInvitationProcess, error, loading };
};

const ACCEPT_INVITATION = gql`
	mutation AcceptInvitation($invitationId: ID!) {
		legacyInvitationAccept(invitationId: $invitationId)
	}
`;

const useAcceptInvitation = (invitationId: string) => {
	const toast = useToast();
	const navigate = useNavigate();

	const [acceptInvitation, { error, loading }] = useMutation<
		Pick<Mutation, 'legacyInvitationAccept'>,
		MutationLegacyInvitationAcceptArgs
	>(ACCEPT_INVITATION, {
		variables: {
			invitationId,
		},
		onCompleted: () => {
			toast.push('Invitation accepted successfully', { variant: 'success' });
			navigate('/', { replace: true });
		},
		onError: (e) => {
			toast.push(
				'There was an issue accepting your invitation. Please try again later or contact support.',
				{ variant: 'error' }
			);
			console.log(JSON.stringify(e));
		},
	});

	return { acceptInvitation, error, loading };
};

export const LegacyInvitationPage = () => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const invitationId = params.get('invitationID') ?? params.get('invitationid');
	if (!invitationId) throw new PageError('Invalid invitation ID');
	const { company, error, loading } = useProcessInvitation(invitationId);
	const {
		acceptInvitation,
		error: acceptError,
		loading: acceptLoading,
	} = useAcceptInvitation(invitationId);
	const navigate = useNavigate();
	const { user } = useSession();

	if (error) throw new PageError(error);

	if (acceptError) {
		return (
			<PageContent>
				<Container maxWidth="sm" sx={{ height: '100%' }}>
					<Stack
						height="100%"
						spacing={1}
						justifyContent="center"
						alignItems="center"
						textAlign="center">
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
						<Typography variant="h2">
							Sorry, we were unable to process the invitation
						</Typography>
						<Typography>
							If you believe this is a mistake, please contact the person who sent you
							the invitaion.
						</Typography>
					</Stack>
				</Container>
			</PageContent>
		);
	}

	if (acceptLoading) {
		return <Loading message="Processing Invitation..." />;
	}

	if (loading) return <Loading />;

	return (
		<>
			<PageTitle title="Invitation" />
			<PageContent>
				<Container maxWidth="md" sx={{ height: '100%' }}>
					<Stack
						height="100%"
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						spacing={2}>
						<Stack
							display="flex"
							alignItems="center"
							justifyContent="center"
							spacing={2}>
							<LegacyAdjustmentCompanyDisplayCard company={company} />
							<Link color="action" fontSize="large" />
							<Card
								sx={{
									width: '100%',
								}}>
								<CardContent
									sx={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										borderBottom: `1px solid ${theme.palette.divider}`,
									}}
									className="bg-navy-50">
									<Box
										component="img"
										height="1.85rem"
										mr={2}
										src="/img/evolve-logo.svg"
										alt="FTEvolve"
									/>
									<Typography className="text-navy-700 font-bold">
										EVOLVE ACCOUNT
									</Typography>
								</CardContent>
								<CardContent
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}>
									<UserEmblemAvatar
										sx={{
											marginRight: 2,
										}}
										id={user.userId}
										noDropdown
									/>
									<Stack>
										<Typography variant="h2">{user?.displayName}</Typography>
									</Stack>
								</CardContent>
							</Card>
						</Stack>
						<Stack display="flex" flexDirection="column" alignItems="center">
							<Typography m={2} variant="h1" align="center">
								Confirm the two accounts to be linked
							</Typography>
							<Typography variant="subtitle1" align="center">
								Evolve will link this FileTrac Legacy account to the current logged
								in user. Please ensure that you are logged into the correct Evolve
								account that this FileTrac Legacy account should be linked to.
							</Typography>
						</Stack>
						<Stack display="flex" flexDirection="row">
							<Button
								sx={{ m: 0.5 }}
								variant="outlined"
								onClick={() => navigate('/')}>
								Back to Dashboard
							</Button>
							<Button
								className="bg-navy-500 text-white"
								sx={{ m: 0.5 }}
								variant="outlined"
								onClick={() => acceptInvitation()}>
								<Link sx={{ marginRight: 1 }} /> Link Accounts
							</Button>
						</Stack>
					</Stack>
				</Container>
			</PageContent>
		</>
	);
};

// This component does not always require userName
// so it cannot have props of type LegacyInvitationInfoResponse
type legacyAdjustmentCompanyDisplayCardProps = {
	adjCoLogo?: string;
	systemUrl: string;
	adjCoName?: string;
	userName?: string;
};

export const LegacyAdjustmentCompanyDisplayCard = ({
	company,
}: {
	company: legacyAdjustmentCompanyDisplayCardProps | undefined;
}) => {
	return (
		<Card>
			<CardContent
				sx={{
					display: 'flex',
					paddingY: '.4rem',
					alignItems: 'center',
					justifyContent: 'center',
					borderBottom: `1px solid ${theme.palette.divider}`,
					bgcolor: 'warning.50',
				}}>
				<img
					src="/img/FT-logo.svg"
					alt="FileTrac"
					style={{ marginRight: 2, height: '2.25rem' }}
				/>
				<Typography color="warning.800" fontWeight="bold" variant="overline">
					FILETRAC LEGACY ACCOUNT
				</Typography>
			</CardContent>
			<CardContent
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}>
				<Avatar
					variant="square"
					sx={{
						'& img': { objectFit: 'contain' },
						'marginRight': 2,
						'background': 'transparent',
					}}
					{...{
						src:
							company?.systemUrl && company?.adjCoLogo
								? `${company?.systemUrl}/../images/logos/${company?.adjCoLogo}`
								: undefined,
					}}>
					<Business />
				</Avatar>
				<Stack>
					<Typography variant="h2">{company?.userName}</Typography>
					<Typography variant="body1">{company?.adjCoName}</Typography>
				</Stack>
			</CardContent>
		</Card>
	);
};
