import {
	gql,
	QueryHookOptions,
	QueryResult,
	useLazyQuery,
	useMutation,
	useQuery,
} from '@apollo/client';
import {
	ArticleOutlined,
	BugReportOutlined,
	CheckCircleOutlined,
	CodeOutlined,
	ErrorOutlined,
	FolderOutlined,
	FolderZipOutlined,
	GraphicEqOutlined,
	ImageOutlined,
	InsertDriveFileOutlined,
	SlideshowOutlined,
	SvgIconComponent,
	SwapVerticalCircleOutlined,
	WatchLaterOutlined,
} from '@mui/icons-material';
import { Chip } from '@mui/material';
import axios, { AxiosProgressEvent } from 'axios';
import {
	GET_FOLDER_INFO,
	GET_ROOT_FOLDER_CONTENTS,
} from 'components/pages/documents/hooks/folders/use-folder';
import { FILE_INFO_FIELDS } from 'components/pages/documents/utils/fragments.graphql';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import {
	CsvFileOutlined,
	ExcelFileOutlined,
	PdfFileOutlined,
	PowerPointFileOutlined,
	WordDocumentOutlined,
	XactimateFileOutlined,
} from 'components/ui/icons';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	useModal,
} from 'components/ui/modal';
import { useToast } from 'components/ui/toast';
import {
	FileInstanceCopyRequest,
	FileInstanceInformation,
	Mutation,
	MutationFileInstanceCopyArgs,
	Query,
	QueryGetFileInstanceDownloadUrlArgs,
	QueryGetUploadUrlArgs,
	VirusStatus,
} from 'middleware-types';
import { ComponentProps } from 'react';
import { handleNoResponse, responseHasErrors } from './errors';

/** get icon for file extension */
interface ExtensionIconProps extends ComponentProps<SvgIconComponent> {
	filename: string;
}

export const ExtensionIcon = ({ filename, ...props }: ExtensionIconProps) => {
	const extension = filename.split('.').pop()?.toLowerCase();
	switch (extension) {
		// Image types
		case 'bmp':
		case 'png':
		case 'jpg':
		case 'jpeg':
		case 'heic':
		case 'gif':
		case 'tiff':
		case 'svg':
			return <ImageOutlined {...props} />;
		// Folder - Assuming usage context allows folder handling
		case 'folder':
			return <FolderOutlined {...props} />;
		// Code files
		case 'htm':
		case 'html':
		case 'map':
		case 'css':
			return <CodeOutlined {...props} />;
		// Audio files
		case 'wav':
		case 'aiff':
		case 'midi':
		case 'ra':
		case 'mp3':
			return <GraphicEqOutlined {...props} />;
		// Video files
		case 'mov':
		case 'avi':
		case 'qt':
		case 'mp4':
		case 'mkv':
			return <SlideshowOutlined {...props} />;
		// Text file
		case 'txt':
		case 'md':
		case 'json':
			return <ArticleOutlined {...props} />;
		// Compressed files
		case 'zip':
			return <FolderZipOutlined {...props} />;
		// Document files
		case 'doc':
		case 'docx':
			return <WordDocumentOutlined {...props} />;
		// Spreadsheet files
		case 'xls':
		case 'xlsx':
			return <ExcelFileOutlined {...props} />;
		// Presentation files
		case 'ppt':
		case 'pptx':
			return <PowerPointFileOutlined {...props} />;
		// CSV files
		case 'csv':
			return <CsvFileOutlined {...props} />;
		// PDF files
		case 'pdf':
			return <PdfFileOutlined {...props} />;
		// ESX files
		case 'esx':
			return <XactimateFileOutlined {...props} />;
		default:
			return <InsertDriveFileOutlined {...props} />;
	}
};

// get virus status chip
interface VirusStatusChipProps {
	virusStatus: VirusStatus | undefined;
}

export const VirusStatusChip = ({ virusStatus }: VirusStatusChipProps) => {
	switch (virusStatus) {
		case VirusStatus.Pending:
			return <Chip size="small" icon={<WatchLaterOutlined />} label="Pending" />;
		case VirusStatus.Clean:
			return (
				<Chip size="small" icon={<CheckCircleOutlined />} label="Clean" color="success" />
			);
		case VirusStatus.Infected:
			return (
				<Chip size="small" icon={<BugReportOutlined />} label="Infected" color="error" />
			);
		case VirusStatus.ScanFailed:
			return (
				<Chip size="small" icon={<ErrorOutlined />} label="Scan Failed" color="warning" />
			);
		case VirusStatus.TooLargeToScan:
			return (
				<Chip
					size="small"
					icon={<ErrorOutlined />}
					label="Too Large to Scan"
					color="warning"
				/>
			);
		default:
			return <Chip size="small" icon={<SwapVerticalCircleOutlined />} label="Pending Save" />;
	}
};

/** get upload url */
export const GET_UPLOAD_URL = gql`
	query GetUploadUrl(
		$fileName: String!
		$fileSize: Float!
		$clientReference: String
		$updatesFileId: String
		$requestedAccessLevel: FileAccessLevel
	) {
		getUploadUrl(
			fileName: $fileName
			fileSize: $fileSize
			clientReference: $clientReference
			updatesFileId: $updatesFileId
			requestedAccessLevel: $requestedAccessLevel
		) {
			clientReference
			blobUploadUrl
			expiresUtc
			fileUploadToken
		}
	}
`;

export const useGetUploadUrl = () => {
	return useLazyQuery<Pick<Query, 'getUploadUrl'>, QueryGetUploadUrlArgs>(GET_UPLOAD_URL);
};

/** upload file */
export const uploadFile = async (
	uploadUrl: string,
	file: File,
	onUploadProgress?: (e: AxiosProgressEvent) => void
) => {
	await axios.put(uploadUrl, file, {
		headers: {
			'Content-Type': '', // Content-Type will invalidate the signed url signature so force it to be blank
		},
		onUploadProgress,
	});
};

/** get download url */
const GET_FILE_INSTANCE_DOWNLOAD_URL = gql`
	query GetFileInstanceDownloadUrl(
		$fileInstanceId: String!
		$sasUrlOnly: Boolean
		$inline: Boolean
	) {
		getFileInstanceDownloadUrl(
			fileInstanceId: $fileInstanceId
			sasUrlOnly: $sasUrlOnly
			inline: $inline
		) {
			downloadUrl
			expiresUtc
		}
	}
`;

const checkFileExpiration = (
	result: QueryResult<
		Pick<Query, 'getFileInstanceDownloadUrl'>,
		QueryGetFileInstanceDownloadUrlArgs
	>
) => {
	const { data, loading, refetch } = result;
	if (loading) return;
	const expiresUtc = data?.getFileInstanceDownloadUrl.expiresUtc;
	if (!expiresUtc) return;
	const expiration = new Date(expiresUtc);
	if (new Date() > expiration) refetch();
};

export const useDownloadUrl = (
	options: QueryHookOptions<
		Pick<Query, 'getFileInstanceDownloadUrl'>,
		QueryGetFileInstanceDownloadUrlArgs
	>
) => {
	const result = useQuery<
		Pick<Query, 'getFileInstanceDownloadUrl'>,
		QueryGetFileInstanceDownloadUrlArgs
	>(GET_FILE_INSTANCE_DOWNLOAD_URL, options);
	checkFileExpiration(result);
	return result;
};

export const useLazyDownloadUrl = (
	options: QueryHookOptions<
		Pick<Query, 'getFileInstanceDownloadUrl'>,
		QueryGetFileInstanceDownloadUrlArgs
	>
) => {
	const result = useLazyQuery<
		Pick<Query, 'getFileInstanceDownloadUrl'>,
		QueryGetFileInstanceDownloadUrlArgs
	>(GET_FILE_INSTANCE_DOWNLOAD_URL, options);
	return result;
};

/** download file */
const downloadFile = (
	url: string,
	options: {
		inline?: boolean;
		fileName?: string;
		type?: string;
	}
) => {
	const link = document.createElement('a');
	link.href = url;
	if (!options.inline) link.download = options.fileName ?? '';
	if (options.type) link.type = options.type;
	link.target = '_blank';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const downloadFileObject = (file: File, inline?: boolean) => {
	// Create a Blob from the file with an explicitly set MIME type to ensure correct handling across browsers.
	const blob = new Blob([file], { type: file.type });
	const url = URL.createObjectURL(blob);
	downloadFile(url, { inline, fileName: file.name, type: file.type });
	URL.revokeObjectURL(url);
};

export const useDownloadFileInstance = () => {
	const toast = useToast();
	const { showModal } = useModal();
	const [getDownloadUrl] = useLazyDownloadUrl({ fetchPolicy: 'network-only' });

	const downloadFileInstance = (instance: FileInstanceInformation, inline?: boolean) => {
		// if the file is infected, show error and return
		if (instance.virusStatus === VirusStatus.Infected) {
			toast.push('The file you are trying to download has been corrupted.', {
				variant: 'error',
			});
			return;
		}

		const download = async () => {
			// get sas url
			const res = await getDownloadUrl({
				variables: {
					fileInstanceId: instance.id,
					sasUrlOnly: true,
					inline,
				},
			});
			const url = res.data?.getFileInstanceDownloadUrl.downloadUrl;
			if (res.error || !url) {
				toast.push('An error occurred while trying to download the file.', {
					variant: 'error',
				});
				return;
			}

			// prefetch to make sure the url is valid
			axios
				.get(url, { headers: { Range: 'bytes=0-0' } })
				.then((res) => {
					if (res.status !== 206) throw new Error();
					// if the initial call suceeded, we can finally download the file
					downloadFile(url, {
						inline,
						fileName: instance.asciiFileName,
					});
				})
				.catch(() =>
					toast.push(
						'The file you are trying to download is not currently available. If this error continues, please contact technical support.',
						{ variant: 'error' }
					)
				);
		};

		// if there was no virus scan, show a modal before downloading
		if (
			instance.virusStatus === VirusStatus.Pending ||
			instance.virusStatus === VirusStatus.TooLargeToScan ||
			instance.virusStatus === VirusStatus.ScanFailed
		) {
			showModal({
				title: 'Are you sure?',
				content: (
					<ConfirmModalContent
						visual={
							<EmptyStateAvatar
								avatarProps={{ bgcolor: 'error.50' }}
								iconProps={{ color: 'error.500' }}
								icon={<ErrorOutlined />}
							/>
						}
						subheadline="Are you sure?"
						informativeContent="The file did not complete a virus scan. Do you want to download anyway?"
					/>
				),
				actions: (
					<ModalActions>
						<ModalActionButton variant="outlined">Cancel</ModalActionButton>
						<ModalActionButton variant="contained" color="error" onClick={download}>
							Download anyway
						</ModalActionButton>
					</ModalActions>
				),
			});
		} else {
			download();
		}
	};

	return downloadFileInstance;
};

const COPY_FILE_INSTANCE = gql`
	${FILE_INFO_FIELDS}
	mutation fileInstanceCopy($fileInstanceId: String!, $request: FileInstanceCopyRequest!) {
		fileInstanceCopy(fileInstanceId: $fileInstanceId, request: $request) {
			...FileInfoFields
		}
	}
`;

export const useFileInstanceCopy = (instanceId: string) => {
	const toast = useToast();

	const [_fileInstanceCopy, loading] = useMutation<
		Pick<Mutation, 'fileInstanceCopy'>,
		MutationFileInstanceCopyArgs
	>(COPY_FILE_INSTANCE);

	const copyFileInstance = async (request: FileInstanceCopyRequest) => {
		return await _fileInstanceCopy({
			variables: {
				fileInstanceId: instanceId,
				request: request,
			},
			refetchQueries: request.folderId ? [GET_FOLDER_INFO] : [GET_ROOT_FOLDER_CONTENTS],
			awaitRefetchQueries: true,
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Successfully copied file.', {
					variant: 'success',
				});
				return true;
			})
			.catch((e) => {
				console.log(JSON.stringify(e));
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		copyFileInstance,
		loading,
	};
};
