import { gql, useMutation } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationBillingAdHocBillingItemDeleteArgs,
	Query,
	QueryBillingAdHocBillingItemsGetArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { GET_AD_HOC_ITEMS } from './use-ad-hoc-items';

const DELETE_AD_HOC_ITEM = gql`
	mutation DeleteAdHocItem($organizationId: String!, $billingItemId: String!) {
		billingAdHocBillingItemDelete(
			organizationId: $organizationId
			billingItemId: $billingItemId
		)
	}
`;

export const useDeleteAdHocItem = () => {
	const toast = useToast();
	const organizationId = useOrgId();

	const [_deleteItem, { loading }] = useMutation<
		Pick<Mutation, 'billingAdHocBillingItemDelete'>,
		MutationBillingAdHocBillingItemDeleteArgs
	>(DELETE_AD_HOC_ITEM);

	const deleteItem = async (billingItemId: string) => {
		return await _deleteItem({
			variables: { organizationId, billingItemId },
			update: (cache, { data }) => {
				if (!data) return;
				cache.updateQuery<
					Pick<Query, 'billingAdHocBillingItemsGet'>,
					QueryBillingAdHocBillingItemsGetArgs
				>({ query: GET_AD_HOC_ITEMS, variables: { organizationId } }, (queryData) => {
					if (!queryData) return;
					return {
						billingAdHocBillingItemsGet: {
							adHocBillingItems:
								queryData.billingAdHocBillingItemsGet.adHocBillingItems.filter(
									(item) => item.id !== billingItemId
								),
						},
					};
				});
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Ad-hoc line item deleted successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { deleteItem, loading };
};
