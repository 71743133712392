import { useSetOrgPrivate } from 'components/pages/org/account/context-menu-hooks';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { Visibility } from './hierarchy-context-menu';

interface ConfirmChangeOrgVisibilityModalProps extends ModalContentProps {
	organizationId: string;
	displayName: string | undefined;
	visibilityToSet: Visibility;
}

export const ConfirmChangeOrgVisibilityModal = ({
	organizationId,
	displayName,
	visibilityToSet,
	onClose,
}: ConfirmChangeOrgVisibilityModalProps) => {
	const { setOrgPrivate } = useSetOrgPrivate(organizationId);
	const onSubmit = async () =>
		await setOrgPrivate(visibilityToSet === Visibility.Private ? true : false);

	return (
		<ConfirmationModalContent
			variant={visibilityToSet === Visibility.Public ? 'activate' : 'destructive'}
			title={`Make Organization ${visibilityToSet}`}
			primaryText="Are you sure?"
			secondaryText={`Do you really want to make the organization ${displayName} ${visibilityToSet.toLowerCase()}?`}
			confirmText={`Make ${visibilityToSet}`}
			onSubmit={onSubmit}
			onClose={onClose}
		/>
	);
};
