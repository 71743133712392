import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { ConnectionState } from 'middleware-types';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import { ConnectionButton } from '../../shared/ConnectionButton';
import { ConnectionStateExtended } from '../../shared/types';
import {
	OrgConnectionInfo,
	useAcceptOrgConnection,
	useCancelOrgConnectionRequest,
	useIgnoreOrgConnection,
	useOrgConnectionRequest,
	useRemoveOrgConnection,
} from './hooks';

interface Props {
	organization: OrgConnectionInfo;
}

export const OrgConnectionButton = ({ organization }: Props) => {
	switch (organization.connectionState) {
		case ConnectionState.Connected:
			return <RemoveConnectionButton organization={organization} />;
		case ConnectionState.Pending:
			return <CancelRequestButton organization={organization} />;
		case ConnectionStateExtended.IncomingRequest:
			return <AcceptDeclineButtons organization={organization} />;
		case ConnectionState.Ignored:
		case ConnectionStateExtended.NotConnected:
			return <RequestButton organization={organization} />;
	}
};

const RequestButton = ({ organization }: Props) => {
	const { requestConnection, loading } = useOrgConnectionRequest();
	const { hasPermission: hasAssociatePermission } = useAssociateUser(organization.orgId);
	const { hasPermission: hasSitePermission } = useSiteUser();

	const showButton =
		hasAssociatePermission(Permission.Org_Social_Network_U) ||
		hasSitePermission(Permission.Site_OrgNetwork_U);

	if (!showButton) return null;
	return (
		<ConnectionButton
			connectionState={ConnectionStateExtended.NotConnected}
			onClick={() => requestConnection(organization.orgId, organization.entityId)}
			loading={loading}
			color="darkSecondary"
		/>
	);
};

const CancelRequestButton = ({ organization }: Props) => {
	const { cancelConnectionRequest, loading } = useCancelOrgConnectionRequest();
	const { hasPermission: hasAssociatePermission } = useAssociateUser(organization.orgId);
	const { hasPermission: hasSitePermission } = useSiteUser();

	const showButton =
		hasAssociatePermission(Permission.Org_Social_Network_U) ||
		hasSitePermission(Permission.Site_OrgNetwork_U);

	if (!showButton) return null;
	return (
		<ConnectionButton
			connectionState={ConnectionState.Pending}
			onClick={() => cancelConnectionRequest(organization.orgId, organization.connectionId!)}
			loading={loading}
			color="darkSecondary"
		/>
	);
};

const AcceptDeclineButtons = ({ organization }: Props) => {
	const { acceptConnection, loading: acceptLoading } = useAcceptOrgConnection();
	const { ignoreConnection, loading: ignoreLoading } = useIgnoreOrgConnection();
	const { hasPermission: hasAssociatePermission } = useAssociateUser(organization.orgId);
	const { hasPermission: hasSitePermission } = useSiteUser();
	const loading = acceptLoading || ignoreLoading;

	const showButtons =
		hasAssociatePermission(Permission.Org_Social_Network_U) ||
		hasSitePermission(Permission.Site_OrgNetwork_U);

	if (!showButtons) return null;
	return (
		<ConnectionButton
			connectionState={ConnectionStateExtended.IncomingRequest}
			onClick={() => acceptConnection(organization.orgId, organization.connectionId!)}
			onClick2={() => ignoreConnection(organization.orgId, organization.connectionId!)}
			loading={loading}
			color="darkSecondary"
		/>
	);
};

const RemoveConnectionButton = ({ organization }: Props) => {
	const { hasPermission: hasAssociatePermission } = useAssociateUser(organization.orgId);
	const { hasPermission: hasSitePermission } = useSiteUser();

	const [modalOpen, setModalOpen] = useState(false);

	const showButton =
		hasAssociatePermission(Permission.Org_Social_Network_D) ||
		hasSitePermission(Permission.Site_OrgNetwork_D);

	if (!showButton) return null;
	return (
		<>
			<ConnectionButton
				connectionState={ConnectionState.Connected}
				color="darkSecondary"
				onClick={() => setModalOpen(true)}
			/>
			<ModalOrDrawer open={modalOpen}>
				<DisconnectModal organization={organization} onClose={() => setModalOpen(false)} />
			</ModalOrDrawer>
		</>
	);
};

const DisconnectModal = ({ organization, onClose }: Props & ModalContentProps) => {
	const { removeConnection } = useRemoveOrgConnection();
	const onSubmit = async () => {
		return await removeConnection(organization.orgId, organization.connectionId!);
	};

	return (
		<ConfirmationModalContent
			variant="destructive"
			title="Remove Connection"
			primaryText={`Disconnect with ${organization.entityName}?`}
			secondaryText={`Do you really want to remove your organization's connection with ${organization.entityName}? This process cannot be undone.`}
			confirmText="Disconnect"
			onSubmit={onSubmit}
			onClose={onClose}
		/>
	);
};
