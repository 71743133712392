import { Box, MenuItem } from '@mui/material';
import { FilterButton } from '../filter-button';
import { useBillingJournalsContext } from './billing-journals-provider';
import { SelectField } from '../fields';
import { JournalSeverity } from 'middleware-types';

export const BillingJournalsFilter = () => {
	const { severity, setSeverity } = useBillingJournalsContext();

	return (
		<FilterButton
			initialValues={{ severity }}
			setFilters={(values) => setSeverity(values.severity)}>
			<Box p={1} width={200}>
				<SelectField name="severity" label="Severity">
					{Object.values(JournalSeverity).map((option) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</SelectField>
			</Box>
		</FilterButton>
	);
};
