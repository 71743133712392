import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { ConnectionState } from 'middleware-types';
import { useState } from 'react';
import { ConnectionButton } from '../../shared/ConnectionButton';
import { ComprehensiveConnectionState, ConnectionStateExtended } from '../../shared/types';
import {
	useAcceptUserConnection,
	useCancelUserConnectionRequest,
	useIgnoreUserConnection,
	useRemoveUserConnection,
	useUserConnectionRequest,
} from './hooks';

interface Props {
	user: UserConnectionInfo;
}

//types
export interface UserConnectionInfo {
	connectionId?: string | undefined;
	connectionState?: ComprehensiveConnectionState;
	entityId: string;
	entityName: string;
}

export const UserConnectionButton = ({ user }: Props) => {
	switch (user.connectionState) {
		case ConnectionState.Connected:
			return <RemoveConnectionButton user={user} />;
		case ConnectionState.Pending:
			return <CancelRequestButton user={user} />;
		case ConnectionStateExtended.IncomingRequest:
			return <AcceptDeclineButtons user={user} />;
		case ConnectionState.Ignored:
		case ConnectionStateExtended.NotConnected:
			return <RequestButton user={user} />;
		default:
			return <></>;
	}
};

const RequestButton = ({ user }: Props) => {
	const { requestConnection, loading } = useUserConnectionRequest();
	return (
		<ConnectionButton
			connectionState={ConnectionStateExtended.NotConnected}
			onClick={() => requestConnection(user.entityId)}
			loading={loading}
			color="primary"
		/>
	);
};

const CancelRequestButton = ({ user }: Props) => {
	const { cancelConnectionRequest, loading } = useCancelUserConnectionRequest();
	return (
		<ConnectionButton
			connectionState={ConnectionState.Pending}
			onClick={() => cancelConnectionRequest(user.connectionId!)}
			loading={loading}
			color="primary"
		/>
	);
};

const AcceptDeclineButtons = ({ user }: Props) => {
	const { acceptConnection, loading: acceptLoading } = useAcceptUserConnection();
	const { ignoreConnection, loading: ignoreLoading } = useIgnoreUserConnection();
	const loading = acceptLoading || ignoreLoading;

	return (
		<ConnectionButton
			connectionState={ConnectionStateExtended.IncomingRequest}
			onClick={() => acceptConnection(user.connectionId!)}
			onClick2={() => ignoreConnection(user.connectionId!)}
			loading={loading}
			color="primary"
		/>
	);
};

const RemoveConnectionButton = ({ user }: Props) => {
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<ConnectionButton
				connectionState={ConnectionState.Connected}
				color="primary"
				onClick={() => setModalOpen(true)}
			/>
			<ModalOrDrawer open={modalOpen}>
				<DisconnectModal user={user} onClose={() => setModalOpen(false)} />
			</ModalOrDrawer>
		</>
	);
};

const DisconnectModal = ({ user, onClose }: Props & ModalContentProps) => {
	const { removeConnection } = useRemoveUserConnection();
	const onSubmit = async () => {
		return await removeConnection(user.connectionId!);
	};

	return (
		<ConfirmationModalContent
			variant="destructive"
			title="Remove Connection"
			primaryText={`Disconnect with ${user.entityName}?`}
			secondaryText={`Do you really want to remove your connection with ${user.entityName}? This process cannot be undone.`}
			confirmText="Disconnect"
			onSubmit={onSubmit}
			onClose={onClose}
		/>
	);
};
