import { gql, useLazyQuery } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import { Query, QueryBillingCustomerDashboardUrlGetArgs } from 'middleware-types';

const GET_BILLING_DASHBOARD_URL = gql`
	query GetBillingDashboardUrl($organizationId: String!) {
		billingCustomerDashboardUrlGet(organizationId: $organizationId) {
			url
		}
	}
`;

export const useBillingDashboardUrl = () => {
	const organizationId = useOrgId();

	const [onClick, { loading }] = useLazyQuery<
		Pick<Query, 'billingCustomerDashboardUrlGet'>,
		QueryBillingCustomerDashboardUrlGetArgs
	>(GET_BILLING_DASHBOARD_URL, {
		variables: { organizationId },
		onCompleted: (data) => window.open(data.billingCustomerDashboardUrlGet.url, '_blank'),
	});

	return { onClick, loading };
};
