import { DeleteOutlined, EditOutlined, MoreHoriz } from '@mui/icons-material';
import {
	Box,
	IconButton,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { LabelType } from 'middleware-types';
import { useState } from 'react';
import { useIsMobile } from 'utils/useScreenSize';
import { useCommunicationsContext } from '../communications-provider';
import { LabelWithColor } from '../helpers/label-colors';
import { CreateUpdateLabelModal } from '../modals/create-update-label-modal';
import { DeleteLabelModal } from '../modals/delete-label-modal';
import { LabelIcon } from '../shared/label-icon';

interface LabelListItemProps {
	label: LabelWithColor;
	indent?: number;
	onSelect?: () => void;
}

export const LabelListItem = ({ label, indent = 0, onSelect }: LabelListItemProps) => {
	const isMobile = useIsMobile();

	const [hovered, setHovered] = useState(false);
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const { selectedLabelId, setSelectedLabelId, readOnly } = useCommunicationsContext();

	const selected = selectedLabelId === label.id;
	const showMenu =
		(hovered || Boolean(menuAnchor) || isMobile) &&
		label.type === LabelType.Custom &&
		!readOnly;

	const onClick = () => {
		setSelectedLabelId(label.id);
		onSelect?.();
	};

	return (
		<>
			<ListItem
				disableGutters
				disablePadding
				onMouseEnter={() => setHovered(true)}
				onMouseLeave={() => setHovered(false)}
				secondaryAction={
					showMenu ? (
						<Box pr={0.5}>
							<IconButton
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									setMenuAnchor(e.currentTarget);
								}}>
								<MoreHoriz />
							</IconButton>
						</Box>
					) : undefined
				}>
				<ListItemButton
					disableGutters
					onClick={onClick}
					selected={selected}
					sx={{
						'pl': 1.5 + indent * 3,
						'pr': 1.5,
						'borderRadius': 1,
						'&.Mui-selected': { border: 'none' },
					}}>
					<ListItemIcon sx={{ minWidth: 35 }}>
						<LabelIcon label={label} selected={selected} />
					</ListItemIcon>
					<ListItemText
						primary={label.name}
						primaryTypographyProps={{
							noWrap: true,
							fontWeight: selected ? 500 : undefined,
						}}
					/>
				</ListItemButton>
			</ListItem>
			<Menu
				open={Boolean(menuAnchor)}
				anchorEl={menuAnchor}
				onClose={() => setMenuAnchor(null)}
				onClick={() => setMenuAnchor(null)}>
				<MenuItem onClick={() => setEditModalOpen(true)}>
					<ListItemIcon>
						<EditOutlined />
					</ListItemIcon>
					<ListItemText primary="Edit Label" />
				</MenuItem>
				<MenuItem onClick={() => setDeleteModalOpen(true)}>
					<ListItemIcon>
						<DeleteOutlined />
					</ListItemIcon>
					<ListItemText primary="Delete Label" />
				</MenuItem>
			</Menu>
			{/** edit modal */}
			<ModalOrDrawer open={editModalOpen}>
				<CreateUpdateLabelModal label={label} onClose={() => setEditModalOpen(false)} />
			</ModalOrDrawer>
			{/** delete modal */}
			<ModalOrDrawer open={deleteModalOpen}>
				<DeleteLabelModal label={label} onClose={() => setDeleteModalOpen(false)} />
			</ModalOrDrawer>
		</>
	);
};
