import { gql, useQuery } from '@apollo/client';
import { Query } from 'middleware-types';

const GET_STRIPE_URLS = gql`
	query GetStripeUrls {
		billingStripeUrlsGet {
			customerDashboardUrl
			invoiceDashboardUrl
			productDashboardUrl
		}
	}
`;

export const useStripeUrls = () => {
	const { data, loading } = useQuery<Pick<Query, 'billingStripeUrlsGet'>>(GET_STRIPE_URLS);
	const urls = data?.billingStripeUrlsGet;
	return { urls, loading };
};
