import { gql, useMutation } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import { useToast } from 'components/ui/toast';
import {
	AdHocBillingItemUpdate,
	Mutation,
	MutationBillingAdHocBillingItemUpdateArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { BILLING_ITEM_FIELDS } from '../utils/fragments';

const UPDATE_AD_HOC_ITEM = gql`
	${BILLING_ITEM_FIELDS}
	mutation UpdateAdHocItem(
		$organizationId: String!
		$billingItemId: String!
		$update: AdHocBillingItemUpdate!
	) {
		billingAdHocBillingItemUpdate(
			organizationId: $organizationId
			billingItemId: $billingItemId
			update: $update
		) {
			...BillingItemFields
		}
	}
`;

export const useUpdateAdHocItem = () => {
	const toast = useToast();
	const organizationId = useOrgId();

	const [_updateBillingItem] = useMutation<
		Pick<Mutation, 'billingAdHocBillingItemUpdate'>,
		MutationBillingAdHocBillingItemUpdateArgs
	>(UPDATE_AD_HOC_ITEM, { onError: (e) => console.log(JSON.stringify(e)) });

	const updateBillingItem = async (billingItemId: string, update: AdHocBillingItemUpdate) => {
		return await _updateBillingItem({
			variables: { organizationId, billingItemId, update },
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Ad-hoc line item updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return updateBillingItem;
};
