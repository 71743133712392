import { gql, useLazyQuery } from '@apollo/client';
import { useToast } from 'components/ui/toast';
import { PricingModelValidationResults, Query } from 'middleware-types';
import { handleNoResponse } from 'utils/errors';

const VALIDATE_BILLING = gql`
	query ValidateBilling {
		billingPricingModelValidationResultsGet {
			errors
		}
	}
`;

export const useBillingValidate = (onCompleted: (data: PricingModelValidationResults) => void) => {
	const toast = useToast();
	const [validate, { loading }] = useLazyQuery<
		Pick<Query, 'billingPricingModelValidationResultsGet'>
	>(VALIDATE_BILLING, {
		fetchPolicy: 'no-cache',
		onError: () => handleNoResponse({ toast }),
		onCompleted: (data) => onCompleted(data.billingPricingModelValidationResultsGet),
	});

	return { validate, loading };
};
