import { AddOutlined } from '@mui/icons-material';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	Pagination,
	Skeleton,
	Stack,
} from '@mui/material';
import { LocationAddressForm } from 'components/pages/user/account/locations/locations-form';
import { LocationInformationItem } from 'components/pages/user/account/locations/locations-item';
import { Alert } from 'components/ui/alert';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import React, { useEffect, useState } from 'react';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import {
	useAddOrUpdateOrgLocationAddressMutation,
	useDeleteOrgLocationAddressMutation,
	useOrgLocationsQuery,
} from './hooks';

/**
 * Returns the Organization Location Information Section.
 * @param param0
 * @returns
 */
export const OrgLocationInformation = ({ orgId }: { orgId: string }): React.JSX.Element => {
	const [page, setPage] = useState(1);
	const pageSize = 6;
	const { locations, totalCount, loading, error } = useOrgLocationsQuery(orgId, pageSize, page);
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();

	const { deleteLocation } = useDeleteOrgLocationAddressMutation(orgId);
	const { addOrUpdate } = useAddOrUpdateOrgLocationAddressMutation(orgId);

	const [addModalOpen, setAddModalOpen] = useState(false);

	const canAdd =
		hasAssociateUserPermission(Permission.Org_Account_U) ||
		hasSiteUserPermission(Permission.Site_OrgAcct_U);

	const handlePageChange = (_: unknown, p: number): void => {
		setPage(p);
	};

	useEffect(() => {
		if (page > 1 && !loading && locations.length === 0) {
			return setPage((prev) => prev - 1);
		}
	}, [locations, page, loading]);

	return (
		<>
			<Card>
				<CardHeader
					title="Location Information"
					action={
						canAdd && (
							<IconButton onClick={() => setAddModalOpen(true)} edge="end">
								<AddOutlined />
							</IconButton>
						)
					}
				/>
				<Divider />
				<CardContent>
					{error && <Alert error={error} />}
					{loading ? (
						<LocationInformationSkeleton />
					) : (
						locations && (
							<>
								<Box
									display="grid"
									pt={1}
									gap={2}
									gridTemplateColumns={{
										xs: 'repeat(auto-fill)',
										sm: 'repeat(auto-fill, 15rem)',
									}}>
									{locations.map((locationAddress) => (
										<LocationInformationItem
											key={locationAddress.id}
											disabled={canAdd}
											locationAddress={locationAddress}
											labels={{
												Alternate: 'Additional',
											}}
											deleteLocation={deleteLocation}
											addOrUpdate={addOrUpdate}
										/>
									))}
								</Box>
								<Stack marginTop={2} alignItems="center">
									<Pagination
										page={page}
										count={Math.ceil(totalCount / pageSize)}
										onChange={handlePageChange}
									/>
								</Stack>
							</>
						)
					)}
				</CardContent>
			</Card>
			{/** add modal */}
			<ModalOrDrawer open={addModalOpen} dialogProps={{ maxWidth: 'lg' }}>
				<LocationAddressForm
					addOrUpdate={addOrUpdate}
					onClose={() => setAddModalOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};

/**
 * The location information skeleton to display on load.
 * @returns
 */
const LocationInformationSkeleton = () => (
	<Box display="flex" flexWrap="wrap">
		{[...Array(3)].map((e, i) => (
			<Box key={i} mr={1} mb={1}>
				<Skeleton height="14rem" width="14rem" variant="rectangular" />
			</Box>
		))}
	</Box>
);
