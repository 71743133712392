import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { LabelType } from 'middleware-types';
import { useCommunicationsContext } from '../communications-provider';
import { useApplyLabel } from '../hooks/conversation-labels-hooks';

interface TrashConversationModalProps extends ModalContentProps {
	conversationName: string;
}

export const TrashConversationModal = ({
	conversationName,
	onClose,
}: TrashConversationModalProps) => {
	const { applyLabel } = useApplyLabel();
	const { labels } = useCommunicationsContext();

	const onSubmit = async () => {
		const trashLabel = labels.find((label) => label.type === LabelType.Trash);
		if (!trashLabel) return false;
		return await applyLabel(trashLabel.id);
	};

	return (
		<ConfirmationModalContent
			variant="destructive"
			primaryText={`Trash "${conversationName}"?`}
			secondaryText="This conversation will be moved to trash for 30 days before being permanently deleted from your inbox."
			confirmText="Trash"
			onSubmit={onSubmit}
			onClose={onClose}
		/>
	);
};
