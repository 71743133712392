import { useEmblem } from 'components/ui/emblem/hooks';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { ConversationParticipant } from 'middleware-types';
import { useCommunicationsContext } from '../communications-provider';
import { convertParticipantType } from '../helpers/utils';
import { useRemoveParticipant } from '../hooks/participants-hooks';

interface RemoveParticipantModalProps extends ModalContentProps {
	participant: ConversationParticipant;
}

export const RemoveParticipantModal = ({ participant, onClose }: RemoveParticipantModalProps) => {
	const { entityId } = useCommunicationsContext();
	const { removeParticipant } = useRemoveParticipant();

	const { emblem } = useEmblem(
		participant.entityId,
		convertParticipantType(participant.entityType)
	);

	const isSelf = entityId === participant.entityId;

	const onSubmit = async () => {
		return await removeParticipant({
			entityType: participant.entityType,
			entityId: participant.entityId,
		});
	};

	return (
		<ConfirmationModalContent
			variant="destructive"
			primaryText={isSelf ? 'Leave Conversation?' : `Remove ${emblem?.displayName}`}
			secondaryText={`${
				isSelf ? 'You' : emblem?.displayName
			} will lose access to this conversation and its history.`}
			confirmText={isSelf ? 'Leave' : 'Remove'}
			onSubmit={onSubmit}
			onClose={onClose}
		/>
	);
};
