import { ChatHistory } from 'components/pages/evoxchat/chatHistory';
import { useGetConversation } from './hooks';
import { Avatar, Box, Card, Link, Stack, Typography } from '@mui/material';
import {
	ErrorOutlined,
	ForumOutlined,
	KeyboardArrowDownOutlined,
	KeyboardArrowUpOutlined,
	Launch,
} from '@mui/icons-material';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalContent,
	useModal,
} from 'components/ui/modal';
import { useAccount } from 'utils/useAccount';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { useState } from 'react';

interface EvoxConversationDisplayProps {
	conversationId: string | undefined;
	selectedMessageId?: string;
}

export const EvoxConversationDisplay = ({
	conversationId,
	selectedMessageId,
}: EvoxConversationDisplayProps) => {
	const { messages, userId, openAiThreadId, cumulativeTokens, source, type, metadata, loading } =
		useGetConversation(conversationId);
	const { account } = useAccount(userId);
	const userDisplayName = `${account?.name?.firstName} ${account?.name?.lastName}`;
	const userHandle = account?.handle ?? '';
	const { showModal } = useModal();
	const [showDetails, setShowDetails] = useState(false);

	const confirmLeaveWebsite = (url: string) => {
		showModal({
			title: 'Are you sure?',
			content: (
				<ModalContent>
					<ConfirmModalContent
						visual={
							<EmptyStateAvatar
								avatarProps={{ bgcolor: 'error.50' }}
								iconProps={{ color: 'error.500' }}
								icon={<ErrorOutlined />}
							/>
						}
						subheadline={`You are now being redirected to openai.com`}
						informativeContent={`Only members of the Evolution Global team will have access to the content on openai.com, and you should NOT modify any content or settings there, at risk of corrupting user conversations.`}
					/>
				</ModalContent>
			),
			actions: (
				<ModalActions justifyContent="center">
					<ModalActionButton size="large" variant="outlined">
						Cancel
					</ModalActionButton>
					<ModalActionButton
						size="large"
						variant="contained"
						color="primary"
						onClick={() => window.open(url, '_blank', 'noreferrer')}>
						Continue
					</ModalActionButton>
				</ModalActions>
			),
		});
	};

	if (conversationId === undefined) {
		return (
			<Card variant="outlined" sx={{ width: 500, height: '100%', overflow: 'auto' }}>
				<Stack spacing={2} p={2} height="100%" justifyContent="center" alignItems="center">
					<Avatar sx={{ height: 60, width: 60 }}>
						<ForumOutlined fontSize="large" />
					</Avatar>
					<Typography variant="h4" textAlign="center">
						Select an item on the left to view the full EVO-X conversation.
					</Typography>
				</Stack>
			</Card>
		);
	}

	return (
		<Stack width={500} spacing={0.5} alignItems="stretch">
			<Stack direction={'column'}>
				<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
					<Typography>
						User:{' '}
						{account && (
							<Link href={'/' + userHandle + '/Account'}>{userDisplayName}</Link>
						)}
					</Typography>
					<Typography>Cumulative Tokens: {cumulativeTokens}</Typography>
				</Stack>
				<Stack direction={'row'} justifyContent="flex-end">
					<Link
						onClick={() => setShowDetails(!showDetails)}
						sx={{ display: 'flex', alignItems: 'center' }}>
						{showDetails ? (
							<KeyboardArrowUpOutlined color="primary" />
						) : (
							<KeyboardArrowDownOutlined color="primary" />
						)}
						{showDetails ? 'Hide Conversation Details' : 'Show Conversation Details'}
					</Link>
				</Stack>
				{showDetails && (
					<Stack direction={'column'}>
						<Typography>
							<span className="font-bold">
								{source} - {type}
							</span>
						</Typography>
						<Typography>
							{openAiThreadId && openAiThreadId !== '' && (
								<Link
									onClick={() => {
										confirmLeaveWebsite(
											`https://platform.openai.com/threads/${openAiThreadId}`
										);
									}}>
									OpenAI Thread <Launch color="inherit" fontSize="inherit" />
								</Link>
							)}
						</Typography>
						{metadata && metadata !== '' && (
							<Typography>
								Metadata:{' '}
								<pre
									style={{
										whiteSpace: 'pre-wrap',
										wordWrap: 'normal',
										margin: 0,
									}}>
									{metadata}
								</pre>
							</Typography>
						)}
					</Stack>
				)}
			</Stack>
			<Card variant="outlined" sx={{ flex: 1 }}>
				<Box id="evox-container" position="relative" height="100%">
					<Box height="100%" overflow="auto">
						<ChatHistory
							messages={messages}
							loading={loading}
							userId={userId}
							selectedMessageId={selectedMessageId}
							readOnly
						/>
					</Box>
				</Box>
			</Card>
		</Stack>
	);
};
