import { Dialog, DialogProps, Drawer, DrawerProps } from '@mui/material';
import { ReactNode } from 'react';
import { useIsMobile } from 'utils/useScreenSize';

interface ModalOrDrawerProps {
	open: boolean;
	dialogProps?: Omit<DialogProps, 'open' | 'children'>;
	drawerProps?: Omit<DrawerProps, 'open' | 'children'>;
	children: ReactNode;
}

export const ModalOrDrawer = ({ open, dialogProps, drawerProps, children }: ModalOrDrawerProps) => {
	const isMobile = useIsMobile();
	if (isMobile) {
		return (
			<Drawer open={open} anchor="bottom" {...drawerProps}>
				{children}
			</Drawer>
		);
	} else {
		return (
			<Dialog open={open} fullWidth maxWidth="sm" {...dialogProps}>
				{children}
			</Dialog>
		);
	}
};
