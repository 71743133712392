import { Close, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';

type DebouncedSearchFieldProps = {
	setExternalValue: React.Dispatch<React.SetStateAction<string>>;
} & TextFieldProps;

const DEBOUNCE_TIMER_IN_MS = 500;

export const DebouncedSearchField = ({
	setExternalValue,
	InputProps,
	...props
}: DebouncedSearchFieldProps) => {
	const [value, _setValue] = useState('');

	const debouncedSetSearchValue = useCallback(
		debounce((value: string) => {
			if (!value) return;
			setExternalValue(value);
		}, DEBOUNCE_TIMER_IN_MS),
		[]
	);

	const setValue = (value: string) => {
		_setValue(value);
		debouncedSetSearchValue(value);
		if (!value) setExternalValue(value);
	};

	return (
		<TextField
			value={value}
			onChange={(e) => setValue(e.target.value)}
			placeholder="Search"
			{...props}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<Search />
					</InputAdornment>
				),
				endAdornment: value ? (
					<InputAdornment position="end">
						<IconButton onClick={() => setValue('')}>
							<Close />
						</IconButton>
					</InputAdornment>
				) : undefined,
				...InputProps,
			}}
		/>
	);
};
