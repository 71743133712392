import { Button, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { useUser } from 'components/pages/auth/auth';
import Authenticator from 'components/pages/auth/mfa-config/authenticator';
import Sms from 'components/pages/auth/mfa-config/sms';
import { PageTitle } from 'components/ui/page';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useWindowDimensions } from 'utils/useWindowDimensions';

export enum MfaConfigSubPage {
	MethodSelect = 'MethodSelect',
	Sms = 'Sms',
	Authenticator = 'Authenticator',
}

const MfaConfigPage = () => {
	const user = useUser();
	const largeScreen = useWindowDimensions();

	const [subPage, setSubPage] = useState(MfaConfigSubPage.MethodSelect);
	const navigate = useNavigate();

	if (subPage === MfaConfigSubPage.Authenticator)
		return <Authenticator onBackClick={() => setSubPage(MfaConfigSubPage.MethodSelect)} />;
	if (subPage === MfaConfigSubPage.Sms)
		return <Sms onBackClick={() => setSubPage(MfaConfigSubPage.MethodSelect)} />;

	if (!user) return <Navigate to="/auth/login" replace />;

	return (
		<>
			<PageTitle title="MFA Configuration" />
			<div className="m-auto flex w-96 flex-col text-center">
				<img
					className={clsx(' mx-auto mb-10 w-32', {
						hidden: largeScreen,
					})}
					src="/img/evolve-logo.svg"
					alt="FTEvolve"
				/>
				<Typography mb={2} variant="h2" className="text-neutral-900">
					Choose a multi-factor authentication (MFA) method
				</Typography>
				<Typography mb={2} className="text-neutral-900">
					You must configure MFA before you can sign in. Choose how you want to
					authenticate.
				</Typography>
				<div className="mt-4 flex">
					<Button
						onClick={() => setSubPage(MfaConfigSubPage.Authenticator)}
						className="flex gap-4 p-4">
						<img src="/img/app-mfa.png" alt="Mfa App" />
						<span className="flex flex-col font-normal">
							<Typography mb={2} variant="h4" className="text-neutral-900">
								Authenticator app
							</Typography>
							<Typography mb={2} variant="h5" className="text-neutral-600">
								Authenticate using a code generated by an app installed on your
								mobile device or computer.
							</Typography>
						</span>
					</Button>
				</div>
				<div className="mt-4 flex gap-4">
					<Button
						onClick={() => setSubPage(MfaConfigSubPage.Sms)}
						className="flex gap-4 p-4">
						<img src="/img/sms-mfa.png" alt="Mfa Sms" />
						<span className="flex flex-col font-normal">
							<Typography mb={2} variant="h4" className="text-neutral-900 ">
								SMS
							</Typography>
							<Typography mb={2} variant="h5" className="text-neutral-600">
								Authenticate using a code sent via text message to your phone.
							</Typography>
						</span>
					</Button>
				</div>
				<Button
					onClick={async () => {
						await Auth.signOut();
						navigate('/auth/login', { replace: true });
					}}
					className="mt-8"
					variant="outlined">
					Sign Out
				</Button>
			</div>
		</>
	);
};

export default MfaConfigPage;
