import { ErrorOutlined, PowerSettingsNewOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, ButtonProps, Stack, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { EmptyStateAvatar } from '../empty-state-avatar';
import { ModalButtonsContainer } from './modal-buttons-container';
import { ModalContent } from './modal-content';
import { ModalHeader } from './modal-header';
import { ModalContentProps } from './modal-types';

interface ConfirmationModalContentProps extends ModalContentProps {
	variant?: 'destructive' | 'activate';
	title?: string;
	graphic?: ReactNode;
	primaryText?: string;
	secondaryText?: string;
	confirmText?: string;
	cancelText?: string;
	onSubmit: () => Promise<boolean>;
}

export const ConfirmationModalContent = ({
	variant,
	title,
	graphic,
	primaryText,
	secondaryText,
	confirmText,
	cancelText,
	onSubmit,
	onClose,
}: ConfirmationModalContentProps) => {
	const modalTitle = title ?? 'Are you sure?';
	const modalGraphic =
		graphic ??
		(variant === 'destructive' ? (
			<EmptyStateAvatar
				avatarProps={{ bgcolor: 'error.50' }}
				iconProps={{ color: 'error.500' }}
				icon={<ErrorOutlined />}
			/>
		) : variant === 'activate' ? (
			<EmptyStateAvatar
				avatarProps={{ bgcolor: 'primary.50' }}
				iconProps={{ color: 'primary.500' }}
				icon={<PowerSettingsNewOutlined />}
			/>
		) : (
			<></>
		));
	const modalConfirmText = confirmText ?? (variant === 'destructive' ? 'Delete' : 'Confirm');
	const modalCancelText = cancelText ?? 'Cancel';
	const buttonColor: ButtonProps['color'] = variant === 'destructive' ? 'error' : 'primary';

	const [loading, setLoading] = useState(false);
	const submitModal = () => {
		setLoading(true);
		onSubmit().then((success) => {
			setLoading(false);
			if (success) onClose();
		});
	};

	return (
		<>
			<ModalHeader title={modalTitle} onClose={onClose} />
			<ModalContent alignItems="center" px={6} py={3} spacing={2}>
				{modalGraphic}
				<Stack alignItems="center" textAlign="center" spacing={0.5}>
					<Typography variant="h1" sx={{ wordBreak: 'break-word' }}>
						{primaryText}
					</Typography>
					<Typography variant="body1">{secondaryText}</Typography>
				</Stack>
			</ModalContent>
			<ModalButtonsContainer>
				<Button variant="outlined" onClick={onClose}>
					{modalCancelText}
				</Button>
				<LoadingButton
					variant="contained"
					color={buttonColor}
					loading={loading}
					onClick={submitModal}>
					{modalConfirmText}
				</LoadingButton>
			</ModalButtonsContainer>
		</>
	);
};
