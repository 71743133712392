import {
	AccountCircle,
	LockOutlined,
	MoreHorizRounded,
	PowerSettingsNewOutlined,
	PublicOutlined,
} from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { DeactivateIcon } from 'components/ui/icons';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { ConfirmChangeOrgVisibilityModal } from 'pages/orgs/[handle]/account/hierarchy/confirmChangeOrgVisibilityModal';
import { Status } from 'pages/site/system-search/system-search';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';
import { useUpdateOrganizationActivationMutation } from '../org/account/basic-info-hooks';
import { ActivationOperation } from '../user/account/personal/personal-info-hooks';

/**
 * Props for the context menu.
 *
 * @interface OrgContextMenuProps
 */
interface OrgContextMenuProps {
	id: string;
	refetch: () => void;
	status: Status;
	handle: string | undefined | null;
	displayName: string | undefined;
	visibility: Visibility;
}

// This doesn't need as many keys
// but removing some causes TypeScript issues
/**
 * The org status.
 *
 * @enum {number}
 */

/**
 * The Row Action.
 *
 * @enum {number}
 */
enum RowAction {
	ActivateOrganization,
	DeactivateOrganization,
	MakePrivate,
	MakePublic,
}

export enum Visibility {
	Public = 'Public',
	Private = 'Private',
}

/**
 * Renders the context menu for site user rows.
 * @param props
 * @returns
 */
export const OrgContextMenu = (props: OrgContextMenuProps): React.JSX.Element => {
	const { hasPermission } = useSiteUser();
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
		setAnchorEl(event.currentTarget);

	const [confirmMakeOrgPublicModalOpen, setConfirmSetOrgPublicModalOpen] = useState(false);
	const [confirmMakeOrgPrivateModalOpen, setConfirmSetOrgPrivateModalOpen] = useState(false);
	const [activateModalOpen, setActivateModalOpen] = useState(false);
	const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);

	const { updateOrganizationActivation } = useUpdateOrganizationActivationMutation(props.id!);

	const onAction = (action: RowAction): void => {
		switch (action) {
			case RowAction.ActivateOrganization:
				setActivateModalOpen(true);
				break;
			case RowAction.DeactivateOrganization:
				setDeactivateModalOpen(true);
				break;
			case RowAction.MakePublic:
				setConfirmSetOrgPublicModalOpen(true);
				break;
			case RowAction.MakePrivate:
				setConfirmSetOrgPrivateModalOpen(true);
				break;
		}
	};

	/**
	 * The actions possible for a context menu.
	 *  @type {*} */
	const tableActions = [
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgProfile_R],
			link: `../../orgs/${props.handle}`,
			text: 'View Profile',
			icon: <AccountCircle />,
			visibility: [Visibility.Public, Visibility.Private],
		},
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgAcct_R],
			link: `../../orgs/${props.handle}/account`,
			text: 'View Account',
			icon: <AccountCircle />,
			visibility: [Visibility.Public, Visibility.Private],
		},
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.DeactivateOrganization,
			text: 'Deactivate Organization',
			icon: <DeactivateIcon />,
			visibility: [Visibility.Public, Visibility.Private],
		},
		{
			status: ['Inactive'],
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.ActivateOrganization,
			text: 'Activate Organization',
			icon: <PowerSettingsNewOutlined />,
			visibility: [Visibility.Public, Visibility.Private],
		},
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.MakePrivate,
			text: 'Make Private',
			icon: <LockOutlined />,
			visibility: [Visibility.Public],
		},
		{
			status: ['Active'],
			permissions: [Permission.Site_OrgAcct_U],
			action: RowAction.MakePublic,
			text: 'Make Public',
			icon: <PublicOutlined />,
			visibility: [Visibility.Private],
		},
	];

	const handleRowAction = (action: RowAction): void => {
		setAnchorEl(null);
		if (onAction) {
			onAction(action);
		}
	};

	let menu = tableActions
		.filter(
			(ta) =>
				hasPermission(ta.permissions ?? []) &&
				props.status &&
				ta.status.includes(props.status) &&
				props.visibility &&
				ta.visibility.includes(props.visibility)
		)
		.map((ta, k) => {
			if (ta.link) {
				return (
					<MenuItem key={k} component={Link} to={ta.link}>
						<ListItemIcon>{ta.icon}</ListItemIcon>
						<ListItemText>{ta.text}</ListItemText>
					</MenuItem>
				);
			}

			return (
				<MenuItem
					key={k}
					onClick={
						ta.action !== undefined ? (): void => handleRowAction(ta.action) : undefined
					}>
					<ListItemIcon>{ta.icon}</ListItemIcon>
					<ListItemText>{ta.text}</ListItemText>
				</MenuItem>
			);
		});

	return (
		<>
			{menu.length > 0 && (
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					onClick={handleClick}>
					<MoreHorizRounded />
				</IconButton>
			)}
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={(): void => setAnchorEl(null)}>
				{menu}
			</Menu>
			{/** make public */}
			<ModalOrDrawer open={confirmMakeOrgPublicModalOpen}>
				<ConfirmChangeOrgVisibilityModal
					organizationId={props.id}
					onClose={() => setConfirmSetOrgPublicModalOpen(false)}
					displayName={props.displayName}
					visibilityToSet={Visibility.Public}
				/>
			</ModalOrDrawer>
			{/** make private */}
			<ModalOrDrawer open={confirmMakeOrgPrivateModalOpen}>
				<ConfirmChangeOrgVisibilityModal
					organizationId={props.id}
					onClose={() => setConfirmSetOrgPrivateModalOpen(false)}
					displayName={props.displayName}
					visibilityToSet={Visibility.Private}
				/>
			</ModalOrDrawer>
			{/** activate */}
			<ModalOrDrawer open={activateModalOpen}>
				<ConfirmationModalContent
					variant="activate"
					title="Activate Organization"
					primaryText="Are you sure?"
					secondaryText={`Do you really want to activate
					${props.displayName}? You can deactivate this organization anytime.`}
					confirmText="Activate Organization"
					onSubmit={async () =>
						await updateOrganizationActivation(ActivationOperation.Activate)
					}
					onClose={() => setActivateModalOpen(false)}
				/>
			</ModalOrDrawer>
			{/** deactivate */}
			<ModalOrDrawer open={deactivateModalOpen}>
				<ConfirmationModalContent
					variant="destructive"
					title="Deactivate Organization"
					primaryText="Are you sure?"
					secondaryText={`Do you really want to deactivate
					${props.displayName}? You can activate this organization anytime.`}
					confirmText="Deactivate Organization"
					onSubmit={async () =>
						await updateOrganizationActivation(ActivationOperation.Deactivate)
					}
					onClose={() => setDeactivateModalOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};
