import { Add, ReceiptLongOutlined } from '@mui/icons-material';
import {
	Box,
	Card,
	Dialog,
	Divider,
	IconButton,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';
import { AdHocItemRow } from './ad-hoc-item-row';
import { useAdHocItems } from './hooks/use-ad-hoc-items';
import { useBillingProducts } from './hooks/use-billing-products';
import { AddUpdateLineItemModal } from './modals/add-update-line-item-modal';
import { PreviewInvoiceModal } from './modals/preview-invoice-modal/preview-invoice-modal';

export const AdHocItemsCard = ({ isChildOrg }: { isChildOrg: boolean }) => {
	const { loading: productsLoading } = useBillingProducts();
	const { items, loading: itemsLoading } = useAdHocItems();
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [previewModalOpen, setPreviewModalOpen] = useState(false);

	const { hasPermission } = useSiteUser();
	const canUpdate = hasPermission(Permission.Site_OrgBilling_U);

	return (
		<>
			<Card>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					px={2.5}
					py={1.5}>
					<Typography variant="h2">Ad-Hoc Line Items</Typography>
					<Stack direction="row">
						{!isChildOrg && (
							<Tooltip title="Preview Invoice">
								<IconButton onClick={() => setPreviewModalOpen(true)}>
									<ReceiptLongOutlined />
								</IconButton>
							</Tooltip>
						)}
						{canUpdate && (
							<IconButton onClick={() => setEditModalOpen(true)}>
								<Add />
							</IconButton>
						)}
					</Stack>
				</Stack>
				<Divider />
				<Box p={2.5}>
					{itemsLoading || productsLoading ? (
						<Skeleton variant="rounded" height={60} />
					) : items.length === 0 ? (
						<Box p={2} bgcolor="neutral.50" borderRadius={1}>
							<Typography variant="body1">
								This organization does not have any ad-hoc line items yet.
							</Typography>
						</Box>
					) : (
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Description</TableCell>
										<TableCell align="right">Unit Price</TableCell>
										<TableCell align="right">Quantity</TableCell>
										<TableCell padding="checkbox" />
									</TableRow>
								</TableHead>
								<TableBody>
									{items.map((item) => (
										<AdHocItemRow
											key={item.id}
											item={item}
											canUpdate={canUpdate}
										/>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>
			</Card>
			{/** add/update modal */}
			<Dialog open={editModalOpen} fullWidth maxWidth="sm">
				<AddUpdateLineItemModal onClose={() => setEditModalOpen(false)} />
			</Dialog>
			{/** preview modal */}
			<Dialog
				open={previewModalOpen}
				fullWidth
				maxWidth="lg"
				sx={{ '& .MuiDialog-paper': { height: '100%' } }}>
				<PreviewInvoiceModal onClose={() => setPreviewModalOpen(false)} />
			</Dialog>
		</>
	);
};
