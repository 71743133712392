import { NotificationSummariesPage } from 'components/pages/notification-summaries/notification-summaries-page';
import { useOrgId } from 'components/pages/org/outlet';
import { PageTitle } from 'components/ui/page';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';

export const AssociateNotifications = () => {
	const orgId = useOrgId();
	const { hasPermission: hasAssociatePermission } = useAssociateUser(orgId);
	const { hasPermission: hasSitePermission } = useSiteUser();

	const canCreate =
		hasAssociatePermission(Permission.Org_Notification_C) ||
		hasSitePermission(Permission.Site_OrgNotification_C);

	return (
		<>
			<PageTitle title="Organization Announcements" />
			<NotificationSummariesPage type="organization" canCreate={canCreate} />
		</>
	);
};
