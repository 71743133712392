import {
	DeleteOutlined,
	DownloadOutlined,
	DriveFileMoveOutlined,
	FileCopyOutlined,
	InfoOutlined,
	IntegrationInstructionsOutlined,
	LockOutlined,
	ModeEditOutlined,
	RemoveRedEyeOutlined,
	Restore,
	SvgIconComponent,
} from '@mui/icons-material';
import { FileInstanceInformation } from 'middleware-types';
import { useMemo, useState } from 'react';
import { useDownloadFileInstance } from 'utils/fileUtils';
import { useIsMobile } from 'utils/useScreenSize';
import { useVersionHistoryContext } from '../components/version-history-provider';
import { useDocumentsContext } from '../documents-page';

interface FileMenuItem {
	section: number;
	label: string;
	icon: SvgIconComponent;
	onClick: () => void;
}

export const useFileOptions = (file: FileInstanceInformation) => {
	const isMobile = useIsMobile();
	const downloadFileInstance = useDownloadFileInstance();
	const { permissions } = useDocumentsContext();

	const { isVersionHistory, currentInstanceId } = useVersionHistoryContext();

	const [renameOpen, setRenameOpen] = useState(false);
	const [copyOpen, setCopyOpen] = useState(false);
	const [moveOpen, setMoveOpen] = useState(false);
	const [privacyOpen, setPrivacyOpen] = useState(false);
	const [makeCurrentOpen, setMakeCurrentOpen] = useState(false);
	const [versionHistoryOpen, setVersionHistoryOpen] = useState(false);
	const [detailsOpen, setDetailsOpen] = useState(false);
	const [metadataOpen, setMetadataOpen] = useState(false);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [deleteInstanceOpen, setDeleteInstanceOpen] = useState(false);

	const items = useMemo(() => {
		const items: FileMenuItem[] = [];
		// view
		if (file.hasPermissionToDownload && !isVersionHistory) {
			items.push({
				section: 1,
				label: 'View',
				icon: RemoveRedEyeOutlined,
				onClick: () => downloadFileInstance(file, true),
			});
		}
		// download
		if (file.hasPermissionToDownload) {
			items.push({
				section: 1,
				label: 'Download',
				icon: DownloadOutlined,
				onClick: () => downloadFileInstance(file, false),
			});
		}
		// rename
		if (file.hasPermissionToUpdate && !isVersionHistory) {
			items.push({
				section: 2,
				label: 'Rename',
				icon: ModeEditOutlined,
				onClick: () => setRenameOpen(true),
			});
		}
		// copy
		if (file.hasPermissionToDownload && permissions.update.length > 0) {
			items.push({
				section: 2,
				label: 'Copy',
				icon: FileCopyOutlined,
				onClick: () => setCopyOpen(true),
			});
		}
		// move
		if (file.hasPermissionToUpdate && !isVersionHistory) {
			items.push({
				section: 2,
				label: 'Move',
				icon: DriveFileMoveOutlined,
				onClick: () => setMoveOpen(true),
			});
		}
		// change privacy
		if (file.hasPermissionToUpdate && file.allowedAccessLevelChangesList!.length > 1) {
			items.push({
				section: 2,
				label: 'Change Privacy',
				icon: LockOutlined,
				onClick: () => setPrivacyOpen(true),
			});
		}
		// version history
		if (!isVersionHistory) {
			items.push({
				section: 2,
				label: 'Version History',
				icon: Restore,
				onClick: () => setVersionHistoryOpen(true),
			});
		}
		// make current
		if (file.hasPermissionToUpdate && isVersionHistory && file.id !== currentInstanceId) {
			items.push({
				section: 2,
				label: 'Make Current',
				icon: Restore,
				onClick: () => setMakeCurrentOpen(true),
			});
		}
		// see details
		if (isMobile) {
			items.push({
				section: 2,
				label: 'See Details',
				icon: InfoOutlined,
				onClick: () => setDetailsOpen(true),
			});
		}
		//see metadata
		items.push({
			section: 2,
			label: 'See Metadata',
			icon: IntegrationInstructionsOutlined,
			onClick: () => setMetadataOpen(true),
		});
		// delete
		if (file.hasPermissionToDelete && !isVersionHistory) {
			items.push({
				section: 3,
				label: 'Delete',
				icon: DeleteOutlined,
				onClick: () => setDeleteOpen(true),
			});
		}
		// delete instance
		if (file.hasPermissionToDelete && isVersionHistory) {
			items.push({
				section: 3,
				label: 'Delete',
				icon: DeleteOutlined,
				onClick: () => setDeleteInstanceOpen(true),
			});
		}
		return items;
	}, [file, isVersionHistory, isMobile]);

	return {
		items,
		renameOpen,
		setRenameOpen,
		copyOpen,
		setCopyOpen,
		moveOpen,
		setMoveOpen,
		privacyOpen,
		setPrivacyOpen,
		makeCurrentOpen,
		setMakeCurrentOpen,
		versionHistoryOpen,
		setVersionHistoryOpen,
		detailsOpen,
		setDetailsOpen,
		metadataOpen,
		setMetadataOpen,
		deleteOpen,
		setDeleteOpen,
		deleteInstanceOpen,
		setDeleteInstanceOpen,
	};
};
