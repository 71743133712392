import { Link, Stack, Typography } from '@mui/material';
import { Amplify, Auth } from 'aws-amplify';
import clsx from 'clsx';
import { DelayedLoading } from 'components/ui/delayed-loading';
import { GlobeComponent } from 'pages/auth/globe';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useWindowDimensions } from 'utils/useWindowDimensions';

Amplify.configure({
	Auth: {
		userPoolWebClientId: import.meta.env.VITE_AWS_COGNITO_CLIENTID,
		userPoolId: import.meta.env.VITE_AWS_COGNITO_USERPOOLID,
	},
});

/**
 * Prevents users from accessing the auth pages,
 * that is, pages that should only be accessed by unauthenticated users.
 */
export const AuthOutlet = () => {
	const navigate = useNavigate();
	const largeScreen = useWindowDimensions();
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState<any>(null);

	const location = useLocation();

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const user = await Auth.currentAuthenticatedUser();
				setUser(user);

				if (user?.preferredMFA === 'NOMFA') {
					setLoading(false);
				} else {
					navigate('/', { replace: true });
				}
			} catch (error) {
				setLoading(false);
			}
		};

		fetchUser();
	}, [location.pathname, navigate]);

	if (loading) return <DelayedLoading />;
	return (
		<main className="flex h-full flex-col bg-[url('/img/navy-tinted-background.svg')] bg-cover bg-center">
			{user?.preferredMFA === 'NOMFA' && <Navigate to="/auth/mfa-config" replace />}
			<div
				className={clsx(
					'mx-auto flex w-full flex-grow flex-row items-center justify-center  px-4 md:px-0',
					{
						'overflow-hidden': largeScreen,
						'overflow-auto': !largeScreen,
					}
				)}>
				<div
					className={clsx('flex w-1/2 max-w-[56rem] lg:items-center', {
						hidden: !largeScreen,
					})}>
					<GlobeComponent />
				</div>

				<div className="flex  w-full max-w-[56rem] lg:w-1/2">
					<Outlet context={{ user } satisfies AuthOutletContext} />
				</div>
			</div>
			<footer className="flex h-16 items-center justify-center">
				<Stack
					alignItems="center"
					display="flex"
					flexDirection="column"
					gap={1}
					className={clsx('lg:flex-row')}>
					<Stack display={'flex'} flexDirection={'row'}>
						<Typography
							className="pt-1 text-center align-middle"
							style={{ fontWeight: 400 }}
							variant="h4">
							Powered By &nbsp;
						</Typography>
						<Link href="https://evolution.global" className="hover:opacity-50">
							<img
								src="/img/evolution-global-black.svg"
								alt="Evolution Global"
								className="h-6 w-auto align-middle lg:h-6"
							/>
						</Link>
					</Stack>
					<Stack
						display="flex"
						flexDirection="row"
						gap={0.5}
						alignItems="center"
						className={clsx('lg:pt-2')}>
						<Link
							variant="caption"
							href="https://www.evolution.global/legal/master-subscription-agreement">
							Master Subscription Agreement
						</Link>

						<Typography fontSize=".5rem" style={{ transform: 'scaleX(2.5)' }}>
							|
						</Typography>
						<Link
							variant="caption"
							href="https://www.evolution.global/legal/cookie-policy">
							Cookie Policy
						</Link>
						<Typography fontSize=".5rem" style={{ transform: 'scaleX(2.5)' }}>
							|
						</Typography>
						<Link
							variant="caption"
							href="https://www.evolution.global/legal/privacy-policy">
							Privacy Policy
						</Link>
					</Stack>
				</Stack>
			</footer>
		</main>
	);
};

type AuthOutletContext = { user: any };

export const useUser = () => useOutletContext<AuthOutletContext>().user ?? null;

export type Credentials = {
	email: string;
	password: string;
};
