import { Stack } from '@mui/material';
import { ReactNode } from 'react';

export const ModalButtonsContainer = ({ children }: { children: ReactNode }) => {
	return (
		<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
			{children}
		</Stack>
	);
};
