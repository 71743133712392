import { DebouncedSearchField } from '../debounced-search-field';
import { useBillingJournalsContext } from './billing-journals-provider';

export const BillingJournalsSearch = () => {
	const { setSearchFor } = useBillingJournalsContext();
	return (
		<DebouncedSearchField
			setExternalValue={setSearchFor}
			fullWidth={false}
			sx={{ width: { sm: 300 } }}
		/>
	);
};
