import { Container, Grid, Pagination, Skeleton, Stack, Typography } from '@mui/material';
import { EvoxConversationDisplay } from 'components/pages/site/evox/conversation-display';
import { useEvoxReview } from 'components/pages/site/evox/hooks';
import { EvoxMessageList } from 'components/pages/site/evox/message-list';
import { NavList, NavListItem } from 'components/ui/nav-list';
import { PageContent, PageTitle } from 'components/ui/page';
import {
	ChatMessageRating,
	ConversationTypeFilter,
	SortDirection,
	UserChatMessage,
} from 'middleware-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DEFAULTPAGESIZES } from 'utils/theme';

export enum EvoxReviewPageType {
	GeneralChats = 'general-chats',
	ThumbsUp = 'thumbs-up',
	ThumbsDown = 'thumbs-down',
	MessageDrafts = 'message-drafts',
	ClaimNotesSummaries = 'claim-notes-summaries',
}

export const EvoxReviewPage = () => {
	// const [pageSize, setPageSize] = useState(DEFAULTPAGESIZES[0]);
	const [page, setPage] = useState(1);
	// const [isAsc, setIsAsc] = useState(false);
	const { pageType } = useParams<{ pageType: EvoxReviewPageType }>();

	let chatMessageRating: ChatMessageRating | undefined = undefined;
	switch (pageType) {
		case EvoxReviewPageType.ThumbsUp:
			chatMessageRating = ChatMessageRating.ThumbsUp;
			break;
		case EvoxReviewPageType.ThumbsDown:
			chatMessageRating = ChatMessageRating.ThumbsDown;
			break;
	}

	// For now, only use paging for all messages.
	// Return thumb up'd or thumb down'd messages
	// in one max sized page (1000).
	const pageSize = chatMessageRating ? 1000 : DEFAULTPAGESIZES[0];
	const offset = chatMessageRating ? 0 : (page - 1) * pageSize;
	const sortDirection = SortDirection.Descending;

	let conversationTypeFilter: ConversationTypeFilter[] | undefined = undefined;
	switch (pageType) {
		case EvoxReviewPageType.MessageDrafts:
			conversationTypeFilter = [ConversationTypeFilter.MessagingDraft];
			break;
		case EvoxReviewPageType.ClaimNotesSummaries:
			conversationTypeFilter = [ConversationTypeFilter.ClaimNotesSummary];
			break;
	}

	const { messages, totalCount, loading } = useEvoxReview({
		pageSize,
		offset,
		sortDirection,
		chatMessageRating,
		conversationTypeFilter,
	});

	const [selectedMessage, setSelectedMessage] = useState<UserChatMessage | null>(null);

	useEffect(() => {
		setPage(1);
		setSelectedMessage(null);
	}, [pageType]);

	const handlePageChange = (_: unknown, p: number): void => {
		setPage(p);
	};

	return (
		<>
			<PageTitle title={`EVO-X Messages`} />
			<PageContent>
				<Container maxWidth="xl" sx={{ height: '100%' }}>
					<Grid container height="97%" spacing={1}>
						<Grid item xs={12} md={2} lg={2}>
							<NavList title="EVO-X Review" sticky>
								<NavListItem
									text="General Chats"
									to={`/site/evox/review/${EvoxReviewPageType.GeneralChats}`}
								/>
								<NavListItem
									text="Liked Chat Messages"
									to={`/site/evox/review/${EvoxReviewPageType.ThumbsUp}`}
								/>
								<NavListItem
									text="Disliked Chat Messages"
									to={`/site/evox/review/${EvoxReviewPageType.ThumbsDown}`}
								/>
								<NavListItem
									text="Messaging Drafts"
									to={`/site/evox/review/${EvoxReviewPageType.MessageDrafts}`}
								/>
								<NavListItem
									text="Claim Notes Summaries"
									to={`/site/evox/review/${EvoxReviewPageType.ClaimNotesSummaries}`}
								/>
							</NavList>
						</Grid>
						<Grid item xs={12} md={10} lg={10} height="100%">
							<Stack direction="column" height="100%" overflow="auto">
								{loading ? (
									<MessagesListSkeleton />
								) : (
									<>
										<PageTitle title="EVO-X Messages" />
										<Container maxWidth="xl" sx={{ height: '100%' }}>
											<Stack
												direction="row"
												spacing={4}
												height={'100%'}
												justifyContent="center">
												<Stack spacing={2} width={500}>
													<Typography variant="h2">
														{pageType ===
															EvoxReviewPageType.GeneralChats &&
															'General Chats'}
														{pageType === EvoxReviewPageType.ThumbsUp &&
															'Liked Chat Messages'}
														{pageType ===
															EvoxReviewPageType.ThumbsDown &&
															'Disliked Chat Messages'}
														{pageType ===
															EvoxReviewPageType.MessageDrafts &&
															'Messaging Drafts'}
														{pageType ===
															EvoxReviewPageType.ClaimNotesSummaries &&
															'Claim Notes Summaries'}
													</Typography>
													<EvoxMessageList
														loading={loading}
														messages={messages}
														selectedMessageId={
															selectedMessage?.chatMessageId
														}
														onMessageSelected={(message) =>
															setSelectedMessage(message)
														}
													/>
													{/* Currently these messages are not displayed as a table,
													so we can't use the normal <TablePagination/> component.
													That means functionality is also somewhat limited.
													However, all the logic for sort direction and page
													size alterations is in place, so those features should be
													easy to include in the future with some UI changes. */}
													{!chatMessageRating && (
														<Pagination
															page={page}
															count={Math.ceil(totalCount / pageSize)}
															onChange={handlePageChange}
														/>
													)}
												</Stack>
												<EvoxConversationDisplay
													conversationId={
														selectedMessage?.chatConversationId
													}
													selectedMessageId={
														chatMessageRating
															? selectedMessage?.chatMessageId
															: undefined
													}
												/>
											</Stack>
										</Container>
									</>
								)}
							</Stack>
						</Grid>
					</Grid>
				</Container>
			</PageContent>
		</>
	);
};

export const MessagesListSkeleton = () => (
	<Stack spacing={1}>
		{Array.from({ length: 6 }).map((_, idx) => (
			<Grid key={idx} container alignItems="stretch">
				<Grid item display="flex" justifyContent="center" alignItems="center" xs={2}>
					<Skeleton height="2.5rem" width="2.5rem" variant="circular" />
				</Grid>
				<Grid item xs={10}>
					<Stack>
						<Skeleton height="2.5rem" width="100%" />
						<Skeleton height="1.5rem" width="100%" />
					</Stack>
				</Grid>
			</Grid>
		))}
	</Stack>
);
