import { Close, ErrorOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import { useDeleteAdHocItem } from '../hooks/use-delete-ad-hoc-item';
import { AdHocBillingItem } from 'middleware-types';

interface DeleteLineItemModalProps {
	item: AdHocBillingItem;
	onClose: () => void;
}

export const DeleteLineItemModal = ({ item, onClose }: DeleteLineItemModalProps) => {
	const { deleteItem, loading } = useDeleteAdHocItem();
	const onSubmit = () => {
		deleteItem(item.id).then((success) => {
			if (!success) return;
			onClose();
		});
	};

	return (
		<Stack>
			<Stack
				px={2}
				py={1}
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={1}>
				<Typography variant="h3">Are you sure?</Typography>
				<IconButton onClick={onClose}>
					<Close />
				</IconButton>
			</Stack>
			<Divider />
			<Stack alignItems="center" textAlign="center" px={10} py={3}>
				<EmptyStateAvatar
					avatarProps={{ bgcolor: 'error.50' }}
					iconProps={{ color: 'error.500' }}
					icon={<ErrorOutlined />}
				/>
				<Typography variant="h1" marginTop={2} sx={{ wordBreak: 'break-word' }}>
					Delete &quot;{item.description}?&quot;
				</Typography>
				<Typography variant="body1" sx={{ marginTop: '4px' }}>
					This ad-hoc line item will be deleted. This action cannot be undone.
				</Typography>
			</Stack>
			<Divider />
			<Stack direction="row" justifyContent="flex-end" px={2} py={1.5} spacing={1.5}>
				<Button size="large" variant="outlined" onClick={onClose}>
					Cancel
				</Button>
				<LoadingButton
					size="large"
					variant="contained"
					color="error"
					loading={loading}
					onClick={onSubmit}>
					Delete
				</LoadingButton>
			</Stack>
		</Stack>
	);
};
