import {
	AccountTreeOutlined,
	DeleteOutlined,
	EditOutlined,
	ErrorOutlined,
	MoreHorizOutlined,
} from '@mui/icons-material';
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
import { useOrgId } from 'components/pages/org/outlet';
import { useOrgRoleDeleteMutation, useOrgRolesQuery } from 'components/pages/org/roles';
import { EmptyStateAvatar } from 'components/ui/empty-state-avatar';
import {
	ConfirmModalContent,
	ModalActionButton,
	ModalActions,
	ModalLoadingButton,
	useModal,
} from 'components/ui/modal';
import { PageContent, PageTitle } from 'components/ui/page';
import SortableColumnCell from 'components/ui/sortable-column-cell';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PageError } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { DEFAULTPAGESIZES } from 'utils/theme';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';

type OrgRoleRowProps = {
	id: string;
	orgId: string;
	isAdminRole: boolean;
	name: string;
	category: string;
	associatedContacts: number;
	onDelete: (id: string, name: string) => void;
};

/**
 * OrgRoleRow - A single row on the organization roles table.
 *
 * @param {OrgRoleRowProps} props
 * @returns
 */
const OrgRoleRow = (props: OrgRoleRowProps): React.JSX.Element => {
	const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void =>
		setAnchorEl(event.currentTarget);
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(props.orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const navigate = useNavigate();

	const handleRowAction = (): void => {
		setAnchorEl(null);
		props.onDelete(props.id, props.name);
	};

	return (
		<TableRow>
			<TableCell size="small">
				<AccountTreeOutlined fontSize="small" />
			</TableCell>
			<TableCell>{props.name}</TableCell>
			<TableCell>{props.category}</TableCell>
			<TableCell>{props.associatedContacts}</TableCell>
			<TableCell size="small">
				<IconButton onClick={handleClick}>
					<MoreHorizOutlined />
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={(): void => setAnchorEl(null)}>
					<MenuItem onClick={() => navigate(`${props.id}`, { replace: true })}>
						<ListItemIcon>
							<EditOutlined />
						</ListItemIcon>
						<ListItemText>Manage Role</ListItemText>
					</MenuItem>
					{/* Material menu's have a hacky way of ref-ing the first child, this breaks when using a wrapping HOC. Instead, just use the context */}
					{(hasAssociateUserPermission(Permission.Org_Assoc_Roles_D) ||
						hasSiteUserPermission(Permission.Site_OrgRoles_D)) && (
						<MenuItem
							disabled={props.associatedContacts > 0 || props.isAdminRole}
							onClick={handleRowAction}>
							<ListItemIcon>
								<DeleteOutlined />
							</ListItemIcon>
							<ListItemText>Delete Role</ListItemText>
						</MenuItem>
					)}
				</Menu>
			</TableCell>
		</TableRow>
	);
};

/**
 * OrgRoles contains the entire organization roles page.
 *
 * @returns
 */
export const OrgRolesPage = () => {
	const orgId = useOrgId();

	const [page, setPage] = useState(0);
	const [isAsc, setIsAsc] = useState<boolean>(true);
	const [sortBy, setSortBy] = useState<string>('Name');
	const [pageSize, setPageSize] = useState<number>(DEFAULTPAGESIZES[0]);
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const { showModal } = useModal();

	const canCreate =
		hasAssociateUserPermission(Permission.Org_Assoc_Roles_C) ||
		hasSiteUserPermission(Permission.Site_OrgRoles_C);

	const { totalCount, roles, loading, refetch, error } = useOrgRolesQuery(
		orgId,
		pageSize,
		page,
		sortBy,
		isAsc ? 'Ascending' : 'Descending'
	);

	if (error) throw PageError;

	const { deleteRole, status } = useOrgRoleDeleteMutation(orgId, {
		onCompleted: () => {
			refetch();
		},
	});

	/**
	 * onDelete processes a delete triggered on a row of the table.
	 * This is used to run the mutation and update the query.
	 *
	 * @param {(string | string)} id
	 */
	const onDelete = (id: string, name: string): void => {
		showModal({
			title: 'Delete Role',
			content: (
				<ConfirmModalContent
					visual={
						<EmptyStateAvatar
							avatarProps={{ bgcolor: 'error.50' }}
							iconProps={{ color: 'error.500' }}
							icon={<ErrorOutlined />}
						/>
					}
					subheadline="Are you sure?"
					informativeContent={`Do you really want to delete the ${name} role? This process cannot be undone.`}
				/>
			),
			actions: (
				<ModalActions>
					<ModalActionButton variant="outlined">Cancel</ModalActionButton>
					<ModalLoadingButton
						color="error"
						variant="contained"
						onClick={async () => deleteRole(id)}>
						Delete Role
					</ModalLoadingButton>
				</ModalActions>
			),
		});
	};

	let rows: React.JSX.Element[] = roles.map((row, i) => (
		<OrgRoleRow key={i} orgId={orgId} {...row} onDelete={onDelete} />
	));

	/**
	 * On page changed.
	 *
	 * @param {number} p
	 */
	const handlePageChange = (_: unknown, p: number): void => {
		setPage(p);
	};

	/**
	 * On page size changed.
	 *
	 * @param {React.ChangeEvent<{ value: unknown; }>} e
	 */
	const handlePageSizeChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	): void => {
		const value = parseInt(e.target.value);
		setPageSize(value);
		setPage(0);
		refetch();
	};

	/**
	 * Handle column sorting pressed.
	 *
	 * @param {string} dataKey
	 */
	const handleSortClick = (dataKey: string): void => {
		if (dataKey === sortBy) {
			setIsAsc(!isAsc);
		} else {
			setSortBy(dataKey);
		}
	};

	return (
		<>
			<PageTitle title="Organization Roles" />
			<PageContent>
				<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
					<CardHeader
						title="Organization Roles"
						action={
							canCreate && (
								<Button
									component={Link}
									to={`create`}
									variant="contained"
									color="primary">
									Create Role
								</Button>
							)
						}
					/>
					<CardContent
						sx={{
							flex: '1 1 auto',
							overflow: 'hidden',
						}}>
						<TableContainer sx={{ maxHeight: '100%' }}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell size="small" style={{ width: '4%' }}></TableCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="Name"
											onSort={handleSortClick}
											active={'Name' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Role Name
										</SortableColumnCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="Category.Name"
											onSort={handleSortClick}
											active={'Category.Name' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Category
										</SortableColumnCell>
										<SortableColumnCell
											style={{ width: '20%' }}
											column="associateCount"
											onSort={handleSortClick}
											active={'associateCount' === sortBy}
											direction={isAsc ? 'asc' : 'desc'}>
											Number of Associates
										</SortableColumnCell>
										<TableCell
											size="small"
											style={{ width: '10%' }}></TableCell>
									</TableRow>
								</TableHead>
								{loading || status.loading ? (
									<SkeletonRows rows={pageSize} />
								) : (
									<TableBody>{rows}</TableBody>
								)}
							</Table>
						</TableContainer>
					</CardContent>
					<CardActions disableSpacing sx={{ justifyContent: 'flex-end' }}>
						<TablePagination
							component={Stack}
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							count={totalCount}
							page={page}
							rowsPerPage={pageSize}
							onPageChange={handlePageChange}
							onRowsPerPageChange={handlePageSizeChange}
						/>
					</CardActions>
				</Card>
			</PageContent>
		</>
	);
};

type SkeletonProps = {
	rows: number;
};

// Will generate however many placeholders depending on page size
// Actual number of rows may be fewer
const SkeletonRows = (props: SkeletonProps): React.JSX.Element => (
	<TableBody>
		{Array(props.rows)
			.fill(null)
			.map((_, i) => {
				return (
					<TableRow key={i}>
						<TableCell colSpan={5}>
							<Skeleton animation="wave" height={'2em'} />
						</TableCell>
					</TableRow>
				);
			})}
	</TableBody>
);
