import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	FormControlLabel,
	Grid,
	TextField as MTextField,
	MenuItem,
	Skeleton,
} from '@mui/material';
import { Alert } from 'components/ui/alert';
import { CheckboxField, SelectField, TextField } from 'components/ui/fields';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { useToast } from 'components/ui/toast';
import { Formik } from 'formik';
import { AssociateOrInvitation, AssociateUpdate } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useSession } from 'utils/session';
import { useValidation } from 'utils/useValidation';
import { useAllOrgRolesQuery, useUpdateAssociateMutation } from '../hooks';

/**
 *  Update Associate props.
 */
interface UpdateAssociateProps extends ModalContentProps {
	orgId: string;
	associate: AssociateOrInvitation;
}

/**
 * useUpdateAssociate - Custom hook for getting validation schema, handling submit, and loading.
 *
 * @returns {({
 * 	initialValues: UpdateAssociateProps;
 * 	roles: AllOrgRoles;
 * 	validationSchema: ValidationSchema | null;
 * 	submit: (values: MAssociateInvitationRequest) => Promise<void>;
 * 	loading: boolean;
 * 	error: ApolloError | undefined;
 * })}
 */
const useUpdateAssociate = ({ orgId, associate, onClose }: UpdateAssociateProps) => {
	const validation = useValidation('AssociateUpdate');
	const toast = useToast();
	const { update, error } = useUpdateAssociateMutation(orgId, associate.associateId!);
	const rolesQuery = useAllOrgRolesQuery(orgId);

	const initialValues: AssociateUpdate = {
		emailAddress: associate.emailAddress,
		displayName: associate.displayName,
		organizationRoleId: associate.organizationRoleId,
		displayOnProfile: associate.displayOnProfile ?? false,
	};

	/**
	 * When the form submits
	 */
	const submit = async (values: AssociateUpdate) => {
		return await update(values)
			.then(async (res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push(`Successfully updated associate ${values.displayName}.`, {
					variant: 'success',
				});
				onClose();
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		initialValues,
		roles: rolesQuery.roles,
		submit,
		validationSchema: validation.schema,
		loading: validation.loading || rolesQuery.loading,
		error,
	};
};

/**
 * Associate Invitation Modal
 *
 * @param {UpdateAssociateProps} associate
 * @returns
 */
export const UpdateAssociateForm = (props: UpdateAssociateProps) => {
	const { user } = useSession();
	const { associate, onClose } = props;
	const { initialValues, roles, loading, submit, validationSchema, error } =
		useUpdateAssociate(props);

	if (loading) return <SkeletonForm />;

	return (
		<Formik<AssociateUpdate>
			onSubmit={submit}
			validationSchema={validationSchema}
			initialValues={initialValues}
			enableReinitialize>
			{(formik) => (
				<>
					<ModalHeader
						title={`Update Associate ${associate.displayName}`}
						onClose={onClose}
					/>
					<ModalContent>
						<Box>
							<Grid container spacing={1} columnSpacing={2}>
								<Grid xs={12} sm={6} md={6} item>
									<TextField
										label="Display Name"
										required
										name="displayName"
										fullWidth
									/>
								</Grid>
								<Grid xs={12} sm={6} md={6} item>
									<TextField
										label="Email Address"
										required
										type="email"
										name="emailAddress"
										fullWidth
									/>
								</Grid>
								<Grid xs={12} sm={6} md={6} item>
									<SelectField
										label="Role"
										required
										name="organizationRoleId"
										value={formik.values.organizationRoleId ?? ''}
										disabled={user.userId === props.associate.userId}
										fullWidth>
										{roles.map((src, i) => (
											<MenuItem key={i} value={src.id}>
												{src.name !== '' ? src.name : <em>None</em>}
											</MenuItem>
										))}
									</SelectField>
								</Grid>
								<Grid item xs={12}>
									<FormControlLabel
										control={<CheckboxField name="displayOnProfile" />}
										label="Display on Profile"
									/>
								</Grid>
								{error && (
									<Grid xs={12} md={12} item>
										<Alert error={error} />
									</Grid>
								)}
							</Grid>
						</Box>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
							loading={formik.isSubmitting}
							onClick={formik.submitForm}>
							Update Associate
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};

const SkeletonForm = () => (
	<ModalContent noDividers>
		<Box>
			<Grid container spacing={1} columnSpacing={2}>
				<Grid xs={12} md={6} item>
					<Skeleton animation="wave" width="100%">
						<MTextField fullWidth />
					</Skeleton>
				</Grid>
				<Grid xs={12} sm={6} md={6} item>
					<Skeleton animation="wave" width="100%">
						<MTextField fullWidth />
					</Skeleton>
				</Grid>
				<Grid xs={12} sm={6} md={6} item>
					<Skeleton animation="wave" width="100%">
						<MTextField fullWidth />
					</Skeleton>
				</Grid>
			</Grid>
		</Box>
	</ModalContent>
);
