import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import _ from 'lodash';
import { getJwtToken } from './session';

const cleanTypeName = new ApolloLink((operation, forward) => {
	if (operation.variables) {
		const omitTypename = <T>(key: string, value: T): T | undefined =>
			key === '__typename' ? undefined : value;
		operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
	}
	return forward(operation).map((data) => {
		return data;
	});
});

const cache = new InMemoryCache({
	dataIdFromObject(responseObject) {
		switch (responseObject.__typename) {
			case 'UserProfileEffectivePrivacy':
				return `UserProfileEffectivePrivacy:${responseObject.targetUserId}:${responseObject.aspect}`;
			default:
				return defaultDataIdFromObject(responseObject);
		}
	},
	typePolicies: {
		Query: {
			fields: {
				// This has been added due to an issue on the People Search page that happened when the last Site User Invitation was deleted (1 inv -> 0).
				// Due to the peopleSearch query result not having a unique ID, apollo is not able to merge the existing and incoming cache data
				// and will send a warning to the console indicating the merge issue. Setting merge to false for the UserQuery.peopleSearch field forces
				// the cache to always take the incoming data, thus avoiding the merge between the existing cache data (PeopleSearchResult) and incoming cache data ([]).
				// Attempts were made to set the fetchPolicy of the peopleSearch lazyQuery to 'no-cache' but ran into issues with refetch() appearing to cache
				// anyways regardless of the fetchPolicy and other unrelated issues where loading was never set to true without additionally setting notifyOnNetworkStatusChange: true.
				// Having a unique ID returned in the query result would be the ideal solution, but barring that, always accepting the incoming cache data appears to fix the issue.
				peopleSearch: {
					merge: false,
				},
				// This causes the queries like `query { user { profile(#} {} }}` to try to read from existing profile objects within the cache.
				// See for more details: https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
				profile(existing, { args, toReference }) {
					// Return previously cached values for this key in Query
					if (existing) return existing;
					// Reference previously cached values from other queries that are of the type
					// specified in __typename
					return toReference({
						__typename: 'UserProfile',
						id: args?.id,
					});
				},
				account(existing, { args, toReference }) {
					if (existing) return existing;
					return toReference({
						__typename: 'UserAccount',
						id: args?.userId,
					});
				},
				emblem(existing, { args, toReference }) {
					if (existing) return existing;
					return toReference({
						__typename: 'Emblem',
						id: args?.entityId,
					});
				},
				profilePrivacy: {
					keyArgs: ['userId', 'type'],
					read(existing, { args, toReference, cache }) {
						if (existing) return existing;
						return toReference({
							__ref: cache.identify({
								__typename: 'UserProfileEffectivePrivacy',
								targetUserId: args?.userId,
								aspect: args?.type,
							}),
						});
					},
				},
				usaAdjusterLicensing: {
					keyArgs: ['userId'],
				},
				canAdjusterLicensing: {
					keyArgs: ['userId'],
				},
				affiliatedOrganizations: {
					merge: false,
				},
				notifications: {
					keyArgs: ['targetEntityId', 'types'],
					merge: (existing, incoming, { args }) => {
						if (!existing || args?.offset === 0) return incoming;
						return {
							...existing,
							items: _.uniqBy([...existing.items, ...incoming.items], '__ref'),
						};
					},
				},
				siteUserEmblems: {
					keyArgs: ['searchText'],
					merge: (existing, incoming, { args }) => {
						if (!existing || args?.offset === 0) return incoming;
						return {
							...existing,
							items: _.uniqBy([...existing.items, ...incoming.items], '__ref'),
						};
					},
				},
				conversationSearch: {
					keyArgs: ['entityType', 'entityId', 'labelId', 'searchText'],
					merge: (existing, incoming, { args }) => {
						if (!existing || args?.offset === 0) return incoming;
						return {
							...existing,
							conversationSummaries: _.uniqBy(
								[
									...existing.conversationSummaries,
									...incoming.conversationSummaries,
								],
								'__ref'
							),
						};
					},
				},
				billingOrganizationDraftInvoicingGet: {
					merge: false,
				},
				billingAdHocBillingItemsGet: {
					merge: false,
				},
			},
		},
		UserAccount: {
			fields: {
				name: {
					merge: true,
				},
			},
		},
		UserProfile: {
			fields: {
				name: {
					merge: true,
				},
			},
		},
		UserEmblem: {
			fields: {
				name: {
					merge: true,
				},
			},
		},
		AdjusterLicensingSummary: {
			keyFields: ['userId', 'countryId'],
		},
		UsaAdjusterLicensingOutput: {
			fields: {
				statesLicensing: {
					merge: false,
				},
			},
		},
		UserProfileEffectivePrivacy: {
			keyFields: ['targetUserId', 'aspect'],
		},
		ConversationSummary: {
			keyFields: ['conversationId'],
		},
	},
});

const authMiddleware = setContext(async (_, { headers, ...context }) => {
	// Silently acquires an access token
	const token = await getJwtToken();
	return {
		headers: {
			...headers,
			authorization: `Bearer ${token || null}`,
			assumedIdentity: localStorage.getItem('_assumedIdentity') ?? '',
		},
		...context,
	};
});

export const client = new ApolloClient<any>({
	cache,
	link: ApolloLink.from([
		authMiddleware,
		cleanTypeName,
		new HttpLink({
			uri: import.meta.env.VITE_BFF,
		}),
	]),
	defaultOptions: {
		query: {
			errorPolicy: 'all',
		},
		mutate: {
			errorPolicy: 'all',
		},
	},
});
