import { LoadingButton } from '@mui/lab';
import { Link, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { Credentials } from 'components/pages/auth/auth';
import {
	confirmPasswordValidation,
	emailValidation,
	passwordValidation,
} from 'components/ui/LoginYups';
import { TextField } from 'components/ui/fields';
import { PageTitle } from 'components/ui/page';
import { Form, Formik, FormikHelpers } from 'formik';
import ConfirmEmail, { ConfirmEmailForm } from 'pages/auth/confirm-email';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import * as Yup from 'yup';

/**
 * Page for signing the user up in Cognito. Contains 3 distinct sub-pages:
 * The basic sign up page, the confirm email page, the MFA configuration page.
 */
const SignUpPage = () => {
	// When signUpCredentials is not null, confirm email page will render
	const [signUpCredentials, setSignUpCredentials] = useState<Credentials | null>(null);
	const largeScreen = useWindowDimensions();

	const navigate = useNavigate();

	const handleSubmit = async (vals: SignUpForm, helpers: FormikHelpers<SignUpForm>) => {
		try {
			await Auth.signUp({
				username: vals.email,
				password: vals.password,
			});

			setSignUpCredentials({ email: vals.email, password: vals.password });
		} catch (e: any) {
			console.log(e);
			helpers.setErrors({ confirmPassword: e?.message });
			if (e?.code === 'UsernameExistsException') helpers.setErrors({ email: e?.message });
			if (e?.code === 'InvalidPasswordException') helpers.setErrors({ password: e?.message });
		}
	};

	const handleConfirmEmailSubmit = async (
		code: string,
		helpers: FormikHelpers<ConfirmEmailForm>
	) => {
		if (!signUpCredentials) return;
		try {
			await Auth.confirmSignUp(signUpCredentials.email, code);
			await Auth.signIn(signUpCredentials.email, signUpCredentials.password);
			navigate('/auth/mfa-config', { replace: true });
		} catch (e: any) {
			helpers.setErrors({ code: e?.message });
		}
	};

	if (signUpCredentials)
		return <ConfirmEmail email={signUpCredentials.email} onSubmit={handleConfirmEmailSubmit} />;

	return (
		<>
			<PageTitle title="Sign Up" />
			<Formik<SignUpForm>
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validateOnBlur={false}
				validateOnChange={false}
				enableReinitialize
				validateOnMount={false}
				validationSchema={validationSchema}>
				{({ isSubmitting, errors, setErrors }) => (
					<Form className="m-auto flex w-96 flex-col text-center">
						<img
							className={clsx(' mx-auto mb-10 w-32', {
								hidden: largeScreen,
							})}
							src="/img/evolve-logo.svg"
							alt="FTEvolve"
						/>
						<Typography className="text-neutral-900" mb={2} variant="h2">
							Sign Up for an Account
						</Typography>
						<div className="mb-4 flex flex-col gap-3">
							<TextField
								label="Email address *"
								type="email"
								name="email"
								InputLabelProps={{ shrink: true }}
								error={!!errors.email}
								helperText={errors?.email}
								onChangeCapture={() =>
									setErrors({
										...errors,
										email: undefined,
									})
								}
								FormHelperTextProps={{
									className: clsx(!!errors?.email && 'static', 'text-left mb-1'),
								}}
								sx={{
									fieldset: { borderColor: 'neutral.700' },
								}}
							/>
							<TextField
								label="Password *"
								type="password"
								autoComplete="new-password"
								name="password"
								InputLabelProps={{ shrink: true }}
								error={!!errors.password}
								helperText={errors?.password}
								onChangeCapture={() =>
									setErrors({
										...errors,
										password: undefined,
									})
								}
								FormHelperTextProps={{
									className: clsx(
										!!errors?.password && 'static',
										'text-left mb-1'
									),
								}}
								sx={{
									fieldset: { borderColor: 'neutral.700' },
								}}
							/>
							<TextField
								label="Confirm Password *"
								type="password"
								name="confirmPassword"
								InputLabelProps={{ shrink: true }}
								autoComplete="new-password"
								error={!!errors.confirmPassword}
								helperText={errors?.confirmPassword}
								onChangeCapture={() =>
									setErrors({
										...errors,
										confirmPassword: undefined,
									})
								}
								FormHelperTextProps={{
									className: clsx(
										!!errors?.confirmPassword && 'static',
										'text-left mb-1'
									),
								}}
								sx={{
									fieldset: { borderColor: 'neutral.700' },
								}}
							/>
						</div>
						<LoadingButton
							className="mt-2 text-white "
							variant="contained"
							color="primary"
							fullWidth
							type="submit"
							loading={isSubmitting}>
							Submit
						</LoadingButton>
						<Typography className="mt-5">
							Already have an account?{' '}
							<Link className="text-navy-500" href="/auth/login">
								Log in
							</Link>
						</Typography>
					</Form>
				)}
			</Formik>
		</>
	);
};

export type SignUpForm = Credentials & {
	confirmPassword: string;
};

const initialValues: SignUpForm = {
	email: '',
	password: '',
	confirmPassword: '',
};

const validationSchema = Yup.object({
	email: emailValidation,
	password: passwordValidation,
	confirmPassword: confirmPasswordValidation,
});

export default SignUpPage;
