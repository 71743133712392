import { gql, useQuery } from '@apollo/client';
import { Query } from 'middleware-types';

const GET_BILLING_PRODUCTS = gql`
	query GetBillingProducts {
		billingProductsGet {
			products {
				productId
				name
				description
				prices {
					billingScheme
					description
					effectiveDate
					interval
					price
					priceId
					productId
					type
				}
			}
		}
	}
`;

export const useBillingProducts = () => {
	const { data, loading } = useQuery<Pick<Query, 'billingProductsGet'>>(GET_BILLING_PRODUCTS);
	const products = data?.billingProductsGet.products ?? [];
	return { products, loading };
};
