import { Container, Stack } from '@mui/material';
import { OrgProfileOrgConnectionsBar } from 'components/pages/network/org/OrgConnections/orgConnectionsBarWrappers';
import { useOrgId } from 'components/pages/org/outlet';
import { PageContent, PageTitle } from 'components/ui/page';
import { useState } from 'react';
import { PageError } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import { OrgAboutCard } from './about/about-card';
import { OrgHeaderCard } from './header/header-card';
import { useOrgProfile } from './hooks';
import { OrgLinesOfBusinessCard } from './lines-of-business.tsx/lines-of-business-card';
import { TeamCard } from './team/team-card';

export const OrgProfilePage = () => {
	const orgId = useOrgId();
	const { error } = useOrgProfile(orgId);
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const canEdit =
		hasAssociateUserPermission(Permission.Org_Profile_U) ||
		hasSiteUserPermission(Permission.Site_OrgProfile_U);

	const canReorderTeamMembers =
		hasAssociateUserPermission(Permission.Org_Profile_U) ||
		hasAssociateUserPermission(Permission.Org_Assoc_U) ||
		hasSiteUserPermission(Permission.Site_OrgProfile_U) ||
		hasSiteUserPermission(Permission.Site_OrgAssoc_U);

	const canEditTeamMembers =
		hasAssociateUserPermission(Permission.Org_Assoc_U) ||
		hasSiteUserPermission(Permission.Site_OrgAssoc_U);

	const [selectedTab, setSelectedTab] = useState(0);

	if (error?.message == '401: Unauthorized') throw PageError;

	return (
		<>
			<PageTitle title="Profile" />
			<PageContent mobileNoPadding>
				<Container maxWidth="md" disableGutters>
					<Stack spacing={{ xs: 2, sm: 3 }}>
						<OrgHeaderCard
							orgId={orgId}
							canEdit={canEdit}
							selectedTab={selectedTab}
							setSelectedTab={setSelectedTab}
						/>
						<OrgProfileOrgConnectionsBar orgId={orgId} />
						{selectedTab === 0 && <OrgAboutCard orgId={orgId} canEdit={canEdit} />}
						{selectedTab === 1 && (
							<TeamCard
								orgId={orgId}
								canEditTeamMembers={canEditTeamMembers}
								canReorderTeamMembers={canReorderTeamMembers}
							/>
						)}
						{selectedTab === 2 && (
							<OrgLinesOfBusinessCard orgId={orgId} canEdit={canEdit} />
						)}
					</Stack>
				</Container>
			</PageContent>
		</>
	);
};
