import { gql, useQuery } from '@apollo/client';
import { Loading } from 'components/ui/loading';
import {
	ConversationApiEntityType,
	EmblemEntityType,
	Query,
	QueryConversationsWithParticipantsArgs,
} from 'middleware-types';
import { Navigate, useParams } from 'react-router-dom';
import { useSession } from 'utils/session';

export const CONVERSATIONS_WITH_PARTICIPANTS = gql`
	query ConversationsWithParticipants(
		$entityType: ConversationApiEntityType!
		$entityId: String!
		$entityIds: [String!]!
	) {
		conversationsWithParticipants(
			entityType: $entityType
			entityId: $entityId
			entityIds: $entityIds
		) {
			items {
				conversationId
			}
		}
	}
`;

export const ConversationEmblemRedirect = () => {
	const userId = useSession().user.userId;
	const { entityType, entityId } = useParams<{
		entityType: EmblemEntityType;
		entityId: string;
	}>();

	const { data, loading } = useQuery<
		Pick<Query, 'conversationsWithParticipants'>,
		QueryConversationsWithParticipantsArgs
	>(CONVERSATIONS_WITH_PARTICIPANTS, {
		fetchPolicy: 'no-cache',
		variables: {
			entityType: ConversationApiEntityType.Users,
			entityId: userId,
			entityIds: [entityId!],
		},
		onError: (e) => console.log(JSON.stringify(e)),
	});

	if (loading) return <Loading />;

	const conversations = data?.conversationsWithParticipants.items ?? [];
	if (conversations.length > 0)
		return (
			<Navigate
				to={`/app/conversations?conversationId=${conversations[0].conversationId}`}
				replace
			/>
		);
	return (
		<Navigate to={`/app/conversations?entityType=${entityType}&entityId=${entityId}`} replace />
	);
};
