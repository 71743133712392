import { FilterList } from '@mui/icons-material';
import { Button, Popover } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import { ReactNode, useState } from 'react';

interface FilterButtonProps<T> {
	initialValues: T;
	setFilters: (values: T) => void;
	children: ReactNode;
}

export const FilterButton = <T extends FormikValues>({
	initialValues,
	setFilters,
	children,
}: FilterButtonProps<T>) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const onSubmit = async (values: T) => {
		setFilters(values);
	};

	return (
		<>
			<Button
				variant="outlined"
				startIcon={<FilterList />}
				onClick={(e) => setAnchorEl(e.currentTarget)}>
				Filter
			</Button>
			<Formik<T> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
				{({ submitForm }) => (
					<Popover
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={() => {
							submitForm();
							setAnchorEl(null);
						}}
						anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
						{children}
					</Popover>
				)}
			</Formik>
		</>
	);
};
