import { gql, useMutation } from '@apollo/client';
import { BILLING_ITEM_FIELDS } from '../utils/fragments';
import { useToast } from 'components/ui/toast';
import {
	AdHocBillingItemUpdate,
	Mutation,
	MutationBillingAdHocBillingItemAddArgs,
	Query,
	QueryBillingAdHocBillingItemsGetArgs,
} from 'middleware-types';
import { useOrgId } from 'components/pages/org/outlet';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { GET_AD_HOC_ITEMS } from './use-ad-hoc-items';

const CREATE_AD_HOC_ITEM = gql`
	${BILLING_ITEM_FIELDS}
	mutation CreateAdHocItem($organizationId: String!, $update: AdHocBillingItemUpdate!) {
		billingAdHocBillingItemAdd(organizationId: $organizationId, update: $update) {
			...BillingItemFields
		}
	}
`;

export const useCreateAdHocItem = () => {
	const toast = useToast();
	const organizationId = useOrgId();

	const [_createBillingItem] = useMutation<
		Pick<Mutation, 'billingAdHocBillingItemAdd'>,
		MutationBillingAdHocBillingItemAddArgs
	>(CREATE_AD_HOC_ITEM, { onError: (e) => console.log(JSON.stringify(e)) });

	const createBillingItem = async (update: AdHocBillingItemUpdate) => {
		return await _createBillingItem({
			variables: { organizationId, update },
			update: (cache, { data }) => {
				if (!data) return;
				cache.updateQuery<
					Pick<Query, 'billingAdHocBillingItemsGet'>,
					QueryBillingAdHocBillingItemsGetArgs
				>({ query: GET_AD_HOC_ITEMS, variables: { organizationId } }, (queryData) => {
					if (!queryData) return;
					return {
						billingAdHocBillingItemsGet: {
							adHocBillingItems: [
								...queryData.billingAdHocBillingItemsGet.adHocBillingItems,
								data.billingAdHocBillingItemAdd,
							],
						},
					};
				});
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Ad-hoc line item created successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return createBillingItem;
};
