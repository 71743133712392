import { gql, useQuery } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import { Query, QueryBillingAdHocBillingItemsGetArgs } from 'middleware-types';
import { BILLING_ITEM_FIELDS } from '../utils/fragments';

export const GET_AD_HOC_ITEMS = gql`
	${BILLING_ITEM_FIELDS}
	query GetAdHocItems($organizationId: String!) {
		billingAdHocBillingItemsGet(organizationId: $organizationId) {
			adHocBillingItems {
				...BillingItemFields
			}
		}
	}
`;

export const useAdHocItems = () => {
	const organizationId = useOrgId();
	const { data, loading } = useQuery<
		Pick<Query, 'billingAdHocBillingItemsGet'>,
		QueryBillingAdHocBillingItemsGetArgs
	>(GET_AD_HOC_ITEMS, {
		variables: { organizationId },
		onError: (e) => console.log(JSON.stringify(e)),
	});

	const items = data?.billingAdHocBillingItemsGet.adHocBillingItems ?? [];
	return { items, loading };
};
