import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import { TextField } from 'components/ui/fields';
import { FileField, FileType, FileValue } from 'components/ui/fields/file';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { Formik, FormikProps } from 'formik';
import { QuickLink } from 'middleware-types';
import { useState } from 'react';
import { useValidation } from 'utils/useValidation';
import { QuickLinksMethods } from './quickLinks';

interface QuickLinkFormValues {
	title: string;
	url: string;
	icon?: FileValue;
}

interface AddUpdateQuickLinkModalProps extends ModalContentProps {
	methods: QuickLinksMethods;
	quickLink?: QuickLink;
}

export const AddUpdateQuickLinkModal = ({
	methods,
	quickLink,
	onClose,
}: AddUpdateQuickLinkModalProps) => {
	const { addQuickLink, updateQuickLink } = methods;
	const [isUploading, setIsUploading] = useState(false);

	const fileInstance = quickLink?.iconFile?.currentInstance;
	const initialValues: QuickLinkFormValues = {
		title: quickLink?.title ?? '',
		url: quickLink?.url ?? '',
		icon: {
			uploadToken: null,
			id: fileInstance?.id,
			fileName: fileInstance?.fileName,
			fileSize: fileInstance?.fileSize,
			uploadedUtc: fileInstance?.uploadedUtc,
			virusStatus: fileInstance?.virusStatus,
		},
	};

	const onSubmit = async (values: QuickLinkFormValues) => {
		let success = false;
		const uploadToken = values.icon?.uploadToken ?? undefined;
		if (quickLink === undefined) {
			success = await addQuickLink({
				title: values.title,
				url: values.url,
				iconUploadToken: uploadToken,
			});
		} else {
			success = await updateQuickLink(quickLink.id, {
				title: values.title,
				url: values.url,
				clearIconFlag: uploadToken === undefined && values.icon?.id === undefined,
				iconUploadToken: uploadToken,
			});
		}
		if (success) onClose();
	};

	const validation = useValidation(
		quickLink === undefined ? 'QuickLinkCreate' : 'QuickLinkPatch'
	);

	return (
		<Formik<QuickLinkFormValues>
			onSubmit={onSubmit}
			validationSchema={validation.schema}
			initialValues={initialValues}
			enableReinitialize>
			{({ isSubmitting, isValid, dirty, submitForm }: FormikProps<QuickLinkFormValues>) => (
				<>
					<ModalHeader
						title={quickLink ? 'Edit Link' : 'Add New Link'}
						onClose={onClose}
					/>
					<ModalContent>
						<TextField label="Link Title" required name="title" fullWidth />
						<TextField label="URL" required name="url" fullWidth />
						<Stack spacing={0.5}>
							<Typography variant="h4">
								Upload a custom icon for your link. (Optional)
							</Typography>
							<Typography variant="subtitle2">
								For best display, choose an image with a 1:1 ratio (i.e. a square
								shaped image). Avoid overly complex or decorative icons.
							</Typography>
							<FileField
								name="icon"
								type={FileType.Image}
								updatesFileId={quickLink?.iconFileId}
								allowDelete
								setIsUploading={setIsUploading}
							/>
						</Stack>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							loading={isSubmitting || isUploading}
							disabled={!isValid || !dirty}
							onClick={submitForm}>
							Save
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};

interface DeleteQuickLinkModalProps extends ModalContentProps {
	methods: QuickLinksMethods;
	quickLinkId: string;
}

export const DeleteQuickLinkModal = ({
	methods,
	quickLinkId,
	onClose,
}: DeleteQuickLinkModalProps) => {
	const { deleteQuickLink } = methods;
	const onSubmit = async () => {
		return await deleteQuickLink(quickLinkId);
	};

	return (
		<ConfirmationModalContent
			variant="destructive"
			primaryText="Delete Quick Link"
			secondaryText="Do you really want to delete this link? This process cannot be undone."
			onSubmit={onSubmit}
			onClose={onClose}
		/>
	);
};
