import { Divider, Stack, StackProps } from '@mui/material';

interface ModalContentProps extends StackProps {
	noDividers?: boolean;
}

export const ModalContent = ({ noDividers = false, ...props }: ModalContentProps) => {
	return (
		<>
			{!noDividers && <Divider />}
			<Stack p={2} spacing={2} overflow="auto" {...props} />
			{!noDividers && <Divider />}
		</>
	);
};
