import { format, formatDistance, isSameDay, isThisWeek, isToday, isYesterday } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useEffect, useRef } from 'react';
import { PageError } from 'utils/errors';

/**
 * JS Dates parse strings to assume an ISO string.  YYYY-DD-MM without
 * a timezone component assumes midnight in UTC time as it's an incomplete ISO string.
 * When rendering dates to the user both in input forms and in text,
 * You must offset the time in the date by the current timezone.
 * Date-fns has a function for this, however this function is just for a short hand.
 *
 * Explanation:
 * https://stackoverflow.com/questions/48172772/time-zone-issue-involving-date-fns-format
 *
 * @export
 * @param {(string | Date)} dateIncompleteIso
 * @return {*}
 */
export function zoneDateOnly(dateIncompleteIso: string | Date) {
	if (typeof dateIncompleteIso === 'string') {
		return utcToZonedTime(new Date(dateIncompleteIso), 'UTC');
	}

	return utcToZonedTime(dateIncompleteIso, 'UTC');
}

/* Largely taken from here, written by Rajesh Naroth:
https://rajeshnaroth.medium.com/writing-a-react-hook-to-cancel-promises-when-a-component-unmounts-526efabf251f
Cancellable promises are required for components that can unmount before all promises are resolved,
such as the promises for form submissions in the profile subcomponents.
*/
export type CancellablePromise = {
	promise: Promise<any>;
	cancel: () => void;
};

export const makeCancellable = (promise: Promise<any>): CancellablePromise => {
	let isCanceled = false;

	const wrappedPromise = new Promise((resolve, reject) => {
		return promise
			.then((val) => !isCanceled && resolve({ val }))
			.catch((err) => !isCanceled && reject({ err }));
	});

	return {
		promise: wrappedPromise,
		cancel: () => {
			isCanceled = true;
		},
	};
};

export const useCancellablePromise = (cancellable = makeCancellable) => {
	const emptyPromise = Promise.resolve(true);

	if (cancellable(emptyPromise).cancel === undefined) {
		throw new PageError('Cancellable Promises must have a cancel function');
	}

	const promises = useRef<CancellablePromise[]>([]);

	// When a component unmounts, each promise involved will
	// run its cancel function
	useEffect(() => {
		promises.current = promises.current ?? [];
		return () => {
			if (promises.current) {
				promises.current.forEach((p) => p.cancel());
				promises.current = [];
			}
		};
	}, []);

	// Create a cancellable promise from a regular promise, and add it
	// to our list of cancellable promises
	const cancellablePromise = (promise: Promise<any>): CancellablePromise => {
		const cPromise = cancellable(promise);
		promises.current.push(cPromise);
		return cPromise;
	};

	return { cancellablePromise };
};

export function isDemo(): boolean {
	return import.meta.env.VITE_DEMO === 'true';
}

// Checks if the given date is within the same day of the current time
// Formats the given date to be displayed to the user
export function formatDateDistance(date: Date) {
	const now = new Date();
	const formattedDistance = formatDistance(date, now, { addSuffix: true });

	return isSameDay(date, now)
		? `${format(date, 'h:mm aa')} (${formattedDistance})`
		: `${format(date, 'MMM d, yyyy')} (${formattedDistance})`;
}

export const formatEvoxMessageDate = (_date: Date | string) => {
	const date = typeof _date === 'string' ? new Date(_date) : _date;
	const time = format(date, 'h:mm aa');

	return isToday(date)
		? time
		: isYesterday(date)
		? `Yesterday, ${time}`
		: isThisWeek(date)
		? `${format(date, 'EEEE')}, ${time}`
		: `${format(date, 'M/d')}, ${time}`;
};

export const formatUSD = (value: number) => {
	const options = {
		style: 'currency',
		currency: 'USD',
		trailingZeroDisplay: 'auto',
	};
	const formatter = new Intl.NumberFormat('en-us', options as Intl.NumberFormatOptions);
	return formatter.format(value);
};
