import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import { InvoiceItemData, StripePriceInfo } from 'middleware-types';
import { formatUSD, zoneDateOnly } from 'utils/utils';
import { useBillingProducts } from '../../hooks/use-billing-products';

interface InvoiceItemRowProps {
	item: InvoiceItemData;
}

export const InvoiceItemRow = ({ item }: InvoiceItemRowProps) => {
	const { products } = useBillingProducts();
	const unitPrice = item.price
		? products
				.reduce<StripePriceInfo[]>((prev, current) => [...prev, ...current.prices], [])
				.find((p) => p.priceId === item.price)?.price
		: item.unitAmountDecimal !== undefined && item.unitAmountDecimal !== null
		? item.unitAmountDecimal / 100
		: undefined;
	const amount = unitPrice === undefined ? undefined : unitPrice * (item.quantity ?? 1);

	return (
		<TableRow>
			<TableCell size="small">
				<Stack spacing={-0.25}>
					{item.description}
					{item.startDate && (
						<Typography variant="caption">
							Date of Service: {format(zoneDateOnly(item.startDate), 'MM/dd/yyyy')}
						</Typography>
					)}
				</Stack>
			</TableCell>
			<TableCell align="right">
				{unitPrice === undefined ? '--' : formatUSD(unitPrice)}
			</TableCell>
			<TableCell align="right">{item.quantity ?? '--'}</TableCell>
			<TableCell align="right">{amount === undefined ? '--' : formatUSD(amount)}</TableCell>
		</TableRow>
	);
};
