import { Close } from '@mui/icons-material';
import { Stack, Typography, IconButton } from '@mui/material';
import { ReactNode } from 'react';

interface ModalHeaderProps {
	title: ReactNode;
	onClose: () => void;
}

export const ModalHeader = ({ title, onClose }: ModalHeaderProps) => {
	return (
		<Stack
			px={2}
			py={1}
			pr={1}
			spacing={1}
			direction="row"
			alignItems="center"
			justifyContent="space-between">
			{typeof title === 'string' ? (
				<Typography variant="h3" noWrap>
					{title}
				</Typography>
			) : (
				title
			)}
			<IconButton onClick={onClose}>
				<Close />
			</IconButton>
		</Stack>
	);
};
