import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { LabelWithColor } from '../helpers/label-colors';
import { useDeleteLabel } from '../hooks/labelsHooks';

interface DeleteLabelModalProps extends ModalContentProps {
	label: LabelWithColor;
}

export const DeleteLabelModal = ({ label, onClose }: DeleteLabelModalProps) => {
	const { deleteLabel } = useDeleteLabel();
	const onSubmit = async () => {
		return await deleteLabel(label.id);
	};

	return (
		<ConfirmationModalContent
			variant="destructive"
			primaryText="Delete Label"
			secondaryText={`Do you really want to delete ${label.name}? This process cannot be undone.`}
			onSubmit={onSubmit}
			onClose={onClose}
		/>
	);
};
