import { useMutation, useQuery } from '@apollo/client';
import { PHONE_FIELDS } from 'components/ui/fields/phone';
import { useToast } from 'components/ui/toast';
import { gql } from 'graphql-tag';
import {
	Mutation,
	MutationOrganizationRepresentativeDeleteArgs,
	MutationOrganizationRepresentativeUpsertArgs,
	Query,
	QueryOrganizationRepresentativesArgs,
	RepresentativeUpdate,
	SortDirection,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { NAME_FIELDS } from 'utils/useAccount';

/**
 * useOrgRepresentativesQuery() - Hook for loading the org representatives
 *
 * @param {string} orgId
 * @return {*}
 */
export const useOrgRepresentativesQuery = (orgId: string, pageSize: number, page: number) => {
	const toast = useToast();
	const { data, loading } = useQuery<
		Pick<Query, 'organizationRepresentatives'>,
		QueryOrganizationRepresentativesArgs
	>(ORG_REPRESENTATIVES, {
		variables: {
			organizationId: orgId,
			pageSize,
			offset: (page - 1) * pageSize,
			sortDirection: SortDirection.Ascending,
			searchFor: '',
		},
		onError: () => {
			toast.push('Unable to load org representatives', {
				variant: 'error',
			});
		},
	});

	return {
		representatives: data?.organizationRepresentatives.items ?? [],
		totalCount: data?.organizationRepresentatives.totalCount ?? 0,
		loading,
	};
};

const REPRESENTATIVE_FIELDS = gql`
	${NAME_FIELDS}
	${PHONE_FIELDS}
	fragment RepresentativeFields on Representative {
		id
		organizationId
		representativeType
		name {
			...NameFields
		}
		title
		emailAddress
		cellPhoneNumber {
			...PhoneFields
		}
		workPhoneNumber {
			...PhoneFields
		}
	}
`;

const ORG_REPRESENTATIVES = gql`
	${REPRESENTATIVE_FIELDS}
	query OrgRepresentatives(
		$organizationId: ID!
		$pageSize: Float!
		$offset: Float!
		$sortDirection: SortDirection!
		$searchFor: String!
	) {
		organizationRepresentatives(
			organizationId: $organizationId
			pageSize: $pageSize
			offset: $offset
			sortDirection: $sortDirection
			searchFor: $searchFor
		) {
			items {
				...RepresentativeFields
			}
			totalCount
		}
	}
`;

/**
 * useOrgRepresentativesDeleteMutation() - Hook for deleting an org representative
 *
 * @return {*}
 */
export const useOrgRepresentativesDeleteMutation = () => {
	const toast = useToast();
	const [orgRepresentativeDeleteMutation, { error, loading }] = useMutation<
		Pick<Mutation, 'organizationRepresentativeDelete'>,
		MutationOrganizationRepresentativeDeleteArgs
	>(ORG_REPRESENTATIVE_DELETE);
	const orgRepresentativeDelete = (organizationId: string, representativeId: string) => {
		return orgRepresentativeDeleteMutation({
			variables: { organizationId, representativeId },
			update: (cache) => {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'organizationRepresentatives',
				});

				cache.gc();
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Representative deleted successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { orgRepresentativeDelete, error, loading };
};

/**
 * useOrgRepresentativesUpsertMutation() - Hook for upserting an org representative
 *
 * @return {*}
 */
export const useOrgRepresentativeUpsertMutation = () => {
	const [orgRepresentativeUpsertMutation, { error, loading }] = useMutation<
		Pick<Mutation, 'organizationRepresentativeUpsert'>,
		MutationOrganizationRepresentativeUpsertArgs
	>(ORG_REPRESENTATIVE_UPSERT);
	const orgRepresentativeUpsert = (
		organizationId: string,
		update: RepresentativeUpdate,
		representativeId?: string
	) => {
		return orgRepresentativeUpsertMutation({
			variables: { organizationId, update, representativeId },
			update: (cache) => {
				cache.evict({
					id: 'ROOT_QUERY',
					fieldName: 'organizationRepresentatives',
				});

				cache.gc();
			},
		});
	};

	return { orgRepresentativeUpsert, error, loading };
};

const ORG_REPRESENTATIVE_UPSERT = gql`
	${REPRESENTATIVE_FIELDS}
	mutation organizationRepresentativeUpsert(
		$organizationId: ID!
		$representativeId: ID
		$update: RepresentativeUpdate!
	) {
		organizationRepresentativeUpsert(
			organizationId: $organizationId
			representativeId: $representativeId
			update: $update
		) {
			...RepresentativeFields
		}
	}
`;

const ORG_REPRESENTATIVE_DELETE = gql`
	mutation organizationRepresentativeDelete($organizationId: ID!, $representativeId: ID!) {
		organizationRepresentativeDelete(
			organizationId: $organizationId
			representativeId: $representativeId
		)
	}
`;
