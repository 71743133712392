import { LoadingButton } from '@mui/lab';
import { FormControlLabel, Grid, Link, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import clsx from 'clsx';
import { Credentials } from 'components/pages/auth/auth';
import { LoginForm } from 'components/pages/auth/login-form/login-form';
import { codeValidationWhiteSpaceDisallowed } from 'components/ui/LoginYups';
import { CheckboxField, TextField } from 'components/ui/fields';
import { Form, Formik, FormikHelpers } from 'formik';
import ConfirmEmail, { ConfirmEmailForm } from 'pages/auth/confirm-email';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from 'utils/useWindowDimensions';
import * as Yup from 'yup';
import { useStoreCognitoDevice } from './login-hooks';

const useLogin = () => {
	const [user, setUser] = useState<any>(undefined);
	const [isMFARequired, setMFARequired] = useState(false);
	const navigate = useNavigate();

	// We only set this if there is a "UserNotConfirmedException",
	// in which case we pass the creds to the ConfirmEmail component do finish the login process after confirmation.
	const [loginCredentials, setLoginCredentials] = useState<Credentials | null>(null);

	const onSubmit = async ({ email, password }: LoginForm, helpers: FormikHelpers<LoginForm>) => {
		await Auth.signIn(email, password)
			.then((user) => {
				setUser(user);
				if (
					user.challengeName === 'SMS_MFA' ||
					user.challengeName === 'SOFTWARE_TOKEN_MFA'
				) {
					setMFARequired(true);
					return;
				}
				navigate('/auth/mfa-config', { replace: true });
			})
			.catch((e) => {
				helpers.setErrors({ password: e?.message });

				if (e.code === 'UserNotConfirmedException') {
					// User never completed the email confirmation step, resend the code
					Auth.resendSignUp(email).catch((e) =>
						console.error('Error resending confirmation code', e)
					);
					// This will cause the ConfirmEmail screen to render
					setLoginCredentials({ email, password });
				}

				if (e.code === 'NotAuthorizedException')
					helpers.setErrors({ password: e?.message });
			});
	};

	const handleConfirmEmailSubmit = async (
		code: string,
		helpers: FormikHelpers<ConfirmEmailForm>
	) => {
		if (!loginCredentials) return;
		try {
			await Auth.confirmSignUp(loginCredentials.email, code);
			await Auth.signIn(loginCredentials.email, loginCredentials.password);
			navigate('/auth/mfa-config');
		} catch (e: any) {
			helpers.setErrors({ code: e?.message });
		}
	};

	return {
		user,
		isMFARequired,
		setMFARequired,
		loginCredentials,
		setLoginCredentials,
		onSubmit,
		handleConfirmEmailSubmit,
	};
};

const LoginPage = () => {
	const {
		user,
		isMFARequired,
		setMFARequired,
		loginCredentials,
		setLoginCredentials,
		onSubmit,
		handleConfirmEmailSubmit,
	} = useLogin();

	if (loginCredentials)
		return (
			<ConfirmEmail
				onSubmit={handleConfirmEmailSubmit}
				email={loginCredentials.email}
				setLoginCredentials={setLoginCredentials}
			/>
		);
	if (isMFARequired) return <MFAForm user={user} setMFARequired={setMFARequired} />;
	return <LoginForm onSubmit={onSubmit} />;
};

const MFAForm = ({ user, setMFARequired }: { user: any; setMFARequired: any }) => {
	const navigate = useNavigate();
	const { storeCognitoDevice } = useStoreCognitoDevice();
	const largeScreen = useWindowDimensions();
	const handleSubmitMfaCode = async (
		{ mfaCode, rememberDeviceOptIn }: MfaForm,
		helpers: FormikHelpers<MfaForm>
	) => {
		try {
			await Auth.confirmSignIn(user, mfaCode, user.challengeName);
			await Auth.updateUserAttributes(user, {
				'custom:mfa_configured': 'true',
			});
			if (rememberDeviceOptIn) {
				storeCognitoDevice(user?.deviceKey)
					.then(() => Auth.rememberDevice())
					.catch((error) => console.log(error));
			}
			navigate('/', { replace: true });
		} catch (e: any) {
			helpers.setErrors({ mfaCode: e?.message });
		}
	};

	return (
		<Formik<MfaForm>
			initialValues={{ mfaCode: '', rememberDeviceOptIn: false }}
			onSubmit={(vals, helpers) => handleSubmitMfaCode(vals, helpers)}
			validationSchema={mfaValidationSchema}
			validateOnBlur={false}
			validateOnChange={false}>
			{({ isSubmitting, errors, setErrors }) => (
				<Form className="m-auto flex w-96 flex-col text-center">
					<img
						className={clsx(' mx-auto mb-10 w-32', {
							hidden: largeScreen,
						})}
						src="/img/evolve-logo.svg"
						alt="FTEvolve"
					/>
					<Typography className="text-neutral-900" mb={2} variant="h2">
						Input Code
					</Typography>
					{user.challengeName === 'SMS_MFA' ? (
						<Typography className="text-neutral-900" mb={2}>
							Please enter the Multi-Factor Authentication (MFA) code that was sent to
							your registered phone number.
						</Typography>
					) : (
						<Typography className="text-neutral-900" mb={2}>
							Please enter the One-Time Passcode (OTP) from your Authenticator App.
						</Typography>
					)}
					<div className="flex flex-col gap-3">
						{/* These inputs prevent Chrome from autofilling
							the MFA input with email and password */}
						<input type="hidden" />
						<input type="hidden" />
						<TextField
							label="MFA Code *"
							type="tel"
							name="mfaCode"
							InputLabelProps={{ shrink: true }}
							error={!!errors?.mfaCode}
							helperText={errors?.mfaCode}
							FormHelperTextProps={{
								className: clsx(!!errors?.mfaCode && 'static', 'text-left mb-1'),
							}}
							onChangeCapture={() =>
								setErrors({
									...errors,
									mfaCode: undefined,
								})
							}
							sx={{
								fieldset: { borderColor: 'neutral.700' },
							}}
							focused
						/>
					</div>
					<LoadingButton
						className="mt-2 text-white "
						variant="contained"
						color="primary"
						fullWidth
						type="submit"
						loading={isSubmitting}>
						Submit Code
					</LoadingButton>
					<Typography className="mt-5">
						<Link
							data-test="back-btn"
							className="text-navy-500"
							onClick={() => setMFARequired(false)}
							href="/auth/login">
							Back to Log in
						</Link>
					</Typography>
					<Grid alignContent="center">
						<FormControlLabel
							control={
								<CheckboxField name="rememberDeviceOptIn" disabled={isSubmitting} />
							}
							label="Remember device for 30 days"
						/>
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export type MfaForm = {
	mfaCode: string;
	rememberDeviceOptIn: boolean;
};

const mfaValidationSchema = Yup.object({
	mfaCode: codeValidationWhiteSpaceDisallowed,
});

export default LoginPage;
