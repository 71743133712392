import { gql, useQuery } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import { Query, QueryBillingOrganizationBillingInfoGetArgs } from 'middleware-types';

const GET_ORG_BILLING_INFO = gql`
	query GetOrgBillingInfo($organizationId: String!) {
		billingOrganizationBillingInfoGet(organizationId: $organizationId) {
			planInformation {
				planInterval
				planPrice
				planName
			}
			alerts {
				description
				severity
			}
			inheritedFromRootOrganization
		}
	}
`;

export const useOrgBillingInfo = () => {
	const organizationId = useOrgId();
	const { data, loading } = useQuery<
		Pick<Query, 'billingOrganizationBillingInfoGet'>,
		QueryBillingOrganizationBillingInfoGetArgs
	>(GET_ORG_BILLING_INFO, { variables: { organizationId } });

	const billingInfo = data?.billingOrganizationBillingInfoGet;
	return { billingInfo, loading };
};
