import { AddOutlined } from '@mui/icons-material';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	IconButton,
	Pagination,
	Skeleton,
	Stack,
} from '@mui/material';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { RepresentativeType } from 'middleware-types';
import { useEffect, useState } from 'react';
import { Permission } from 'utils/permissions';
import { useAssociateUser } from 'utils/useAssociateUser';
import { useSiteUser } from 'utils/useSiteUser';
import { useOrgRepresentativesQuery } from './hooks';
import { OrgRepresentativeForm } from './modal';
import { OrgRepCard } from './org-rep-card';

/**
 * Displays the organization representatives
 *
 * @param {{ orgId: string }} { orgId }
 * @return {*}
 */
export const OrgRepresentatives = ({ orgId }: { orgId: string }) => {
	const { hasPermission: hasAssociateUserPermission } = useAssociateUser(orgId);
	const { hasPermission: hasSiteUserPermission } = useSiteUser();
	const canEdit =
		hasAssociateUserPermission(Permission.Org_Account_U) ||
		hasSiteUserPermission(Permission.Site_OrgAcct_U);

	const [addModalOpen, setAddModalOpen] = useState(false);
	const [page, setPage] = useState(1);

	const pageSize = 6;

	const handlePageChange = (_: unknown, p: number): void => {
		setPage(p);
	};

	const { representatives, totalCount, loading } = useOrgRepresentativesQuery(
		orgId,
		pageSize,
		page
	);

	useEffect(() => {
		if (page > 1 && !loading && representatives.length === 0) {
			return setPage((prev) => prev - 1);
		}
	}, [representatives, page, loading]);

	if (loading) return <OrgRepresentativesSkeleton />;

	return (
		<>
			<Card>
				<CardHeader
					title="Representative Information"
					action={
						canEdit && (
							<IconButton edge="end" onClick={() => setAddModalOpen(true)}>
								<AddOutlined />
							</IconButton>
						)
					}
				/>
				<Divider />
				<CardContent>
					<Box
						pt={1}
						display="grid"
						gap={2}
						gridTemplateColumns={{
							xs: 'repeat(auto-fill)',
							sm: 'repeat(auto-fill, 15rem)',
						}}>
						{representatives.map((rep) => (
							<OrgRepCard
								key={rep.id}
								orgId={orgId}
								rep={rep}
								canEdit={canEdit}
								canDelete={
									!rep.representativeType.includes(RepresentativeType.Primary) ||
									representatives.filter((rep) =>
										rep.representativeType.includes(RepresentativeType.Primary)
									).length > 1
								}
							/>
						))}
					</Box>
					<Stack marginTop={2} alignItems="center">
						<Pagination
							page={page}
							count={Math.ceil(totalCount / pageSize)}
							onChange={handlePageChange}
						/>
					</Stack>
				</CardContent>
			</Card>
			{/** add modal */}
			<ModalOrDrawer open={addModalOpen} dialogProps={{ maxWidth: 'lg' }}>
				<OrgRepresentativeForm orgId={orgId} onClose={() => setAddModalOpen(false)} />
			</ModalOrDrawer>
		</>
	);
};

const OrgRepresentativesSkeleton = () => (
	<Card>
		<CardHeader title="Representative Information" />
		<CardContent>
			<Grid container spacing={1}>
				<Grid item xs={6}>
					<Card variant="outlined">
						<CardContent>
							<Skeleton width="50%" />
							<Skeleton width="45%" />
							<br />
							<Skeleton width="35%" />
							<Skeleton width="40%" />
							<Skeleton width="35%" />
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6}>
					<Card variant="outlined">
						<CardContent>
							<Skeleton width="50%" />
							<Skeleton width="45%" />
							<br />
							<Skeleton width="35%" />
							<Skeleton width="40%" />
							<Skeleton width="35%" />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</CardContent>
	</Card>
);
