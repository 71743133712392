import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandleOutlined } from '@mui/icons-material';
import { ListItem, Stack, Typography } from '@mui/material';
import { UserEmblemAvatar } from 'components/ui/emblem/emblem-avatar';
import { forwardRef, HTMLAttributes } from 'react';
import { theme } from 'utils/theme';
import { EmblemExtended } from './order-team-members-modal';

type SortableTeamMemberListItemProps = {
	teamMember: EmblemExtended;
	orderToSet: number;
} & HTMLAttributes<HTMLDivElement>;

export const SortableTeamMemberListItem = ({
	teamMember,
	orderToSet,
	...props
}: SortableTeamMemberListItemProps) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: teamMember.order,
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<TeamMemberListItem
			teamMember={teamMember}
			orderToSet={orderToSet}
			ref={setNodeRef}
			style={style}
			{...props}
			{...attributes}
			{...listeners}
		/>
	);
};

type TeamMemberListItemProps = {
	teamMember: EmblemExtended;
	orderToSet?: number;
} & HTMLAttributes<HTMLDivElement>;

const TeamMemberListItem = forwardRef<HTMLDivElement, TeamMemberListItemProps>(
	({ teamMember, orderToSet, style, ...props }, ref) => {
		return (
			<Stack
				sx={{
					background: 'white',
					borderRadius: 1,
					border: `1px solid ${theme.palette.neutral[100]}`,
				}}
				component="div"
				ref={ref}
				style={style}
				maxWidth="100vw">
				<ListItem sx={{ touchAction: 'none' }}>
					<Stack
						width="100%"
						direction="row"
						justifyContent="space-between"
						alignItems="center">
						<Stack direction="row" alignItems="center" spacing={1}>
							<Stack component="div" {...props} sx={{ cursor: 'grab' }}>
								<DragHandleOutlined sx={{ color: 'neutral.500' }} />
							</Stack>
							<Stack
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								width="100%">
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
									overflow="hidden">
									{orderToSet && <Typography>{orderToSet}</Typography>}
									<UserEmblemAvatar id={teamMember.id} noDropdown />
									<Typography overflow="hidden" textOverflow="ellipsis">
										{teamMember.displayName}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</ListItem>
			</Stack>
		);
	}
);

TeamMemberListItem.displayName = 'TeamMemberDisplayName';

export default TeamMemberListItem;
