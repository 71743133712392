import { gql, useMutation } from '@apollo/client';
import { useOrgId } from 'components/pages/org/outlet';
import { useToast } from 'components/ui/toast';
import {
	Mutation,
	MutationBillingOrganizationDraftInvoicingUpdateArgs,
	Query,
	QueryBillingOrganizationDraftInvoicingGetArgs,
} from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { GET_DRAFT_INVOICING } from './use-draft-invoicing-enabled';

const UPDATE_DRAFT_INVOICING = gql`
	mutation UpdateDraftInvoicing($organizationId: String!, $enabled: Boolean!) {
		billingOrganizationDraftInvoicingUpdate(organizationId: $organizationId, enabled: $enabled)
	}
`;

export const useUpdateDraftInvoicing = () => {
	const toast = useToast();
	const organizationId = useOrgId();
	const [_updateDraftInvoicing, { loading }] = useMutation<
		Pick<Mutation, 'billingOrganizationDraftInvoicingUpdate'>,
		MutationBillingOrganizationDraftInvoicingUpdateArgs
	>(UPDATE_DRAFT_INVOICING);

	const updateDraftInvoicing = async (enabled: boolean) => {
		return await _updateDraftInvoicing({
			variables: { organizationId, enabled },
			update: (cache) => {
				cache.updateQuery<
					Pick<Query, 'billingOrganizationDraftInvoicingGet'>,
					QueryBillingOrganizationDraftInvoicingGetArgs
				>({ query: GET_DRAFT_INVOICING, variables: { organizationId } }, () => ({
					billingOrganizationDraftInvoicingGet: { draftInvoicingEnabled: enabled },
				}));
			},
		})
			.then((res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Draft invoicing updated successfully.', { variant: 'success' });
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { updateDraftInvoicing, loading };
};
