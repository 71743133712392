import { gql } from '@apollo/client';

export const BILLING_ITEM_FIELDS = gql`
	fragment BillingItemFields on AdHocBillingItem {
		dateOfService
		description
		externalOrganizationId
		id
		quantity
		recurring
		stripePriceId
		stripeProductId
		unitPrice
	}
`;
