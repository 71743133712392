import { JournalEntry, JournalSeverity, ListSortDirection } from 'middleware-types';
import { createContext, ReactNode, useContext, useState } from 'react';
import { useBillingJournals } from './hooks/use-billing-journals';
import { DEFAULTPAGESIZES } from 'utils/theme';

interface BillingJournalsContextState {
	journals: JournalEntry[];
	totalCount: number;
	loading: boolean;
	setSearchFor: React.Dispatch<React.SetStateAction<string>>;
	severity: JournalSeverity | '';
	setSeverity: React.Dispatch<React.SetStateAction<'' | JournalSeverity>>;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	pageSize: number;
	setPageSize: React.Dispatch<React.SetStateAction<number>>;
}

const BillingJournalsContext = createContext<BillingJournalsContextState | undefined>(undefined);

interface BillingJournalsProviderProps {
	allOrgs: boolean;
	children: ReactNode;
}

export const BillingJournalsProvider = ({ allOrgs, children }: BillingJournalsProviderProps) => {
	const [searchFor, setSearchFor] = useState('');
	const [severity, setSeverity] = useState<JournalSeverity | ''>('');
	const [pageSize, setPageSize] = useState(DEFAULTPAGESIZES[0]);
	const [page, setPage] = useState(0);

	const { journals, totalCount, loading } = useBillingJournals(
		{
			searchFor,
			severity: severity || undefined,
			pageSize,
			offset: page * pageSize,
			sortDirection: ListSortDirection.Descending,
		},
		allOrgs
	);

	return (
		<BillingJournalsContext.Provider
			value={{
				journals,
				totalCount,
				loading,
				setSearchFor,
				severity,
				setSeverity,
				page,
				setPage,
				pageSize,
				setPageSize,
			}}>
			{children}
		</BillingJournalsContext.Provider>
	);
};

export const useBillingJournalsContext = () => {
	const value = useContext(BillingJournalsContext);
	if (value === undefined)
		throw new Error('useBillingJournalsContext must be used inside a BillingJournalsProvider');
	return value;
};
