import { TableCell, TableRow } from '@mui/material';
import { format } from 'date-fns';
import { JournalEntry } from 'middleware-types';
import { JournalSeverityChip } from './journal-severity-chip';

interface BillingJournalRowProps {
	journal: JournalEntry;
	includeCompany: boolean;
}

export const BillingJournalRow = ({ journal, includeCompany }: BillingJournalRowProps) => {
	return (
		<TableRow>
			<TableCell>
				<JournalSeverityChip severity={journal.severity} />
			</TableCell>
			<TableCell>{format(new Date(journal.dateTimeUtc), 'M/d/yyyy h:mm a')}</TableCell>
			{includeCompany && <TableCell>{journal.journalEntityName}</TableCell>}
			<TableCell>{journal.message}</TableCell>
			<TableCell>{journal.reference ?? '--'}</TableCell>
		</TableRow>
	);
};
