import { Skeleton, Stack } from '@mui/material';
import { PageError } from 'utils/errors';
import { useIsSiteUser } from 'utils/useIsSiteUser';
import { AdHocItemsCard } from './ad-hoc-items-card';
import { BillingJournalsCard } from './billing-journals-card';
import { useOrgBillingInfo } from './hooks/use-org-billing-info';
import { OrgBillingAlerts } from './org-billing-alerts';
import { PlanInformationCard } from './plan-information-card';

export const OrgBillingPage = () => {
	const isSiteUser = useIsSiteUser();
	const { billingInfo, loading } = useOrgBillingInfo();

	if (loading) {
		return (
			<Stack p={4} spacing={2} alignItems="center" justifyContent="center">
				<Skeleton variant="circular" width={80} height={80} />
				<Stack alignItems="center">
					<Skeleton variant="text" width={80} />
					<Skeleton variant="text" width={100} />
				</Stack>
			</Stack>
		);
	}

	if (!billingInfo) throw PageError;
	const { planInformation, alerts, inheritedFromRootOrganization: isChildOrg } = billingInfo;

	return (
		<Stack spacing={2.5}>
			{!isChildOrg && <OrgBillingAlerts alerts={alerts} />}
			<PlanInformationCard planInformation={planInformation} isChildOrg={isChildOrg} />
			{isSiteUser && <AdHocItemsCard isChildOrg={isChildOrg} />}
			{isSiteUser && !isChildOrg && <BillingJournalsCard />}
		</Stack>
	);
};
