import { InfoOutlined, OpenInNew } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Divider, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { BillingPlanInformation } from 'middleware-types';
import { Permission } from 'utils/permissions';
import { useIsSiteUser } from 'utils/useIsSiteUser';
import { useSiteUser } from 'utils/useSiteUser';
import { formatUSD } from 'utils/utils';
import { useBillingDashboardUrl } from './hooks/use-billing-dashboard-url';
import { useBillingPortalUrl } from './hooks/use-billing-portal-url';
import { useDraftInvoicingEnabled } from './hooks/use-draft-invoicing-enabled';
import { useUpdateDraftInvoicing } from './hooks/use-update-draft-invoicing';

interface PlanInformationCardProps {
	planInformation: BillingPlanInformation | undefined;
	isChildOrg: boolean;
}

export const PlanInformationCard = ({ planInformation, isChildOrg }: PlanInformationCardProps) => {
	const isSiteUser = useIsSiteUser();
	const { onClick: onPortalClick, loading: portalUrlLoading } = useBillingPortalUrl();
	const { onClick: onDashboardClick, loading: dashboardUrlLoading } = useBillingDashboardUrl();

	return (
		<Card>
			<Box px={2.5} py={2}>
				<Typography variant="h2">Plan Information</Typography>
			</Box>
			<Divider />
			<Box p={2.5}>
				{isChildOrg ? (
					<Box p={2} bgcolor="neutral.50" borderRadius={1}>
						<Typography variant="body1">
							This organization&apos;s plan must be managed through its parent
							organization.
						</Typography>
					</Box>
				) : (
					<Stack direction="row" alignItems="flex-end" justifyContent="space-between">
						{planInformation ? (
							<Stack spacing={0.5}>
								<Typography variant="h3">{planInformation.planName}</Typography>
								<Typography variant="body1">
									{formatUSD(planInformation.planPrice)}/
									{planInformation.planInterval}
								</Typography>
							</Stack>
						) : (
							<Typography variant="body1" color="GrayText" alignSelf="center">
								No plan information
							</Typography>
						)}
						<Stack direction="row" spacing={1.5}>
							<LoadingButton
								variant="outlined"
								startIcon={<OpenInNew />}
								loading={portalUrlLoading}
								onClick={() => onPortalClick()}>
								Manage Payment & Invoices
							</LoadingButton>
							{isSiteUser && (
								<LoadingButton
									variant="outlined"
									startIcon={<OpenInNew />}
									loading={dashboardUrlLoading}
									onClick={() => onDashboardClick()}>
									Customer Dashboard
								</LoadingButton>
							)}
						</Stack>
					</Stack>
				)}
			</Box>
			{isSiteUser && !isChildOrg && <DraftInvoicingSection />}
		</Card>
	);
};

const DraftInvoicingSection = () => {
	const { enabled, loading: queryLoading } = useDraftInvoicingEnabled();
	const { updateDraftInvoicing, loading: updateLoading } = useUpdateDraftInvoicing();
	const loading = queryLoading || updateLoading;

	const { hasPermission } = useSiteUser();
	const canUpdate = hasPermission(Permission.Site_OrgBilling_U);

	return (
		<>
			<Divider />
			<Stack px={1.5} py={1.5} direction="row" alignItems="center" spacing={1.5}>
				<Switch
					checked={enabled}
					disabled={loading || !canUpdate}
					onChange={(_e, checked) => updateDraftInvoicing(checked)}
				/>
				<Typography variant="body1">Create monthly invoices as drafts</Typography>
				<Tooltip title="When automatic draft creation is off, customers will automatically be invoiced and charged through Stripe.">
					<InfoOutlined sx={{ color: 'neutral.500' }} />
				</Tooltip>
			</Stack>
		</>
	);
};
