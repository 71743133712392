import { NotificationSummariesPage } from 'components/pages/notification-summaries/notification-summaries-page';
import { PageTitle } from 'components/ui/page';
import { Permission } from 'utils/permissions';
import { useSiteUser } from 'utils/useSiteUser';

export const SystemNotifications = () => {
	const { hasPermission } = useSiteUser();
	const canCreate = hasPermission(Permission.System_Notifications_C);

	return (
		<>
			<PageTitle title="System Announcements" />
			<NotificationSummariesPage type="system" canCreate={canCreate} />
		</>
	);
};
