import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, TextField as MTextField, MenuItem, Skeleton } from '@mui/material';
import { Alert } from 'components/ui/alert';
import { SelectField, TextField } from 'components/ui/fields';
import { ConfirmationModalContent } from 'components/ui/modals/confirmation-modal-content';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { useToast } from 'components/ui/toast';
import { Formik, FormikProps } from 'formik';
import { AssociateInvitationRequest } from 'middleware-types';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { useValidation } from 'utils/useValidation';
import {
	useAllOrgRolesQuery,
	useDeleteAssociateInvitationMutation,
	useInviteAssociateMutation,
} from '../hooks';

interface DeleteAssociateInvitationModalProps extends ModalContentProps {
	orgId: string;
	invitationId: string;
	displayName: string;
}

export const DeleteAssociateInvitationModal = ({
	orgId,
	invitationId,
	displayName,
	onClose,
}: DeleteAssociateInvitationModalProps) => {
	const { deleteInvitation } = useDeleteAssociateInvitationMutation();
	return (
		<ConfirmationModalContent
			variant="destructive"
			title="Delete Invitation"
			primaryText="Delete Invitation"
			secondaryText={`Are you sure you want to delete the invitation to ${displayName}?`}
			onSubmit={async () => {
				await deleteInvitation(orgId, invitationId);
				return true;
			}}
			onClose={onClose}
		/>
	);
};

/**
 * useInvAssociate - Custom hook for getting validation schema, handling submit, and loading.
 *
 * @returns {({
 * 	initialValues: MAssociateInvitationRequest;
 * 	roles: AllOrgRoles;
 * 	validationSchema: ValidationSchema | null;
 * 	submit: (values: MAssociateInvitationRequest) => Promise<void>;
 * 	loading: boolean;
 * 	error: ApolloError | undefined;
 * })}
 */
const useInvAssociate = (orgId: string) => {
	const validation = useValidation('AssociateInvitationRequest');
	const toast = useToast();
	const { invite, error } = useInviteAssociateMutation(orgId);
	const rolesQuery = useAllOrgRolesQuery(orgId);
	let initialValues = {
		emailAddress: '',
		displayName: '',
		roleId: '',
	};

	/**
	 * When the form submits
	 */
	const submit = async (values: AssociateInvitationRequest) => {
		return await invite(values)
			.then(async (res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return {
		initialValues,
		roles: rolesQuery.roles,
		submit,
		validationSchema: validation.schema,
		loading: validation.loading || rolesQuery.loading,
		error,
	};
};

interface AssociateInvitationFormProps extends ModalContentProps {
	orgId: string;
	onSubmit: (invite: AssociateInvitationRequest) => void;
}

/**
 * Associate Invitation Modal
 *
 * @param {InvitationModalProps} props
 * @returns
 */
export const AssociateInvitationForm = ({
	orgId,
	onSubmit,
	onClose,
}: AssociateInvitationFormProps) => {
	const { initialValues, roles, loading, submit, validationSchema, error } =
		useInvAssociate(orgId);

	const handleSubmit = async (values: AssociateInvitationRequest) => {
		if (await submit(values)) {
			onSubmit(values);
		}
	};

	if (loading) return <SkeletonForm />;

	return (
		<Formik<AssociateInvitationRequest>
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			initialValues={initialValues}
			enableReinitialize>
			{(props: FormikProps<AssociateInvitationRequest>) => (
				<>
					<ModalHeader title="Invite Associate" onClose={onClose} />
					<ModalContent>
						<Box>
							<Grid container spacing={1} columnSpacing={2}>
								<Grid xs={12} sm={6} md={6} item>
									<TextField
										label="Display Name"
										required
										name="displayName"
										fullWidth
									/>
								</Grid>
								<Grid xs={12} sm={6} md={6} item>
									<TextField
										label="Email Address"
										required
										type="email"
										name="emailAddress"
										fullWidth
									/>
								</Grid>
								<Grid xs={12} sm={6} md={6} item>
									<SelectField
										label="Role"
										required
										name="roleId"
										value={props.values.roleId ?? ''}
										fullWidth>
										{roles.map((src, i) => (
											<MenuItem key={i} value={src.id}>
												{src.name !== '' ? src.name : <em>None</em>}
											</MenuItem>
										))}
									</SelectField>
								</Grid>
								{error && (
									<Grid xs={12} md={12} item>
										<Alert error={error} />
									</Grid>
								)}
							</Grid>
						</Box>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							disabled={!props.isValid || !props.dirty || props.isSubmitting}
							loading={props.isSubmitting}
							onClick={() => props.submitForm()}>
							Send Invite
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};

const SkeletonForm = () => (
	<ModalContent noDividers>
		<Box>
			<Grid container spacing={1} columnSpacing={2}>
				<Grid xs={12} md={6} item>
					<Skeleton animation="wave" width="100%">
						<MTextField fullWidth />
					</Skeleton>
				</Grid>
				<Grid xs={12} sm={6} md={6} item>
					<Skeleton animation="wave" width="100%">
						<MTextField fullWidth />
					</Skeleton>
				</Grid>
				<Grid xs={12} sm={6} md={6} item>
					<Skeleton animation="wave" width="100%">
						<MTextField fullWidth />
					</Skeleton>
				</Grid>
			</Grid>
		</Box>
	</ModalContent>
);
