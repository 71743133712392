import { gql, useMutation, useQuery } from '@apollo/client';
import { AddOutlined } from '@mui/icons-material';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	IconButton,
	Pagination,
	Skeleton,
	Stack,
} from '@mui/material';
import {
	LocationAddressForm,
	LocationAddressFormValues,
} from 'components/pages/user/account/locations/locations-form';
import { LocationInformationItem } from 'components/pages/user/account/locations/locations-item';
import { Alert } from 'components/ui/alert';
import { GEOADDRESS_FIELDS } from 'components/ui/fields/address';
import { ModalOrDrawer } from 'components/ui/modals/modal-or-drawer';
import { useToast } from 'components/ui/toast';
import { format } from 'date-fns';
import {
	LocationAddressUpdate,
	Mutation,
	MutationUserAccountLocationAddressAddOrUpdateArgs,
	MutationUserAccountLocationAddressDeleteArgs,
	Query,
	QueryUserLocationAddressesArgs,
	SortDirection,
} from 'middleware-types';
import { useEffect, useState } from 'react';
import { handleNoResponse, responseHasErrors } from 'utils/errors';
import { Permission } from 'utils/permissions';
import { useSession } from 'utils/session';
import { useSiteUser } from 'utils/useSiteUser';
import { zoneDateOnly } from 'utils/utils';

export const DELETE_LOCATION_ADDRESS = gql`
	mutation userAccountLocationAddressDelete($userId: ID!, $locationAddressId: ID!) {
		userAccountLocationAddressDelete(userId: $userId, locationAddressId: $locationAddressId)
	}
`;

/**
 * Hook to delete a user's location address.
 * @param userId
 * @returns
 */
export const useDeleteLocationAddressMutation = (userId: string) => {
	const toast = useToast();
	const [userAccountLocationAddressDelete, { error, loading }] = useMutation<
		Pick<Mutation, 'userAccountLocationAddressDelete'>,
		MutationUserAccountLocationAddressDeleteArgs
	>(DELETE_LOCATION_ADDRESS, {
		update: (cache) => {
			cache.evict({
				id: 'ROOT_QUERY',
				fieldName: 'userLocationAddresses',
			});

			cache.gc();
		},
	});

	return {
		deleteLocation: (locationAddressId: string) => {
			return userAccountLocationAddressDelete({
				variables: {
					userId,
					locationAddressId,
				},
			})
				.then((res) => {
					if (responseHasErrors(res.errors, { toast })) {
						return false;
					}
					toast.push('Location address deleted successfully.', {
						variant: 'success',
					});
					return true;
				})
				.catch(() => {
					handleNoResponse({ toast });
					return false;
				});
		},
		error,
		loading,
	};
};

export const ADD_OR_UPDATE_LOCATION_ADDRESS = gql`
	${GEOADDRESS_FIELDS}
	mutation userAccountLocationAddressAddOrUpdate(
		$userId: ID!
		$locationAddressId: String
		$update: LocationAddressUpdate!
	) {
		userAccountLocationAddressAddOrUpdate(
			userId: $userId
			locationAddressId: $locationAddressId
			update: $update
		) {
			id
			parentId
			addressType
			fromDate
			toDate
			address {
				...GeoAddressFields
			}
		}
	}
`;

/**
 * Hook to add or update a user's location address.
 * @param userId
 * @returns
 */
export const useAddOrUpdateLocationAddressMutation = (userId: string) => {
	const toast = useToast();
	const [locationAddOrUpdate, { loading, error }] = useMutation<
		Pick<Mutation, 'userAccountLocationAddressAddOrUpdate'>,
		MutationUserAccountLocationAddressAddOrUpdateArgs
	>(ADD_OR_UPDATE_LOCATION_ADDRESS, {
		update: (cache) => {
			cache.evict({
				id: 'ROOT_QUERY',
				fieldName: 'userLocationAddresses',
			});

			cache.gc();
		},
	});
	const addOrUpdate = async (location: LocationAddressFormValues, locationId?: string) => {
		return locationAddOrUpdate({
			variables: {
				userId,
				locationAddressId: locationId,
				update: {
					...(location as LocationAddressUpdate),
					fromDate: location.fromDate ? format(location.fromDate, 'yyyy-MM-dd') : null,
					toDate: location.toDate ? format(location.toDate, 'yyyy-MM-dd') : null,
				},
			},
		})
			.then(async (res) => {
				if (responseHasErrors(res.errors, { toast })) {
					return false;
				}
				toast.push('Location information updated successfully.', {
					variant: 'success',
				});
				return true;
			})
			.catch(() => {
				handleNoResponse({ toast });
				return false;
			});
	};

	return { addOrUpdate, loading, error };
};

export const LOCATIONADDRESSES = gql`
	${GEOADDRESS_FIELDS}
	query userLocationAddresses(
		$userId: ID!
		$pageSize: Float!
		$offset: Float!
		$sortDirection: SortDirection!
		$searchFor: String!
	) {
		userLocationAddresses(
			userId: $userId
			pageSize: $pageSize
			offset: $offset
			sortDirection: $sortDirection
			searchFor: $searchFor
		) {
			totalCount
			items {
				id
				parentId
				addressType
				fromDate
				toDate
				address {
					...GeoAddressFields
				}
			}
		}
	}
`;

/**
 * Hook to get the user's location addresses.
 * @param userId
 * @returns
 */
export const useLocationAddressesQuery = (userId: string, pageSize: number, page: number) => {
	const { data, loading, error } = useQuery<
		Pick<Query, 'userLocationAddresses'>,
		QueryUserLocationAddressesArgs
	>(LOCATIONADDRESSES, {
		fetchPolicy: 'cache-first',
		variables: {
			userId,
			pageSize,
			offset: (page - 1) * pageSize,
			sortDirection: SortDirection.Ascending,
			searchFor: '',
		},
	});

	return {
		locations:
			data?.userLocationAddresses.items?.map((la) => ({
				id: la.id,
				address: la.address,
				addressType: la.addressType,
				fromDate: la?.fromDate ? zoneDateOnly(la?.fromDate) : null,
				toDate: la?.toDate ? zoneDateOnly(la.toDate) : null,
			})) ?? [],
		totalCount: data?.userLocationAddresses.totalCount ?? 0,
		loading,
		error,
	};
};

/**
 * Displays the user's location addresses in Account Settings
 *
 * @param {{ userId: string }} { userId }
 * @return {*}  {React.JSX.Element}
 */
export const LocationInformation = ({ userId }: { userId: string }): React.JSX.Element => {
	const [page, setPage] = useState(1);
	const pageSize = 6;
	const { locations, totalCount, loading, error } = useLocationAddressesQuery(
		userId,
		pageSize,
		page
	);
	const { hasPermission } = useSiteUser();
	const { user } = useSession();
	const canEdit =
		userId === user.userId ||
		(user.siteUserId && hasPermission(Permission.Site_User_U)) ||
		(!user.siteUserId && hasPermission(Permission.SocialUser_Account_U));

	const { deleteLocation } = useDeleteLocationAddressMutation(userId);
	const { addOrUpdate } = useAddOrUpdateLocationAddressMutation(userId);

	const [addModalOpen, setAddModalOpen] = useState(false);

	const handlePageChange = (_: unknown, p: number): void => {
		setPage(p);
	};

	useEffect(() => {
		if (page > 1 && !loading && locations.length === 0) {
			return setPage((prev) => prev - 1);
		}
	}, [locations, page, loading]);

	return (
		<>
			<Card>
				<CardHeader
					title="Location Information"
					action={
						canEdit && (
							<IconButton edge="end" onClick={() => setAddModalOpen(true)}>
								<AddOutlined />
							</IconButton>
						)
					}
				/>
				<Divider />
				<CardContent>
					{error && <Alert error={error} />}
					{loading ? (
						<LocationInformationSkeleton />
					) : (
						locations && (
							<>
								<Box
									pt={1}
									display="grid"
									gap={2}
									gridTemplateColumns={{
										xs: 'repeat(auto-fill)',
										sm: 'repeat(auto-fill, 15rem)',
									}}>
									{locations.map((locationAddress) => (
										<LocationInformationItem
											key={locationAddress.id}
											disabled={canEdit}
											locationAddress={locationAddress}
											deleteLocation={deleteLocation}
											addOrUpdate={addOrUpdate}
										/>
									))}
								</Box>
								<Stack marginTop={2} alignItems="center">
									<Pagination
										page={page}
										count={Math.ceil(totalCount / pageSize)}
										onChange={handlePageChange}
									/>
								</Stack>
							</>
						)
					)}
				</CardContent>
			</Card>
			{/** add modal */}
			<ModalOrDrawer open={addModalOpen} dialogProps={{ maxWidth: 'lg' }}>
				<LocationAddressForm
					addOrUpdate={addOrUpdate}
					onClose={() => setAddModalOpen(false)}
				/>
			</ModalOrDrawer>
		</>
	);
};

const LocationInformationSkeleton = () => (
	<Box display="flex" flexWrap="wrap">
		{[...Array(3)].map((e, i) => (
			<Box key={i} mr={1} mb={1}>
				<Skeleton height="14rem" width="14rem" variant="rectangular" />
			</Box>
		))}
	</Box>
);
