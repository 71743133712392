import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControlLabel, Radio, Stack, Typography } from '@mui/material';
import { DatePickerField } from 'components/ui/fields';
import { RadioGroupField } from 'components/ui/fields/radio-group';
import { ModalButtonsContainer } from 'components/ui/modals/modal-buttons-container';
import { ModalContent } from 'components/ui/modals/modal-content';
import { ModalHeader } from 'components/ui/modals/modal-header';
import { ModalContentProps } from 'components/ui/modals/modal-types';
import { Formik, FormikErrors, FormikProps } from 'formik';
import {
	ConversationParticipant,
	Emblem,
	Participant,
	ParticipantHistoryType,
} from 'middleware-types';
import { convertEntityType } from '../helpers/utils';
import { useAddParticipants } from '../hooks/participants-hooks';
import { ParticipantSearchField } from '../shared/participant-search-field';

interface AddParticipantsFormValues {
	participants: Emblem[];
	historyType: ParticipantHistoryType;
	historyShareFromUtc: Date | null;
}

interface AddParticipantsModalProps extends ModalContentProps {
	existingParticipants: ConversationParticipant[];
}

export const AddParticipantsModal = ({
	existingParticipants,
	onClose,
}: AddParticipantsModalProps) => {
	const initialValues: AddParticipantsFormValues = {
		participants: [],
		historyType: ParticipantHistoryType.All,
		historyShareFromUtc: null,
	};

	const validate = (values: AddParticipantsFormValues) => {
		const errors: FormikErrors<AddParticipantsFormValues> = {};
		if (values.participants.length === 0) errors.participants = 'Required Field';
		if (values.historyType === ParticipantHistoryType.Partial) {
			if (values.historyShareFromUtc === null) errors.historyType = 'Required Field';
			else if (isNaN(values.historyShareFromUtc.getTime()))
				errors.historyType = 'Invalid Date';
		}
		return errors;
	};

	const addParticipants = useAddParticipants();
	const onSubmit = async (values: AddParticipantsFormValues) => {
		const participants: Participant[] = values.participants.map((p) => ({
			entityId: p.id,
			entityType: convertEntityType(p.entityType),
		}));
		const historyShareFromUtc =
			values.historyType === ParticipantHistoryType.Partial
				? values.historyShareFromUtc
				: null;
		await addParticipants(participants, values.historyType, historyShareFromUtc);
		onClose();
	};

	return (
		<Formik<AddParticipantsFormValues>
			onSubmit={onSubmit}
			initialValues={initialValues}
			validate={validate}>
			{({
				isValid,
				dirty,
				submitForm,
				values,
				isSubmitting,
			}: FormikProps<AddParticipantsFormValues>) => (
				<>
					<ModalHeader title="Add Participants" onClose={onClose} />
					<ModalContent spacing={1}>
						<ParticipantSearchField
							name="participants"
							label="New Participants"
							required
							omittedIds={existingParticipants.map((p) => p.entityId)}
						/>
						<Box>
							<Typography variant="h6">
								How much chat history should be shared with these new participants?
							</Typography>
							<RadioGroupField name="historyType">
								<FormControlLabel
									value={ParticipantHistoryType.All}
									control={<Radio />}
									label="All chat history"
								/>
								<FormControlLabel
									value={ParticipantHistoryType.None}
									control={<Radio />}
									label="No chat history"
								/>
								<FormControlLabel
									value={ParticipantHistoryType.Partial}
									control={<Radio />}
									label={
										<Stack direction="row" alignItems="center" spacing={2}>
											<Typography>Starting from</Typography>
											<Box flex={1}>
												<DatePickerField
													name="historyShareFromUtc"
													includeTime
													disabled={
														values.historyType !==
														ParticipantHistoryType.Partial
													}
												/>
											</Box>
										</Stack>
									}
									sx={{ mt: -0.5 }}
								/>
							</RadioGroupField>
						</Box>
					</ModalContent>
					<ModalButtonsContainer>
						<Button variant="outlined" onClick={onClose}>
							Cancel
						</Button>
						<LoadingButton
							type="submit"
							color="primary"
							variant="contained"
							disabled={!isValid || !dirty}
							loading={isSubmitting}
							onClick={submitForm}>
							Add Participants
						</LoadingButton>
					</ModalButtonsContainer>
				</>
			)}
		</Formik>
	);
};
